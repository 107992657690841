import { createSlice } from "@reduxjs/toolkit";

import { fetchReferralFeeFilters, fetchReferralFees } from "./thunk";
import updateObjectByPath from "../../components/dynamic-form/util/updateState";
import { updateBoundFilters } from "../updateBoundFilters";

const initialState = {
  referralFees: [],
  links: {},
  loading: "idle",
  error: null,
  isLogin: false,
  filtersMeta: {},
  boundFilters: {},
  filters: {},
  filterLoading: "idle",
};

export const referralFeesSlice = createSlice({
  name: "referralFees",
  initialState,
  reducers: {
    updateBoundFilter: updateBoundFilters,
    // fetchOffer: (state, action) => {
    //   return { ...state, offers: action.payload };
    // },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    updateRow: (state, action) => {
      const updatedState = updateObjectByPath(
        state,
        action.payload.path,
        action.payload.value
      );
      return { ...state, ...updatedState };
    },
    removeRow: (state, action) => {
      const ids = action.payload;
      state.referralFees = state.referralFees.filter(
        (fee) => !ids.includes(fee.id)
      );
    },
    fetchLoading: (state, action) => {
      state.loading = "pending";
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(fetchReferralFees.pending, (state, action) => {
      //     if (state.loading === "idle") {
      //         state.loading = "pending";
      //     }
      // })
      .addCase(fetchReferralFees.fulfilled, (state, action) => {
        state.loading = "idle";
        state.referralFees = action.payload?.append
          ? [...state.referralFees, ...action.payload.data.data]
          : action.payload.data.data;
        state.links = action.payload["links"];
        state.meta = action.payload["meta"];
      })
      .addCase(fetchReferralFees.rejected, (state, action) => {
        if (state.loading === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      })
      .addCase(fetchReferralFeeFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload?.data;
        state.filterLoading = "idle";
      })
      .addCase(fetchReferralFeeFilters.pending, (state, action) => {
        state.filterLoading = "pending";
      });
  },
});

export const {
  updateFilter,
  updateBoundFilter,
  extraReducers,
  updateRow,
  removeRow,
  fetchLoading,
} = referralFeesSlice.actions;

export { fetchReferralFeeFilters, fetchReferralFees };

export default referralFeesSlice.reducer;
