import { createSlice } from "@reduxjs/toolkit";
import {
  bulkDeleteCarriers,
  bulkStatusUpdateCarriers,
  createCarrier,
  deleteCarrier,
  fetchCarriers,
  fetchCarriersFilters,
  restoreCarriers,
  searchCarriers,
  updateCarrier,
} from "./thunk";
import updateObjectByPath from "../../components/dynamic-form/util/updateState";
import { updateBoundFilters } from "../updateBoundFilters";

const initialState = {
  carriers: [],
  links: {},
  loading: "idle",
  error: null,
  filters: {
    filters: { status: 0 },
    sort: null,
    page: 1,
    search: "",
  },
  filtersMeta: {},
  boundFilters: {},
  meta: {},
};

export const carrierSlice = createSlice({
  name: "carriers",
  initialState,
  reducers: {
    updateBoundFilter: updateBoundFilters,
    updateRow: (state, action) => {
      const updatedState = updateObjectByPath(
        state,
        action.payload.path,
        action.payload.value
      );
      return { ...state, ...updatedState };
    },
    fetchCarriers: (state, action) => {
      state.carriers = action.payload;
    },
    updateFilter: (state, action) => {
      state.filters = { ...action.payload };
    },
    updateCarrierStatus: (state, action) => {
      const { status, ids } = action.payload;
      state.carriers = state.carriers.map((curr) => {
        if (ids.includes(curr.id)) {
          return { ...curr, status };
        }
        return curr;
      });
    },
    removeCarrier: (state, action) => {
      const ids = action.payload;
      state.carriers = state.carriers.filter((curr) => !ids.includes(curr.id));
    },
    bulkDeleteCarriers: (state, action) => {
      const ids = action.payload;
      state.carriers = state.carriers.filter(
        (Carrier) => !ids.includes(Carrier.id)
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCarriers.pending, (state, action) => {
        state.loading = "pending";
      })
      .addCase(fetchCarriers.fulfilled, (state, action) => {
        state.loading = "idle";
        state.carriers = action.payload?.data;
        state.links = action.payload.links;
        state.meta = action.payload.meta;
      })
      .addCase(fetchCarriers.rejected, (state, action) => {
        if (action.error?.name !== "CanceledError") {
          state.loading = "idle";
          state.error = action.error;
        }
      })
      .addCase(fetchCarriersFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload;
      });
  },
});

export const {
  updateFilter,
  updateBoundFilter,
  removeCarrier,
  updateCarrierStatus,
  updateRow,
} = carrierSlice.actions;

export {
  fetchCarriers,
  createCarrier,
  updateCarrier,
  deleteCarrier,
  searchCarriers,
  bulkDeleteCarriers,
  bulkStatusUpdateCarriers,
  restoreCarriers,
  fetchCarriersFilters,
};

export default carrierSlice.reducer;
