import { createSlice } from "@reduxjs/toolkit";

import {
  addNote,
  addTargetSeller,
  deleteNote,
  fetchNotes,
  fetchTargetSellerFolder,
  fetchTargetSellerListings,
  fetchTargetSellerListingsFilters,
  fetchTargetSellers,
  fetchTargetSellersFilters,
  handleApprovementAction,
  updateWeight,
} from "./thunk";
import updateObjectByPath from "../../components/dynamic-form/util/updateState";
import { updateBoundFilters } from "../updateBoundFilters";

const initialState = {
  // targetSellerFolder: [],
  targetSellerData: [],
  links: {},
  filters: {},
  meta: {},
  filtersMeta: {},
  boundFilters: {},
  loading: "idle",
  ongoingRequests: [],
  error: null,
  isLogin: false,
  filterLoading: "idle",
};

export const targetSellerFolderSlice = createSlice({
  name: "targetSellerFolder",
  initialState,
  reducers: {
    updateBoundFilter: updateBoundFilters,
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    updateRow: (state, action) => {
      const updatedState = updateObjectByPath(
        state,
        action.payload.path,
        action.payload.value
      );
      return { ...state, ...updatedState };
    },
    approveSeller: (state, action) => {
      return {
        ...state,
        targetSellerData: state.targetSellerData.map((item) => {
          return {
            ...item,
            status: item.id === action.payload.id ? 1 : item.status,
          };
        }),
      };
    },
    rejectSeller: (state, action) => {
      // return {
      //   ...state,
      //   targetSellerData: state.targetSellerData.map((item) => {
      //     return {
      //       ...item,
      //       status: item.id === action.payload.id ? 2 : item.status
      //     }
      //   })
      // }
      // reject seller and remove it from the list
      return {
        ...state,
        targetSellerData: state.targetSellerData.filter(
          (item) => item.id !== action.payload.id
        ),
      };
    },
    removeListing: (state, action) => {
      // if the listing included in ids array, remove it from the list
      return {
        ...state,
        targetSellerData: state.targetSellerData.filter(
          (item) => !action.payload.ids.includes(item.id)
        ),
      };
    },
    fetchLoading: (state, action) => {
      state.loading = "pending";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTargetSellerListings.fulfilled, (state, action) => {
        const requestId = action.meta.requestId;
        state.ongoingRequests = state.ongoingRequests.filter(
          (id) => id !== requestId
        ); // Remove the request ID from ongoingRequests

        if (state.ongoingRequests.length === 0) {
          state.loading = "idle"; // Set loading to idle if there are no ongoing requests
        }

        state.targetSellerData = action.payload?.append
          ? [...state.targetSellerData, ...action.payload.data]
          : action.payload.data;
        state.links = action.payload.links;
        state.meta = action.payload.meta;
      })
      .addCase(fetchTargetSellerListings.rejected, (state, action) => {
        const requestId = action.meta.requestId;
        state.ongoingRequests = state.ongoingRequests.filter(
          (id) => id !== requestId
        ); // Remove the request ID from ongoingRequests

        if (state.ongoingRequests.length === 0) {
          state.loading = "idle"; // Set loading to idle if there are no ongoing requests
        }

        state.error = action.error;
      })
      .addCase(fetchTargetSellers.fulfilled, (state, action) => {
        const requestId = action.meta.requestId;
        state.ongoingRequests = state.ongoingRequests.filter(
          (id) => id !== requestId
        );

        if (
          state.ongoingRequests.length === 0 &&
          state.ongoingRequests.length === 0
        ) {
          state.loading = "idle";
        }

        state.targetSellerData = action.payload?.append
          ? [...state.targetSellerData, ...action.payload.data]
          : action.payload.data;
        state.links = action.payload.links;
        state.meta = action.payload.meta;
      })
      .addCase(fetchTargetSellers.rejected, (state, action) => {
        const requestId = action.meta.requestId;
        state.ongoingRequests = state.ongoingRequests.filter(
          (id) => id !== requestId
        );

        if (
          state.ongoingRequests.length === 0 &&
          state.ongoingRequests.length === 0
        ) {
          state.loading = "idle";
        }

        state.error = action.error;
      })
      .addCase(fetchTargetSellerListingsFilters.fulfilled, (state, action) => {
        // if (state.loading === 'pending') {
        //   state.loading = 'idle'
        state.filtersMeta.listingsFilters = action.payload;
        state.filterLoading = "idle";
        // }
      })
      .addCase(fetchTargetSellerListingsFilters.pending, (state, action) => {
        // if (state.loading === 'pending') {
        //   state.loading = 'idle'
        state.filterLoading = "pending";
        // }
      })
      .addCase(fetchTargetSellersFilters.fulfilled, (state, action) => {
        // if (state.loading === 'pending') {
        //   state.loading = 'idle'
        state.filtersMeta.sellersFilters = action.payload;
        // }
      });
  },
});

export const {
  updateFilter,
  approveSeller,
  rejectSeller,
  removeListing,
  updateRow,
  updateBoundFilter,
  fetchLoading,
} = targetSellerFolderSlice.actions;
export {
  fetchTargetSellerFolder,
  fetchTargetSellerListings,
  fetchTargetSellers,
  handleApprovementAction,
  addTargetSeller,
  fetchTargetSellerListingsFilters,
  fetchTargetSellersFilters,
  updateWeight,
  fetchNotes,
  deleteNote,
  addNote,
};

export default targetSellerFolderSlice.reducer;
