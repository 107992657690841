import { createSlice } from "@reduxjs/toolkit";
import {
  bulkDeleteSupPurchases,
  bulkRestoreSupPurchases,
  fetchSupPurchases,
  fetchSupPurchasesFilters,
  updateSupplyPurchase,
  updateSupPurchasesStatus,
} from "./thunk";
import { updateBoundFilters } from "../updateBoundFilters";

const initialState = {
  supPurchases: [],
  links: {},
  loading: "idle",
  error: null,
  filters: {
    status_filter: [],
    created_filter: "",
    updated_filter: "",
    balance_filter: [],
  },
  meta: {},
  filtersMeta: {},
};

export const supPurchasesSlice = createSlice({
  name: "supPurchases",
  initialState,
  reducers: {
    updateBoundFilter: updateBoundFilters,
    fetchSupPurchases: (state, action) => {
      state.supPurchases = action.payload;
    },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    updateSupPurchasesStatuses: (state, action) => {
      const { status, ids } = action.payload;
      state.supPurchases = state.supPurchases.map((curr) => {
        if (ids.includes(curr.id)) {
          return { ...curr, status };
        }
        return curr;
      });
    },
    removeSupPurchases: (state, action) => {
      const ids = action.payload;
      state.supPurchases = state.supPurchases.filter(
        (curr) => !ids.includes(curr.id)
      );
    },
    fetchLoading: (state, action) => {
      state.loading = "pending";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupPurchases.rejected, (state, action) => {
        if (action.error?.name !== "CanceledError") {
          state.loading = "idle";
          state.error = action.error;
        }
      })
      .addCase(fetchSupPurchases.fulfilled, (state, action) => {
        state.loading = "idle";
        state.supPurchases = action.payload?.append
          ? [...state.supPurchases, ...action.payload.data]
          : action.payload.data;
        state.links = action.payload?.links;
        state.meta = action.payload?.meta;
      })
      // .addCase(fetchSupPurchasesFilters.pending, (state, action) => {
      //     state.loading = "pending";
      // })
      .addCase(fetchSupPurchasesFilters.fulfilled, (state, action) => {
        // state.loading = "idle";
        state.filtersMeta = action.payload;
      });
  },
});

export const {
  updateFilter,
  updateBoundFilter,
  removeSupPurchases,
  updateSupPurchasesStatuses,
  fetchLoading,
} = supPurchasesSlice.actions;

export {
  fetchSupPurchases,
  bulkDeleteSupPurchases,
  bulkRestoreSupPurchases,
  updateSupPurchasesStatus,
  fetchSupPurchasesFilters,
  updateSupplyPurchase,
};

export default supPurchasesSlice.reducer;
