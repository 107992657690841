import React, { useState } from "react";
import { HiArrowLeft, HiInformationCircle } from "react-icons/hi";
import ImageMagnifier from "../../dynamic-page/debt/magnifier";
// import Components from "../components";
import priceHandler from "../../../utils/priceHandler";
import Icons from "../../orders/icons";
import ArbitToggle from "../../sub-elements/toggle";
import ListingSales from "../../dynamic-page/components/listingSales";
import InternalNote from "../components/internalNote";
import Item from "../components/item";
import SalesChannel from "../../dynamic-page/components/SalesChannel";
import ListingAnalysis from "../../dynamic-page/components/ListingAnalysis";
import descriptiveContent from "../../../utils/descriptiveContent";
import { useDispatch, useSelector } from "react-redux";
import { refreshAccountListings } from "../../../store/fc-restocking";
import { updateRow } from "../../../store/sales-listings";
import {
  closeNotification,
  startNotification,
} from "../../dynamic-page/util/notifierLive";
import RefreshSales from "../../dynamic-page/components/RefreshSales";
import PopupUp from "../../dynamic-page/util/popUp";
import Button from "../../dynamic-form/elements/Button";
import PropTypes from "prop-types";

const TableView = (list, fulfilmentHandler, openListingModal, connect) => {
  const activeStyle = (status) => {
    if (status) {
      return "!text-blue-500";
    } else {
      return "text-gray-300 hover:text-gray-500";
    }
  };
  const pageData = useSelector((state) => state.salesListing);
  const InfoToggle = () => (
    <span>
      <HiInformationCircle className="w-3 h-3 text-gray-300 hover:text-gray-500 cursor-pointer" />
    </span>
  );
  const QuantityTooltip = ({ data }) => {
    if (data?.length > 0)
      return (
        <PopupUp
          toggle={InfoToggle}
          action="hover"
          // leftSpace={style?.leftSpace}
        >
          <div className="min-w-[250px] h-[55px] px-3 py-2.5 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
            {data?.map((item) => {
              return (
                <div
                  key={item?.val}
                  className="flex flex-row justify-between w-full"
                >
                  <p className="text-gray-900 text-sm font-normal leading-[21px]">
                    {item?.account?.name}
                  </p>
                  <p className="text-gray-900 text-sm font-semibold leading-[21px]">
                    {item?.val || 0}
                  </p>
                </div>
              );
            })}
          </div>
        </PopupUp>
      );
    else
      return (
        <HiInformationCircle className="w-3 h-3 text-gray-300 hover:text-gray-500 cursor-pointer" />
      );
  };
  QuantityTooltip.propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        val: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        account: PropTypes.shape({
          name: PropTypes.string,
        }),
      })
    ).isRequired,
  };

  const ProfitTooltip = (item, fba) => {
    const renderCostRow = (label, margin, cost, color) => {
      if (margin || cost || margin === 0 || cost === 0) {
        return (
          <div className="self-stretch px-2.5 py-px justify-between items-start inline-flex">
            <div className="justify-start items-start gap-1 flex">
              <div className="text-center text-black text-xs font-normal font-['Inter'] !leading-[162.5%]">
                {label}
              </div>
            </div>
            <div className="justify-start items-start gap-2 flex">
              <div className="text-center text-gray-400 text-xs font-normal font-['Inter'] !leading-[162.5%]">
                {margin || margin === 0 ? `${margin}%` : "-"}
              </div>
              <div
                className={`text-center ${color} text-xs font-normal font-['Inter'] !leading-[162.5%]`}
              >
                {(() => {
                  if (cost === 0) {
                    return "$0";
                  }

                  if (cost) {
                    return `-$${priceHandler(cost)}`;
                  }

                  return "-";
                })()}
              </div>
            </div>
          </div>
        );
      } else return null;
    };

    const Calculator = Icons.calculator;

    return (
      <PopupUp
        toggle={() => (
          <Calculator className="text-gray-300 hover:text-gray-500 w-3 h-3 cursor-pointer " />
        )}
        action={"hover"}
        className="!p-0"
        innerClassName="!p-1"
      >
        <div className="max-w-[240px]  flex-col justify-start items-center inline-flex">
          <div className="self-stretch px-2.5 py-px justify-between items-start inline-flex">
            <div className="text-center text-black text-xs font-normal font-['Inter'] !leading-[162.5%]">
              Sales Price
            </div>
            <div className="text-center text-black text-xs font-normal font-['Inter'] !leading-[162.5%]">
              {"$" + item?.["sales_price"]}
            </div>
          </div>
          {renderCostRow(
            "Supply Cost",
            item?.["supply_cost_margin"],
            item?.["supply_cost"],
            "text-red-800"
          )}
          {renderCostRow(
            "Referral Fee",
            item?.["referral_fee_margin"],
            item?.["referral_fee"],
            "text-red-800"
          )}
          {fba &&
            renderCostRow(
              "FBA Fee",
              item?.["fba_fee_margin"],
              item?.["fba_fee"],
              "text-red-800"
            )}
          {item?.["shipment_cost"] !== undefined &&
            renderCostRow(
              "Shipment Cost",
              item?.["shipment_cost_margin"],
              item?.["shipment_cost"],
              "text-red-800"
            )}
          {renderCostRow(
            "Payment Fee",
            item?.["payment_gateway_commission_margin"],
            item?.["payment_gateway_commission"],
            "text-red-800"
          )}
          {renderCostRow(
            "Processing Fee",
            item?.["processing_fee_margin"],
            item?.["processing_fee"],
            "text-red-800"
          )}
          <div className="w-[232px] h-[0px] relative"></div>
          <div className="self-stretch h-[0px] px-2.5 flex-col mb-1 justify-start items-end gap-2.5 flex">
            <div className="w-12 h-[0px] border border-gray-200"></div>
          </div>
          {(item?.["margin"] !== undefined ||
            item?.["profit"] !== undefined) && (
            <div className="self-stretch py-px justify-start items-start gap-1 inline-flex">
              <div className="grow shrink basis-0 self-stretch px-2.5 justify-between items-start flex">
                <div className="justify-start items-start gap-1 flex">
                  <div className="text-center text-black text-xs font-normal font-['Inter'] !leading-[162.5%]">
                    Profit
                  </div>
                </div>
                <div className="justify-start items-start gap-2 flex">
                  <div className="text-center text-gray-400 text-xs font-normal font-['Inter'] !leading-[162.5%]">
                    {item?.["margin"] !== undefined
                      ? `${item?.["margin"]}%`
                      : "-"}
                  </div>
                  <div className="text-center text-emerald-900 text-xs font-normal font-['Inter'] !leading-[162.5%]">
                    {item?.["profit"] !== undefined
                      ? `$` + priceHandler(item?.["profit"])
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </PopupUp>
    );
  };

  const nullHandle = (data, chained) => {
    if (!data || chained == null) {
      return "-";
    } else {
      return chained;
    }
  };

  const [refreshing, setRefreshing] = useState("");

  const dispatch = useDispatch();
  const handleRefreshListings = async (item2) => {
    const identifiers = [item2?.sales_channel_listing?.identifier];

    const ids = item2?.id;

    try {
      setRefreshing(item2?.id);

      let a = startNotification({
        title: `Refreshing the Listing`,
      });

      dispatch(refreshAccountListings({ identifiers }))
        .unwrap()
        .then((rs) => {
          setRefreshing("");
          // util.notifier({type : rs.type , message: rs.message});
          closeNotification(a, {
            toast: {
              type: rs.type === "validation_error" ? "error" : rs.type,
              message: rs.message,
              errors: rs.errors,
            },
          });
          if (rs.type === "success") {
            identifiers.forEach((identifier) => {
              const row = rs.data.find(
                (item) => item.identifier === identifier
              );
              const originalRow = pageData?.salesListings?.find(
                (item) => item.sales_channel_listing.identifier === identifier
              );
              dispatch(
                updateRow({
                  path: `salesListings.[id:${originalRow.id}]`,
                  value: {
                    ...originalRow,
                    sales_channel_listing: row,
                    _updatedRow: true,
                  },
                })
              );
            });

            setTimeout(() => {
              dispatch(
                updateRow({
                  path: `salesListings.[id:${ids}]._updatedRow`,
                  value: false,
                })
              );
            }, 3000);
          }
        });
    } catch (error) {
      // Handle errors if the refresh fails
      console.error("Error refreshing account listings:", error);
    }
  };

  const Internal = Icons.internalNote;

  const Exc = Icons.exclamationFill;

  const Offer = Icons.offerCount;

  const Check = Icons.check;

  return {
    list: list ?? [],
    theme: {
      notes: (item) => (
        <div className={"flex gap-1 flex-col  "}>
          <div className={"grid grid-cols-1 relative  z-9 gap-[4px]"}>
            <PopupUp
              className="!z-[9]"
              toggle={(selected) => (
                <div className={"relative -z-1"}>
                  <Button
                    item={{
                      buttonType: "flat",
                      className: `p-[2px]  ${activeStyle(
                        item?.notes?.length > 0
                      )}`,
                    }}
                  >
                    <Internal className="w-[16px] h-[16px] " />
                  </Button>
                  {item?.notes?.length > 0 && (
                    <div
                      className={
                        "absolute top-[-3px] right-[-3px] bg-white text-blue-500 border border-blue-500 flex text-[8px] min-w-[14px] px-[3px] justify-center items-center font-black rounded-full"
                      }
                    >
                      {item?.notes?.length}
                    </div>
                  )}
                </div>
              )}
              // leftSpace={style?.leftSpace}
            >
              <div className={"w-[525px]"}>
                <InternalNote
                  callBack={null}
                  notes={item?.notes}
                  id={item?.id}
                />
              </div>
            </PopupUp>
          </div>

          {!item?.sales_channel_listing?.available ? (
            <PopupUp
              toggle={(selected) => (
                <Exc className="w-[20px] h-[20px] text-red-600" />
              )}
              action="hover"
            >
              <div className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                Unavailable
              </div>
            </PopupUp>
          ) : (
            ""
          )}
        </div>
      ),
      //   "last update": (item) => {
      //     const salesListing = item?.sales_channel_listing
      //       ? item?.sales_channel_listing
      //       : item?.sales_channel_listings?.[0];
      //     return <util.dateDisplay item={item} date={salesListing?.updated_at} />;
      //   },
      channel: (item) => {
        const salesListing = item?.sales_channel_listing
          ? item?.sales_channel_listing
          : item?.sales_channel_listings?.[0];

        const modalListing = item;

        return (
          <div className="flex flex-col gap-1">
            <SalesChannel
              item={{
                sold_by_us: item?.sold_by_us,
                channel: {
                  logo: salesListing?.["channel"]?.marketplace?.logo,
                  name: salesListing?.["channel"]?.name,
                },
                identifier_type: salesListing?.identifier_type,
                identifier_value: salesListing?.identifier,
                identifier: salesListing?.identifier,
                url: salesListing?.url,
              }}
              item2={item}
              refreshing={refreshing}
              handleRefreshListings={handleRefreshListings}
              date={salesListing?.updated_at}
              openListingModal={() => openListingModal(modalListing)}
            />
            <RefreshSales
              handleRefreshListings={() => handleRefreshListings(item)}
              item={item}
              refreshing={refreshing}
              date={salesListing?.updated_at}
            />
          </div>
        );
      },

      image: (item) => {
        const salesListing = item?.sales_channel_listing
          ? item?.sales_channel_listing
          : item?.sales_channel_listings?.[0];
        return (
          <div
            className={
              "w-[96px] h-[96px] max-h-[96px]  justify-center items-center flex"
            }
          >
            {salesListing?.main_image_path ? (
              <ImageMagnifier
                src={salesListing?.main_image_path}
                className={"w-[96px] h-[96px] max-h-[96px] "}
              />
            ) : (
              <img
                alt="def"
                src={"/assets/images/noImage.png"}
                className={"h-[52px] w-[52px]"}
              />
            )}
          </div>
        );
      },

      title: (item) => (
        <Item
          product={
            item?.sales_channel_listing
              ? [item?.sales_channel_listing?.product]
              : [item?.sales_channel_listings[0]?.product]
          }
          connect={connect}
          item={item}
        />
      ),
      "Listing sales": (item) => (
        <div className="w-[100%] flex justify-start">
          <ListingSales
            item={{
              weekly: {
                sales: item?.["7d_total_sales"],
                revenue: item?.["7d_total_revenue"],
              },
              monthly: {
                sales: item?.["30d_total_sales"],
                revenue: item?.["30d_total_revenue"],
              },
            }}
            noArrows
          />
        </div>
      ),

      "LISTING ANALYSIS": (item) => {
        item = item?.sales_channel_listing
          ? item?.sales_channel_listing
          : item?.sales_channel_listings[0];
        const offerCount = item?.total_offer_count;
        return <ListingAnalysis item={item} offerCount={offerCount} />;
      },

      "Fc eligibility": (item) => (
        <div className="flex flex-col justify-between w-[220px] !px-[8px] !py-[8px] gap-[8px] h-full">
          <div className="flex flex-row justify-between gap-4  w-full">
            <div className="flex flex-row items-center justify-center gap-1">
              {descriptiveContent(
                <ArbitToggle
                  status={item?.fba_eligibility ?? false}
                  bgColor={
                    item?.fba_eligibility ? "bg-orange-600" : "bg-gray-400"
                  }
                  handleFulfilment={() => fulfilmentHandler("fba", item?.id)}
                />,
                "Toggle FC Eligibility"
              )}
              <p
                className={`${
                  item?.fba_eligibility ? "text-orange-600" : "text-gray-400"
                } leading-[17.5px] text-[14px] font-medium`}
              >
                FC Restock
              </p>
            </div>
            {descriptiveContent(
              <button
                className="flex flex-row items-center text-orange-700 gap-0.5 justify-center cursor-pointer"
                onClick={() => {
                  window.open(
                    `/listing/offers?search=&filter%5Bfulfillment%5D=AMAZON_NA&filter%5Bidentifier%5D=${
                      item?.sales_channel_listing?.identifier ||
                      item?.sales_channel_listings[0]?.identifier
                    }&page=1`,
                    "_blank"
                  );
                }}
              >
                <Offer />
                <p className="text-orange-700 text-xl !leading-5 font-medium">
                  {nullHandle(item, item?.fba_offers_count)}
                </p>
              </button>,
              "Offers Count"
            )}
          </div>
          {item?.fba_eligibility ? (
            <div className="flex flex-row mt-1 justify-between gap-4  w-full">
              <div className="flex flex-row items-center justify-center">
                <p className="text-orange-600 text-base whitespace-nowrap font-inter font-medium">
                  {(() => {
                    const maxPrice = +item?.fba_max_price;
                    const minPrice = +item?.fba_min_price;

                    // If maxPrice and minPrice are available and equal
                    if (maxPrice && minPrice && maxPrice - minPrice === 0) {
                      return descriptiveContent(
                        `$${priceHandler(maxPrice)}`,
                        "FBA Price"
                      );
                    }

                    // If both maxPrice and minPrice are available and different
                    if (minPrice && maxPrice) {
                      return descriptiveContent(
                        `$${priceHandler(minPrice)} - $${priceHandler(
                          maxPrice
                        )}`,
                        "FBA Price"
                      );
                    }

                    // If any price is unavailable
                    return descriptiveContent("-", "FBA Price");
                  })()}
                </p>
              </div>
              <div className="flex flex-row gap-0.5 items-center justify-center">
                <p className="text-orange-600 text-base font-bold">
                  {descriptiveContent(
                    item?.fba_margin ? `${item?.fba_margin?.toFixed(2)}%` : "-",
                    " FBA Margin"
                  )}
                </p>
                {item?.fba_margin && ProfitTooltip(item?.fba_tooltip, true)}
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-start justify-end w-full text-xl text-gray-500 font-medium leading-5 break-normal ">
              <p className="p-0 m-0">Not Eligible. </p>
            </div>
          )}
          <div className="flex flex-row justify-between gap-0.5 items-center w-full">
            <Check className="h-[14px] w-[16px] font-medium text-orange-600" />
            <p className="text-orange-600 text-[16px] leading-6 font-inter font-medium ">
              {descriptiveContent(
                item.fba_available_qty,
                "FBA Available Quantity"
              )}
            </p>
            {item?.tooltips?.available?.length ? (
              <QuantityTooltip data={item?.tooltips?.available} />
            ) : null}
            <HiArrowLeft className="h-3 w-3" color="#FDBA8C" />
            <p className="text-orange-600 text-[16px] leading-6 font-inter font-normal ">
              {descriptiveContent(
                item.fba_reserved_qty ?? "N/A",
                "FBA Reserved Quantity"
              )}
            </p>
            {item?.tooltips?.reserved_qty?.length ? (
              <QuantityTooltip data={item?.tooltips?.reserved_qty} />
            ) : null}
            <HiArrowLeft className="h-3 w-3" color="#FDBA8C" />
            <p className="text-orange-600 text-[16px] leading-6 font-inter font-normal ">
              {descriptiveContent(
                item.fba_inbound_quantity ?? "N/A",
                "FBA Inbound Quantity"
              )}
            </p>
            {item?.tooltips?.inbound_qty?.length ? (
              <QuantityTooltip data={item?.tooltips?.inbound_qty} />
            ) : null}
          </div>
        </div>
      ),

      "wh eligibility": (item) => (
        <div className="flex flex-col w-[220px] justify-between !px-[8px] !py-[8px] gap-[6px] h-full">
          <div className="flex flex-row justify-between items-center gap-4  w-full">
            <div className="flex flex-row items-center justify-center gap-1">
              {descriptiveContent(
                <ArbitToggle
                  status={item.fbm_eligibility ?? false}
                  bgColor={item.fbm_eligibility ? "bg-blue-600" : "bg-gray-400"}
                  handleFulfilment={() => fulfilmentHandler("fbm", item?.id)}
                />,
                " Toggle WH Eligibility"
              )}
              <p
                className={`${
                  item.fbm_eligibility ? "text-blue-700" : "text-gray-400"
                } leading-[17.5px] text-[14px] font-medium`}
              >
                Warehouse
              </p>
            </div>
            {descriptiveContent(
              <button
                className="flex flex-row items-center gap-0.5 text-blue-700 justify-center"
                onClick={() => {
                  window.open(
                    `/listing/offers?search=&filter%5Bfulfillment%5D=DEFAULT&filter%5Bidentifier%5D=${
                      item?.sales_channel_listing?.identifier ||
                      item?.sales_channel_listings[0]?.identifier
                    }&page=1`,
                    "_blank"
                  );
                }}
              >
                <Offer />
                <p className=" text-[20px] leading-5 font-medium">
                  {nullHandle(item, item?.fbm_offers_count)}
                </p>
              </button>,
              " Offers Count"
            )}
          </div>
          {item.fbm_eligibility ? (
            <div className="flex flex-row mt-1 justify-between items-center gap-4  w-full">
              <div className="flex flex-row items-center justify-center">
                <p className="text-blue-700 text-base whitespace-nowrap font-inter font-medium">
                  {(() => {
                    const maxPrice = +item.fbm_max_price;
                    const minPrice = +item.fbm_min_price;

                    if (maxPrice && minPrice && maxPrice - minPrice === 0) {
                      return descriptiveContent(
                        `$${priceHandler(maxPrice.toFixed(2))}`,
                        "WH Price"
                      );
                    }

                    if (minPrice && maxPrice) {
                      return descriptiveContent(
                        `$${priceHandler(
                          minPrice.toFixed(2)
                        )} - $${priceHandler(maxPrice.toFixed(2))}`,
                        "WH Price"
                      );
                    }

                    return descriptiveContent("-", "WH Price");
                  })()}
                </p>
              </div>
              <div className="flex flex-row gap-0.5 items-center justify-center">
                <p className="text-blue-700 text-base font-bold">
                  {descriptiveContent(
                    item.fbm_margin ? `${priceHandler(item.fbm_margin)}%` : "-",
                    "WH Margin"
                  )}
                </p>
                {item?.fbm_margin && ProfitTooltip(item?.fbm_tooltip)}
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-start justify-end  text-xl text-gray-500 font-medium leading-5">
              <p className="p-0 m-0">Not Eligible. </p>
            </div>
          )}
          <div className="flex flex-row justify-start items-center gap-1  w-full">
            <Check className="h-[14px] w-[16px] font-medium text-blue-700" />
            <p className="text-blue-700 text-[16px] leading-6 font-medium ">
              {descriptiveContent(
                item.fbm_available_qty ?? "0",
                " WH Available Quantity"
              )}
            </p>

            <QuantityTooltip data={item?.tooltips?.wh_qty} />
          </div>
        </div>
      ),
    },
  };
};
export default TableView;
