import { useEffect, useState } from "react";
import Icons from "../../../orders/icons";
import axios from "axios";
import { toast } from "react-toastify";
import { addAttributes, removeAttributes } from "../../../../store/products";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import DirtySelect from "../../../dynamic-form/elements/DirtySelect";
import ProductDeleteModal from "../../Product-delete-modal";
import priceHandler from "../../../../utils/priceHandler";
import PopupUp from "../../../dynamic-page/util/popUp";

const Shipping = ({
  id,
  productData,
  weightNum,
  dimNum,
  title,
  weightTitle,
  textColor,
  weightDesc,
  dimTitle,
  dimDesc,
}) => {
  const [isValuePosted2, setIsValuePosted2] = useState(null);
  const [isDeleteClicked2, setIsDeleteClicked2] = useState(false);

  const [hasValue2, setHasValue2] = useState(null);

  useEffect(() => {
    if (productData?.attributes?.Other) {
      const foundValue =
        productData.attributes.Other.find(
          (item) => item?.attribute_id === weightNum
        ) ?? null;
      setHasValue2(foundValue); // Set local state based on updated productData
    }
  }, [productData, weightNum]);

  const dispatch = useDispatch();

  const handleValueClicked2 = async (props) => {
    if (props?.value) {
      if (
        (Number(dimensions.dim1 || 1) *
          Number(dimensions.dim2 || 1) *
          Number(dimensions.dim3 || 1)) /
          5000 >
        props.value
      ) {
        setBillableWeight(
          (Number(dimensions.dim1 || 1) *
            Number(dimensions.dim2 || 1) *
            Number(dimensions.dim3 || 1)) /
            5000
        );
      } else {
        setBillableWeight(parseFloat(props.value));
      }
      if ((hasValue2 || isValuePosted2) && !isDeleteClicked2) {
        await axios
          .put(
            `/api/v1/attribute-value/${isValuePosted2?.id || hasValue2?.id}`,
            { value: props?.value, locale: "40" },
            { withCredentials: true }
          )
          .then((res) => {
            if (res?.status === 200) {
              setIsDeleteClicked2(false);
              toast.success(res?.data?.message);
              dispatch(addAttributes(res?.data?.data?.attributeValue));
              setIsValuePosted2(res?.data?.data?.attributeValue);
              props.reset({ value: props?.value });
            } else {
              toast.error(res?.data?.message);
            }
          });
      } else {
        await axios
          .post(
            `/api/v1/attribute-value/${props.attributeId}/${id}`,
            { value: props?.value, locale: "40" },
            { withCredentials: true }
          )
          .then((res) => {
            if (res?.status === 200) {
              setIsValuePosted2(res?.data?.data?.attributeValue);
              props.reset({ value: props?.value });
              setIsDeleteClicked2(false);
              dispatch(addAttributes(res?.data?.data?.attributeValue));
              toast.success(res?.data?.message);
            } else {
              toast.error(res?.data?.message);
            }
          });
      }
    } else {
      try {
        const res = await axios.delete(
          `/api/v1/attribute-value/${isValuePosted2?.id || hasValue2?.id}`,
          { withCredentials: true }
        );

        if (res?.status === 204) {
          toast.success("Attribute has been deleted successfuly");
          setIsValuePosted2("");
          setIsDeleteClicked2(true);
          dispatch(removeAttributes(weightNum));
          props.setDeleteModal(false);
          props.reset({ value: "" });
        } else {
          toast.error(res?.data?.message);
        }
      } catch (error) {
        toast.error("An error occurred");
        console.error(error);
      }
    }
  };

  const [billableWeight, setBillableWeight] = useState(null);

  const isDim =
    productData?.attributes?.Other &&
    productData?.attributes["Other"]?.find(
      (item) => item?.attribute_id === dimNum
    );

  const [dimensions, setDimensions] = useState({
    dim1: null,
    dim2: null,
    dim3: null,
  });

  useEffect(() => {
    const dimensionValues = isDim?.value?.split("x").map(Number);

    if (dimensionValues) {
      const [dim1, dim2, dim3] = dimensionValues;
      setDimensions({
        dim1: dim1 || null,
        dim2: dim2 || null,
        dim3: dim3 || null,
      });
    } else {
      setDimensions({ dim1: null, dim2: null, dim3: null });
    }
  }, [isDim?.value]);

  useEffect(() => {
    const dimensionValues = isDim?.value?.split("x").map(Number);

    if (dimensionValues) {
      const [dim1, dim2, dim3] = dimensionValues;
      setDimensions({
        dim1: dim1 || null,
        dim2: dim2 || null,
        dim3: dim3 || null,
      });
      setBillableWeight(
        (Number(dim1 || 1) * Number(dim2 || 1) * Number(dim3 || 1)) / 5000 >
          Number(hasValue2?.value)
          ? (Number(dim1 || 1) * Number(dim2 || 1) * Number(dim3 || 1)) / 5000
          : Number(hasValue2?.value)
      );
    } else {
      setDimensions({ dim1: null, dim2: null, dim3: null });
    }
  }, []);

  const [isDimPosted, setIsDimPosted] = useState(null);

  const handleDimClick = async () => {
    const value = `${dimensions.dim1}x${dimensions.dim2}${
      dimensions.dim3 ? `x${dimensions.dim3}` : ""
    }`;
    if (
      (Number(dimensions.dim1 || 1) *
        Number(dimensions.dim2 || 1) *
        Number(dimensions.dim3 || 1)) /
        5000 >
      Number(hasValue2?.value)
    ) {
      setBillableWeight(
        (Number(dimensions.dim1 || 1) *
          Number(dimensions.dim2 || 1) *
          Number(dimensions.dim3 || 1)) /
          5000
      );
    } else {
      setBillableWeight(parseFloat(hasValue2?.value));
    }
    if (isDim || isDimPosted) {
      await axios
        .put(
          `/api/v1/attribute-value/${isDim?.id || isDimPosted}`,
          { value: value, locale: "40" },
          { withCredentials: true }
        )
        .then((res) => {
          if (res?.status === 200) {
            toast.success(res?.data?.message);
            setIsDeleteClicked(false);
            dispatch(addAttributes(res?.data?.data?.attributeValue));
            setIsSaveClicked(false);
          } else {
            toast.error(res?.data?.message);
          }
        });
    } else {
      await axios
        .post(
          `/api/v1/attribute-value/${dimNum}/${id}`,
          { value: value, locale: "40" },
          { withCredentials: true }
        )
        .then((res) => {
          if (res?.status === 200) {
            setIsDimPosted(res?.data?.data?.attributeValue?.id);
            toast.success(res?.data?.message);
            setIsDeleteClicked(false);
            dispatch(addAttributes(res?.data?.data?.attributeValue));
            setIsSaveClicked(false);
          } else {
            toast.error(res?.data?.message);
          }
        });
    }
  };
  const [deleteModal, setDeleteModal] = useState(false);

  const handleDimensionChange = (dim, value) => {
    setIsSaveClicked(true);
    setDimensions((prevDimensions) => ({ ...prevDimensions, [dim]: value }));
  };

  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const handleDeleteDim = async () => {
    setDimensions({
      dim1: "",
      dim2: "",
      dim3: "",
    });
    try {
      const res = await axios.delete(
        `/api/v1/attribute-value/${isDimPosted || isDim?.id}`,
        { withCredentials: true }
      );

      if (res?.status === 204) {
        toast.success("Attribute has been deleted successfuly");
        setIsDimPosted("");
        dispatch(removeAttributes(dimNum));

        setIsSaveClicked(false);

        setIsDeleteClicked(true);
        setDeleteModal(false);
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      toast.error("An error occurred");
      console.error(error);
    }
  };

  const Check = Icons.chechkCircle;
  const Info = Icons.info;
  let calculatedWeight;

  if (billableWeight) {
    calculatedWeight =
      billableWeight % 1 === 0 ? billableWeight : billableWeight.toFixed(2);
  } else if (title === "Merchant Shipping") {
    calculatedWeight = priceHandler(productData?.fbm_billable_weight);
  } else {
    calculatedWeight = priceHandler(productData?.fba_billable_weight);
  }

  return (
    <div className="flex gap-3 flex-col h-[142px] border border-gray-100 rounded-[8px]  p-4 w-full md:min-w-[363px] xl:w-[25%] flex-[1_0_0] box-border">
      <div className="flex justify-between">
        <span
          className={` font-semibold text-[18px] leading-[27px] ${textColor}`}
        >
          {title}
        </span>
        {hasValue2 && isDim && (
          <div className="flex gap-1 items-center">
            <span
              className={`${textColor} font-medium text-[14px] leading-normal  `}
            >
              {calculatedWeight} kg
            </span>
            <PopupUp
              toggle={(selected) => (
                <Info className="w-5 h-5 text-gray-300 hover:text-gray-500" />
              )}
              tabIndex={-1}
              action="hover"
              forcePosition={"top"}
            >
              <div className="flex flex-col gap-1.5 p-1.5 px-2 w-[297px]">
                <span className="text-[14px] leading-[14px] font-medium text-gray-900">
                  Billable Weight
                </span>
                <span className="text-gray-500 text-[12px] leading-[15px] font-normal">
                  The billable weight is the greater value between the actual
                  weight and the dimensional weight of the product. It is used
                  to determine shipping costs.
                </span>
              </div>
            </PopupUp>
          </div>
        )}
      </div>
      <div className="flex gap-2">
        <div className=" w-full sm:min-w-[200px]  flex flex-col  gap-2     ">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-1">
              <label className="text-[14px] leading-[21px] font-medium text-gray-900">
                Dimensions{" "}
                <span className="font-semibold text-gray-500 text-[12px] leading-normal">
                  cm
                </span>
              </label>

              <PopupUp
                toggle={(selected) => (
                  <Info className="w-5 h-5 text-gray-300 hover:text-gray-500" />
                )}
                tabIndex={-1}
                action="hover"
                forcePosition={"top"}
              >
                <div className="flex flex-col gap-1.5 p-1.5 px-2 w-[297px]">
                  <span className="text-[14px] leading-[14px] font-medium text-gray-900">
                    {dimTitle}
                  </span>
                  <span className="text-gray-500 text-[12px] leading-[15px] font-normal">
                    {dimDesc}
                  </span>
                </div>
              </PopupUp>
            </div>
            <div className="flex gap-1 items-center">
              {dimensions.dim1 && dimensions.dim2 && isSaveClicked && (
                <button
                  onClick={handleDimClick}
                  className="text-blue-700 hover:text-blue-500 text-[14px] leading-normal font-medium"
                >
                  <Check className="w-5 h-5" />
                </button>
              )}
              {(isDim || isDimPosted) && !isDeleteClicked && (
                <Icons.TrashBin
                  onClick={handleDeleteDim}
                  className="text-gray-300 hover:text-gray-500 w-[18px] h-[18px] cursor-pointer"
                />
              )}
            </div>
          </div>
          <div className="flex items-center gap-1.5 ">
            <input
              value={dimensions.dim1}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (dimensions.dim1 && dimensions.dim2 && isSaveClicked) {
                    handleDimClick();
                  }
                }
              }}
              onChange={(e) => handleDimensionChange("dim1", e.target.value)}
              className="border w-[30%] min-w-px] border-gray-300 [&::-webkit-inner-spin-button]:appearance-none  bg-gray-50 rounded-[8px] h-[42px] text-center py-2"
              type="number"
            />
            <span className="text-gray-700 font-semibold  text-[14px]">x</span>
            <input
              value={dimensions.dim2}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (dimensions.dim1 && dimensions.dim2 && isSaveClicked) {
                    handleDimClick();
                  }
                }
              }}
              onChange={(e) => handleDimensionChange("dim2", e.target.value)}
              className="border w-[30%] min-w-px] border-gray-300 [&::-webkit-inner-spin-button]:appearance-none  bg-gray-50 rounded-[8px] h-[42px] text-center py-2"
              type="number"
            />
            <span className="text-gray-700 font-semibold  text-[14px]">x</span>
            <input
              value={dimensions.dim3}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (dimensions.dim1 && dimensions.dim2 && isSaveClicked) {
                    handleDimClick();
                  }
                }
              }}
              onChange={(e) => handleDimensionChange("dim3", e.target.value)}
              className="border w-[30%] min-w-px] border-gray-300 [&::-webkit-inner-spin-button]:appearance-none  bg-gray-50 h-[42px] rounded-[8px]  text-center py-2"
              type="number"
            />
          </div>
        </div>
        <DirtySelect
          type={"text"}
          isValue={
            productData?.attributes?.Other &&
            productData?.attributes["Other"]?.find(
              (item) => item?.attribute_id === weightNum
            )
          }
          titleDesc="kg"
          id={id}
          texts={{
            tooltipTitle: weightTitle,
            tooltipDesc: weightDesc,
          }}
          title={"Weight"}
          placeholder={"ex. 17"}
          tooltipPosition={"top"}
          attributeId={weightNum}
          className={"min-w-[123px] border-none px-0 py-0 3"}
          handleValueClicked={handleValueClicked2}
        />
        {deleteModal && (
          <ProductDeleteModal
            name={"the attribute"}
            onClose={() => setDeleteModal(false)}
            handleProductDeletion={handleDeleteDim}
          />
        )}
      </div>
    </div>
  );
};
Shipping.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  productData: PropTypes.shape({
    attributes: PropTypes.shape({
      Other: PropTypes.arrayOf(
        PropTypes.shape({
          attribute_id: PropTypes.number,
          value: PropTypes.string,
        })
      ),
    }),
    fbm_billable_weight: PropTypes.number, // if used in component
    fba_billable_weight: PropTypes.number, // if used in component
  }).isRequired,
  weightNum: PropTypes.number.isRequired,
  dimNum: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  weightTitle: PropTypes.string,
  textColor: PropTypes.string,
  weightDesc: PropTypes.string,
  dimTitle: PropTypes.string,
  dimDesc: PropTypes.string,
  setDeleteModal: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // if `value` is a direct prop
  dimensions: PropTypes.shape({
    dim1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dim2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dim3: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  attributeId: PropTypes.number.isRequired, // Added attributeId as required
};

export default Shipping;
