import React from "react";
import moment from "moment";
import { timeLeft } from "../../../utils/helper";
import Util from "../../dynamic-page/util";
import ImageMagnifier from "../../dynamic-page/debt/magnifier";
import quantityHandler from "../../../utils/quantityHandler";
import priceHandler from "../../../utils/priceHandler";
import StatusSteps from "../../dynamic-page/elements/StatusSteps";
import { BsFillInfoCircleFill } from "react-icons/bs";
import descriptiveContent from "../../../utils/descriptiveContent";
import ArbitNormalBadge from "../../sub-elements/normal-badge";
import ItemID from "../../dynamic-page/components/ItemID";
import StatusHistory from "../../goods-acceptance/components/SupplyStatusHistory";
import Icons from "../../orders/icons";
import ProductName from "../../dynamic-page/components/productName";
import PopupUp from "../../dynamic-page/util/popUp";
import CopyText from "../../dynamic-page/util/copyText";
import DateDisplay from "../../dynamic-page/util/dateDisplay";

const TableView = (list, handleTableFilters) => {
  const activeStyle = (status) => {
    if (status) {
      return "!text-blue-500";
    } else {
      return "text-gray-300 hover:text-gray-500";
    }
  };

  const defaultCurrency = {
    code: "TRY",
    image: undefined,
    label: "Turkish lira",
    logo: undefined,
    position: "0",
    product: 1,
    symbol: "₺",
    title: "Turkish lira",
    value: 1,
  };

  const handleSupplyCss = (item) => {
    if (item === "WEBSITE") {
      return "bg-blue-100 text-blue-700 ";
    }
    if (item === "WHOLESALER") {
      return "bg-green-100 text-green-700  ";
    }
    if (item === "MANUFACTURER") {
      return "bg-yellow-100 text-yellow-700  ";
    }
  };

  const handleImage = (item) => {
    const Globe = Icons.globeAlt;
    const Rectangle = Icons.rectangleGroup;
    const Wrench = Icons.wrench;
    if (item === "WEBSITE") {
      return <Globe className="w-3 h-3 " />;
    }
    if (item === "WHOLESALER") {
      return <Rectangle className="w-3 h-3 " />;
    }
    if (item === "MANUFACTURER") {
      return <Wrench className="w-3 h-3 " />;
    }
  };

  const Request = Icons.requestNote;
  const Purchase = Icons.purchaseNote;

  return {
    list: list,
    theme: {
      note: (item) => (
        <div className=" flex-col">
          <div className="flex flex-col justify-between items-center ">
            <PopupUp
              toggle={() =>
                descriptiveContent(
                  <div
                    className={`flex items-center justify-center  transition rounded-full ${activeStyle(
                      item?.supply_request?.comment
                    )}`}
                  >
                    <Request className="w-5 h-5 cursor-pointer" />
                  </div>,
                  "Requester note",
                  null,
                  "top"
                )
              }
              action={"click"}
              // leftSpace={style?.leftSpace}
            >
              <div
                style={{ hyphens: "auto" }}
                className={
                  "max-w-[200px] px-[8px] py-[4px] text-[14px] whitespace-pre-wrap break-all"
                }
              >
                {item?.supply_request?.comment ? (
                  Util.upperCaseString(item?.supply_request?.comment)
                ) : (
                  <span className={"text-gray-400"}>note is empty!</span>
                )}
              </div>
            </PopupUp>
          </div>
          <div className="flex flex-row justify-center items-center ">
            <PopupUp
              toggle={() =>
                descriptiveContent(
                  <div
                    className={`flex items-center justify-center  transition rounded-full ${activeStyle(
                      item?.note
                    )}`}
                  >
                    <Purchase className="w-5 h-5  cursor-pointer" />
                  </div>,
                  "Purchaser note",
                  null,
                  "top"
                )
              }
              action={"click"}
              // leftSpace={style?.leftSpace}
            >
              <div
                style={{ hyphens: "auto" }}
                className={
                  "max-w-[200px] px-[8px] py-[4px] text-[14px] whitespace-pre-wrap break-all"
                }
              >
                {item?.note ? (
                  Util.upperCaseString(item?.note)
                ) : (
                  <span className={"text-gray-400"}>note is empty!</span>
                )}
              </div>
            </PopupUp>
          </div>
        </div>
      ),
      status: (item) => {
        let status;
        if (item?.status) {
          const statusFirstWord = item?.status.split(" ")[0];
          if (statusFirstWord === "Delivered") {
            status = "completed";
          } else {
            status = statusFirstWord.toLowerCase();
          }
        } else {
          status = "awaiting";
        }
        return (
          <StatusSteps
            list={[
              {
                label: (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    fill="none"
                  >
                    <path
                      fill="currentColor"
                      d="M3 1.039a1 1 0 0 0 0 2h1.22l.305 1.222a.99.99 0 0 0 .01.042l1.358 5.43-.893.892c-1.26 1.26-.368 3.414 1.414 3.414H15a1 1 0 0 0 0-2H6.414l1-1H14a1 1 0 0 0 .894-.553l3-6A1 1 0 0 0 17 3.039H6.28l-.31-1.243A1 1 0 0 0 5 1.039H3Zm13 15.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm-9.5 1.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
                    />
                  </svg>
                ),
                status: item?.supply_request?.supply_status
                  ?.split(" ")[0]
                  ?.toLowerCase(),
              },
              {
                label: (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    fill="none"
                  >
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="M4 4.039a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1v-2a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v2a1 1 0 0 1-1 1H4a1 1 0 0 1 0-2v-12Zm3 1h2v2H7v-2Zm2 4H7v2h2v-2Zm2-4h2v2h-2v-2Zm2 4h-2v2h2v-2Z"
                      clipRule="evenodd"
                    />
                  </svg>
                ),
                status: status,
              },
            ]}
            tooltip={
              <StatusHistory
                id={item?.id}
                url="supply-purchase"
                includes={
                  "supplyRequest,acceptedBy,employee,supplyRequest.employee"
                }
              />
            }
          />
        );
      },
      "purchased at": (item) => (
        <DateDisplay item={item} date={item?.created_at} />
      ),
      id: (item) => {
        let title = "ORD"; // Default title
        let bgColor = "red-100"; // Default background color
        let textColor = "red-800"; // Default text color

        if (item?.supply_request?.reason === "warehouse_restocking") {
          title = "WHS";
          bgColor = "purple-100";
          textColor = "purple-800";
        } else if (item?.supply_request?.reason === "fulfilment_restocking") {
          title = "FLS";
          bgColor = "blue-100";
          textColor = "blue-800";
        }

        return (
          <div className=" flex-col justify-center items-center gap-1 inline-flex">
            <ItemID id={item.id} />
            {item?.purchase?.supply_request?.reason === "order" &&
            item?.purchase?.supply_request?.related_reason?.length > 0 ? (
              <PopupUp
                toggle={() => (
                  <ArbitNormalBadge
                    className={` ${
                      item?.purchase?.supply_request?.reason === "order"
                        ? "underline"
                        : ""
                    } !font-medium
                                      `}
                    title={title}
                    bgColor={bgColor}
                    textColor={textColor}
                  />
                )}
                action="click"
              >
                <div className="flex flex-col gap-1 p-2 overflow-y-auto max-h-[300px]">
                  {item?.purchase?.supply_request?.related_reason?.map(
                    (order, index) => (
                      <div
                        key={order}
                        className="flex justify-between items-center gap-2"
                      >
                        <span className="text-gray-700 font-medium">
                          {order}
                        </span>
                        <div>
                          <CopyText
                            className={"text-[12px]"}
                            text={order}
                            hint={false}
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
              </PopupUp>
            ) : (
              <ArbitNormalBadge
                className={` !font-medium 
                          `}
                title={title}
                bgColor={bgColor}
                textColor={textColor}
              />
            )}
          </div>
        );
      },

      image: (item) => (
        <div
          className={
            "w-[52px] h-[52px] max-h-[52px] justify-center items-center flex"
          }
        >
          {item?.product?.image ? (
            <ImageMagnifier
              src={item?.product?.image}
              className={"!max-h-[52px]"}
            />
          ) : (
            <img
              alt="def"
              src={"/assets/images/noImage.png"}
              className={"h-[52px] w-[52px]"}
            />
          )}
        </div>
      ),

      product: (item) => (
        <ProductName
          hide={{ brandCopy: true }}
          showApid={true}
          item={item?.product}
          user={item?.product?.brand?.assigned}
          handleTableFilters={handleTableFilters}
        />
      ),
      "supply channel": (item) => (
        <div className="flex items-center gap-1">
          <div
            className={`w-6 h-6 rounded-full  flex justify-center items-center ${handleSupplyCss(
              item?.supplier?.supply_channel_type
            )}`}
          >
            {handleImage(item?.supplier?.supply_channel_type)}
          </div>
          {item?.supplier
            ? descriptiveContent(
                <button
                  className={`flex  justify-start text-base font-medium items-center gap-1 max-w-[164px] ${
                    item?.supply_request?.url
                      ? "cursor-pointer"
                      : "cursor-default"
                  }`}
                  onClick={() => {
                    item?.supply_request?.url &&
                      window.open(item?.supply_request?.url, "_blank");
                  }}
                >
                  <span className=" truncate max-w-[128px] text-base font-medium">
                    {item?.supplier?.name}
                  </span>
                </button>,
                item?.supplier?.name.length > 14 && item?.supplier?.name
              )
            : ""}
        </div>
      ),
      cost: (item) => (
        <div className="w-[100%]  flex-col px-1 justify-start items-end gap-1 inline-flex">
          <div className="justify-center items-center gap-1 inline-flex">
            <div className="text-pink-700 text-[18px] font-bold font-['Inter'] leading-[18px]">
              {priceHandler(item?.purchase_price?.TRY)}
              <span className="text-[16px]">₺</span>
            </div>
          </div>
          <div className="justify-center items-center gap-1 inline-flex">
            <div className="text-pink-700 text-[14px] font-medium  font-['Inter'] leading-[14px]">
              ${priceHandler(item?.purchase_price?.USD)}
            </div>
            <PopupUp
              toggle={() => (
                <BsFillInfoCircleFill className="w-3 h-3 text-gray-300 hover:text-gray-500" />
              )}
              action={"hover"}
            >
              <div className="w-[186px] text-gray-500 text-xs font-normal font-['Inter'] leading-[15px]">
                Calculated according to the exchange rate of the day of
                purchase.
              </div>
            </PopupUp>
          </div>
        </div>
      ),
      qty: (item) => (
        <div className=" w-[100%]  px-1 flex-col justify-center items-end gap-0.5 inline-flex">
          <div className="justify-end items-center gap-1 inline-flex">
            <div className="text-[#5145CD] text-[18px] font-bold leading-[18px]">
              {quantityHandler(item?.quantity)}
            </div>
          </div>
          <div className="justify-end items-center gap-1 inline-flex">
            <div className="text-[#5145CD] text-[14px] font-medium  leading-none">
              {priceHandler(
                item?.purchase_price["TRY"] * item?.quantity,
                defaultCurrency
              )}
            </div>
          </div>
        </div>
      ),

      "est. delivery": (item) => (
        <div className="flex-col justify-center items-start gap-0.5 inline-flex">
          {item?.created_at ? (
            <div className="w-[128px] flex-col justify-center items-start gap-1 inline-flex">
              <div className="self-stretch justify-start items-center gap-0.5 inline-flex">
                <div className="text-gray-900 text-[14px] font-medium leading-[14px]">
                  {moment(item?.created_at)
                    .add(4, "days")
                    .format("MMM D, YYYY")}{" "}
                </div>
              </div>
              <div className="self-stretch justify-between items-center gap-1 inline-flex">
                <div
                  className={`${
                    timeLeft(
                      moment(item?.created_at).add(4, "days").format()
                    )?.includes("ago")
                      ? "text-[#C81E1E]"
                      : "text-green-600"
                  } text-xs font-medium leading-[18px]`}
                >
                  {timeLeft(moment(item?.created_at).add(4, "days").format())}{" "}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ),
    },
  };
};
export default TableView;
