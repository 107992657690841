import { createSlice } from "@reduxjs/toolkit";

import {
  createRequest,
  deleteRequest,
  fetchFilters,
  fetchRequests,
  supplyFulfilment,
  updateSupplyRequest,
} from "./thunk";
import updateObjectByPath from "../../components/dynamic-form/util/updateState";
import { updateBoundFilters } from "../updateBoundFilters";

const initialState = {
  requests: [],
  loading: "idle",
  error: null,
  isLogin: false,
  filtersMeta: {},
  boundFilters: {},
  links: {},
  filters: {
    updated_at: "",
    payment_status: [],
    supply_status: [],
    delivery_status: [],
    quantity: "",
  },
  filterLoading: "idle",
};

export const supplySlice = createSlice({
  name: "supply",
  initialState,
  reducers: {
    updateBoundFilter: updateBoundFilters,
    updateRow: (state, action) => {
      const updatedState = updateObjectByPath(
        state,
        action.payload.path,
        action.payload.value
      );
      return { ...state, ...updatedState };
    },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    fetchLoading: (state, action) => {
      state.loading = "pending";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRequests.fulfilled, (state, action) => {
        state.loading = "idle";
        state.requests = action.payload?.append
          ? [...state.requests, ...action.payload.data]
          : action.payload.data;
        state.links = action.payload["links"];
        state.meta = action.payload["meta"];
      })
      .addCase(fetchRequests.rejected, (state, action) => {
        if (state.loading === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      })
      .addCase(fetchFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload.data;
        state.filterLoading = "idle";
      })
      .addCase(fetchFilters.pending, (state, action) => {
        state.filterLoading = "pending";
      });
  },
});

export default supplySlice.reducer;

export const { updateFilter, updateBoundFilter, fetchLoading, updateRow } =
  supplySlice.actions;

export const supplyActions = supplySlice.actions;

export {
  fetchRequests,
  createRequest,
  supplyFulfilment,
  fetchFilters,
  deleteRequest,
  updateSupplyRequest,
};
