import classNames from "classnames";
import { HiOutlineCloudUpload } from "react-icons/hi";
import MainModal from "../dynamic-page/MainModal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addImageToProduct, addProductImages } from "../../store/products";
import util from "../dynamic-page/util";
import ArbitButton from "./button";
import PropTypes from "prop-types";

const ArbitUploadButton = ({
  placeholder,
  onFile,
  multipleImage,
  id,
  inputId,
  images,
  selectMainImage,
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const [linkData, setLinkData] = useState("");

  const dispatch = useDispatch();

  const onHandleLinkSrc = async (src) => {
    let links = src.split("\n");
    const payload = { images: links };

    await dispatch(addProductImages({ id, formData: payload }))
      .unwrap()
      .then((response) => {
        if (response?.status === 204 || response?.status === 200) {
          dispatch(addImageToProduct(response?.data?.data));

          if (images?.length === 0) {
            selectMainImage(response?.data?.data[0]);
          }
          setIsClicked(false);
          util.notifier({
            type: response?.data?.type,
            message: response?.data?.message,
          });
        } else {
          util.notifier({
            type: response?.data?.type,
            message: response?.data?.message,
          });
        }
      })
      .catch((err) => {
        util.notifier(err);
      });
  };
  return (
    <div className={classNames("w-[150px]")}>
      <div className="flex justify-center items-center w-full h-full ">
        <label
          htmlFor={inputId}
          className="flex flex-col justify-center items-center
                    w-full h-full bg-gray-50 rounded-[16px] border-[2px] border-gray-200 border-dashed 
                    cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 
                    hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
        >
          <div className="flex flex-col justify-center items-center pt-5 pb-6">
            <HiOutlineCloudUpload className="text-gray-500 text-[20px]" />
            <div className="mb-2 mt-[4px] flex flex-col items-center text-[12px] font-[500] text-gray-500 dark:text-gray-400">
              <span>{placeholder}</span>
              <span className="text-gray-400">or</span>
              <button
                className="cursor-pointer underline"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsClicked(!isClicked);
                }}
              >
                Import via URL
              </button>
            </div>
          </div>
          <input
            id={inputId}
            type="file"
            multiple={multipleImage}
            accept="image/*"
            className="hidden"
            onChange={(e) => {
              onFile(e);
            }}
          />
        </label>
      </div>
      {isClicked && (
        <MainModal
          item={{
            title: "Import Media via URL",
            view: (item) => (
              <form className="flex flex-col gap-3 px-2.5">
                <hr />
                <div className="">
                  <span className="text-gray-900 text-[16px] font-medium ">
                    Paste Media URLs
                  </span>
                  <div className="mt-[4px] h-full relative">
                    <textarea
                      className=" border-[1px] h-[96px] w-[786px] bg-gray-50 text-gray-500 border-gray-300 rounded-[8px]  resize-none text-[14px]"
                      name="link-src_input"
                      id="link-src_input"
                      cols="30"
                      onChange={(e) => {
                        setLinkData(e.target.value);
                      }}
                      value={linkData}
                    ></textarea>
                  </div>
                  <span className="text-gray-500 ml-1  text-[12px] ">
                    Enter one URL per line
                  </span>
                </div>
                <div className="flex justify-end items-center">
                  <div className="mx-1">
                    <ArbitButton
                      width={"120px"}
                      bg_color="white"
                      border_color="blue"
                      text_color="blue-600"
                      onClick={() => setIsClicked(false)}
                    >
                      Cancel
                    </ArbitButton>
                  </div>
                  <div className="mx-1">
                    <ArbitButton
                      width={"120px"}
                      onClick={() => {
                        setLinkData("");
                        onHandleLinkSrc(linkData);
                      }}
                    >
                      Import
                    </ArbitButton>
                  </div>
                </div>
              </form>
            ),
          }}
        />
      )}
    </div>
  );
};

ArbitUploadButton.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onFile: PropTypes.func,
  multipleImage: PropTypes.bool,
  id: PropTypes.string.isRequired,
  inputId: PropTypes.string,
  images: PropTypes.array,
  selectMainImage: PropTypes.func.isRequired,
};

export default ArbitUploadButton;
