import { createSlice } from "@reduxjs/toolkit";
import {
  bulkDeleteShippingCost,
  deleteShippingCost,
  fetchShippingCost as fetchShippingCostThunk,
  fetchShippingCostFilters,
  searchShippingCost,
} from "./thunk";
import updateObjectByPath from "../../components/dynamic-form/util/updateState";
import { updateBoundFilters } from "../updateBoundFilters";

const initialState = {
  shippingCost: [],
  loading: "idle",
  error: null,
  links: {},
  meta: {},
  filters: {
    filters: {},
    sort: null,
    page: 1,
    search: "",
  },
  filtersMeta: {},
  boundFilters: {},
};

export const shippingCostSlice = createSlice({
  name: "shippingCost",
  initialState,
  reducers: {
    updateBoundFilter: updateBoundFilters,
    updateRow: (state, action) => {
      const updatedState = updateObjectByPath(
        state,
        action.payload.path,
        action.payload.value
      );
      return { ...state, ...updatedState };
    },
    updateFilter: (state, action) => {
      state.filters = { ...action.payload };
    },
    removeShippingCost: (state, action) => {
      const ids = action.payload;
      state.shippingCost = state.shippingCost.filter(
        (curr) => !ids.includes(curr.id)
      );
    },
    bulkDeleteShippingCost: (state, action) => {
      const ids = action.payload;
      state.shippingCost = state.shippingCost.filter(
        (ShippingCost) => !ids.includes(ShippingCost.id)
      );
    },
    fetchLoading: (state, action) => {
      state.loading = "pending";
    },
  },
  extraReducers: (builder) => {
    builder
      //   .addCase(fetchShippingCostThunk.pending, (state) => {
      //     state.loading = "pending";
      //   })
      .addCase(fetchShippingCostThunk.fulfilled, (state, action) => {
        state.loading = "idle";
        state.shippingCost = action.payload.append
          ? [...state.shippingCost, ...action.payload?.data.data]
          : action.payload?.data.data;
        state.links = action.payload?.data.links;
        state.meta = action.payload?.data.meta;
      })
      .addCase(fetchShippingCostThunk.rejected, (state, action) => {
        if (action.error?.name !== "CanceledError") {
          state.loading = "idle";
          state.error = action.error;
        }
      })

      .addCase(fetchShippingCostFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload;
      });
  },
});

export const {
  removeShippingCost,
  updateShippingCostStatus,
  updateRow,
  updateFilter,
  updateBoundFilter,
  fetchShippingCostSuccess, // Export this
  appendShippingCostSuccess, // Export this
  fetchLoading, // Export this
} = shippingCostSlice.actions;

export {
  fetchShippingCostThunk as fetchShippingCost, // Ensure thunk has unique name
  deleteShippingCost,
  fetchShippingCostFilters,
  bulkDeleteShippingCost,
  searchShippingCost,
};

export default shippingCostSlice.reducer;
