import Icons from "../../icons";
import util from "../../../dynamic-page/util";
import React from "react";
import priceHandler from "../../../../utils/priceHandler";
import descriptiveContent from "../../../../utils/descriptiveContent";
import PopupUp from "../../../dynamic-page/util/popUp";
import PropTypes from "prop-types";

const Profit = ({ profit }) => {
  const togglePopup = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.34242 1.45457C6.14245 1.29672 5.85746 1.29672 5.65748 1.45457C4.558 2.32248 3.19931 2.87658 1.71658 2.98171C1.47555 2.99879 1.26866 3.17234 1.24253 3.41256C1.21439 3.6712 1.19995 3.93396 1.19995 4.20009C1.19995 7.27267 3.12459 9.89572 5.83405 10.9294C5.94089 10.9702 6.05921 10.9702 6.16605 10.9294C8.87541 9.89566 10.8 7.27266 10.8 4.20016C10.8 3.93401 10.7855 3.67122 10.7574 3.41255C10.7312 3.17234 10.5243 2.99879 10.2833 2.98171C8.80058 2.87658 7.4419 2.32248 6.34242 1.45457ZM5.99995 3.29996C6.41417 3.29996 6.74995 3.63574 6.74995 4.04996V6.14996C6.74995 6.56417 6.41416 6.89996 5.99995 6.89996C5.58574 6.89996 5.24995 6.56417 5.24995 6.14996V4.04996C5.24995 3.63574 5.58574 3.29996 5.99995 3.29996ZM5.99995 8.99996C6.33132 8.99996 6.59995 8.73133 6.59995 8.39996C6.59995 8.06858 6.33132 7.79996 5.99995 7.79996C5.66858 7.79996 5.39995 8.06858 5.39995 8.39996C5.39995 8.73133 5.66858 8.99996 5.99995 8.99996Z"
          fill="#E02424"
        />
      </svg>
    );
  };
  const Calculator = Icons.calculator;
  return (
    <div className={"flex flex-row gap-[9px] items-center w-full flex-1"}>
      <div className={"flex flex-col flex-1 items-end w-full"}>
        <span
          className={`min-w-max whitespace-no-wrap text-[16px] text-${
            profit?.profit_color_code || "grey"
          }-700 h-[21px] flex items-center gap-1 font-bold justify-center`}
        >
          <div className="flex items-center gap-[2px]">
            {profit?.has_anemic_item && (
              <PopupUp
                toggle={togglePopup}
                action={"hover"}
                innerClassName={"max-w-[300px] break-words "}
              >
                <div
                  className={
                    "text-gray-900 font-medium text-[12px] leading-normal"
                  }
                >
                  This order has items whose supply cost is close to the selling
                  price, it may be loss-making in partial. Please check before
                  supplying/shipping.{" "}
                </div>
              </PopupUp>
            )}
            {descriptiveContent(
              profit?.margin ? `${profit?.margin}%` : `-`,
              "Profit margin"
            )}
          </div>
          <PopupUp
            toggle={(selected) => (
              <Calculator
                className={`text-gray-300 hover:text-gray-500 h-[16px] ${
                  selected && "!text-gray-500"
                }`}
              />
            )}
            action={"hover"}
          >
            <div className={"px-[12px] py-[10px] flex gap-[6px] flex-col"}>
              {profit?.statCalculator &&
                Object.keys(profit?.statCalculator).map((key, index) => (
                  <div
                    className={"flex justify-between gap-[16px] min-w-[190px]"}
                    key={key}
                  >
                    <span className={"text-gray-600 font-normal text-[16px]"}>
                      {util.upperCaseString(key.replaceAll("_", " "), "word")}
                    </span>
                    <span
                      className={"text-gray-600 text-[14px] font-bold"}
                      style={{ direction: "ltr" }}
                    >
                      {profit?.statCalculator[key] != null
                        ? priceHandler(
                            profit?.statCalculator[key],
                            profit?.currency
                          )
                        : "-"}
                    </span>
                  </div>
                ))}
            </div>
          </PopupUp>
        </span>
        {profit?.profit && (
          <span
            className={
              "text-gray-700 flex-row  text-[12px] font-medium h-[18px] items-center flex justify-center"
            }
          >
            {descriptiveContent(
              priceHandler(
                profit?.statCalculator?.items_paid,
                profit?.currency
              ),
              "Sold price"
            )}
          </span>
        )}
      </div>
    </div>
  );
};

Profit.propTypes = {
  profit: PropTypes.shape({
    profit_color_code: PropTypes.string,
    has_anemic_item: PropTypes.bool,
    margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    statCalculator: PropTypes.object,
    currency: PropTypes.string,
    profit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

export default Profit;
