import React from "react";
import ImageMagnifier from "../../dynamic-page/debt/magnifier";
import util from "../../dynamic-page/util";
import moment from "moment/moment";
import NoteIcon from "../components/Note";
import StatusSteps from "../../dynamic-page/elements/StatusSteps";
import StatusHistory from "../components/SupplyStatusHistory";
import ArbitNormalBadge from "../../sub-elements/normal-badge";
import ItemID from "../../dynamic-page/components/ItemID";
import { IoArrowForward } from "react-icons/io5";
import ProductName from "../../dynamic-page/components/productName";
import descriptiveContent from "../../../utils/descriptiveContent";
import CopyText from "../../dynamic-page/util/copyText";
import PopupUp from "../../dynamic-page/util/popUp";
import removeDecimals from "../../../utils/removeDecimals";

const tableView = (list, handleTableFilters) => {
  return {
    list: list,
    theme: {
      note: (item) => (
        <NoteIcon
          notes={{
            buyer: item?.purchase?.supply_request?.comment,
            purchaser: item?.purchase?.note,
            accepter: item?.note,
          }}
        />
      ),
      status: (item) => {
        let status;

        if (item?.status) {
          const statusFirstWord = item.status.split(" ")[0];

          if (statusFirstWord === "Delivered") {
            status = "completed";
          } else {
            status = statusFirstWord.toLowerCase();
          }
        } else {
          status = "awaiting";
        }
        return (
          <StatusSteps
            list={[
              {
                label: (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    fill="none"
                  >
                    <path
                      fill="currentColor"
                      d="M3 1.039a1 1 0 0 0 0 2h1.22l.305 1.222a.99.99 0 0 0 .01.042l1.358 5.43-.893.892c-1.26 1.26-.368 3.414 1.414 3.414H15a1 1 0 0 0 0-2H6.414l1-1H14a1 1 0 0 0 .894-.553l3-6A1 1 0 0 0 17 3.039H6.28l-.31-1.243A1 1 0 0 0 5 1.039H3Zm13 15.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm-9.5 1.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
                    />
                  </svg>
                ),
                status: item?.purchase?.supply_request?.supply_status
                  ?.split(" ")[0]
                  ?.toLowerCase(),
              },
              {
                label: (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    fill="none"
                  >
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="M4 4.039a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1v-2a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v2a1 1 0 0 1-1 1H4a1 1 0 0 1 0-2v-12Zm3 1h2v2H7v-2Zm2 4H7v2h2v-2Zm2-4h2v2h-2v-2Zm2 4h-2v2h2v-2Z"
                      clipRule="evenodd"
                    />
                  </svg>
                ),
                status: status,
              },
            ]}
            tooltip={
              <StatusHistory
                id={item.id}
                url={"good-acceptance"}
                includes={
                  "purchase.supplyRequest,acceptedBy,purchase.employee,purchase.supplyRequest.employee,purchase"
                }
              />
            }
          />
        );
      },

      "accepted at": (item) => (
        <util.dateDisplay item={item} date={item?.accepted_at} />
      ),
      id: (item) => {
        let title = "ORD"; // Default title
        let bgColor = "red-100"; // Default background color
        let textColor = "red-800"; // Default text color

        if (item?.purchase?.supply_request?.reason === "warehouse_restocking") {
          title = "WHS";
          bgColor = "purple-100";
          textColor = "purple-800";
        } else if (
          item?.purchase?.supply_request?.reason === "fulfilment_restocking"
        ) {
          title = "FLS";
          bgColor = "blue-100";
          textColor = "blue-800";
        }

        return (
          <div className=" flex-col justify-center items-center gap-1 inline-flex">
            <ItemID id={item.id} />
            {item?.purchase?.supply_request?.reason === "order" &&
            item?.purchase?.supply_request?.related_reason?.length > 0 ? (
              <PopupUp
                toggle={() => (
                  <ArbitNormalBadge
                    className={` ${
                      item?.purchase?.supply_request?.reason === "order"
                        ? "underline"
                        : ""
                    } !font-medium
                                      `}
                    title={title}
                    bgColor={bgColor}
                    textColor={textColor}
                  />
                )}
                action="click"
              >
                <div className="flex flex-col gap-1 p-2 overflow-y-auto max-h-[300px]">
                  {item?.purchase?.supply_request?.related_reason?.map(
                    (order, index) => (
                      <div
                        key={order}
                        className="flex justify-between items-center gap-2"
                      >
                        <span className="text-gray-700 font-medium">
                          {order}
                        </span>
                        <div>
                          <CopyText
                            className={"text-[12px]"}
                            text={order}
                            hint={false}
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
              </PopupUp>
            ) : (
              <ArbitNormalBadge
                className={` !font-medium 
                          `}
                title={title}
                bgColor={bgColor}
                textColor={textColor}
              />
            )}
          </div>
        );
      },

      image: (item) => (
        <div
          className={
            "w-[52px] h-[52px] max-h-[52px] justify-center items-center flex"
          }
        >
          <ImageMagnifier
            src={item?.product?.image}
            className={"!max-h-[52px]"}
          />
        </div>
      ),
      product: (item) => (
        <ProductName
          hide={{ brandCopy: true }}
          showApid={true}
          item={item?.product}
          user={item?.product?.brand?.assigned}
          handleTableFilters={handleTableFilters}
        />
      ),
      "supply channel": (item) => {
        let name = "";
        if (item?.supplier?.name?.length > 30) {
          name = item?.supplier?.name;
        }

        return (
          <div className="flex flex-col px-2 justify-start w-full items-start gap-1">
            {item?.supplier
              ? descriptiveContent(
                  <button
                    className={`flex justify-start text-base font-medium items-center gap-1 w-[100%] ${
                      item?.purchase?.supply_request?.url
                        ? "cursor-pointer"
                        : "cursor-default"
                    }`}
                    onClick={() => {
                      item?.purchase?.supply_request?.url &&
                        window.open(
                          item?.purchase?.supply_request?.url,
                          "_blank"
                        );
                    }}
                  >
                    {
                      <span className=" truncate max-w-[135px] text-base font-medium">
                        {item?.supplier?.name}
                      </span>
                    }
                  </button>,
                  name
                )
              : "-"}
            <p className="text-green-700 font-medium text-[14px] flex gap-1 items-center">
              <IoArrowForward className="w-4 h-4 text-green-700" />
              {item?.warehouse?.name}
            </p>
          </div>
        );
      },
      qty: (item) => (
        // <div className="w-[79px] h-16 px-4 py-3   flex-col justify-center items-end gap-0.5 inline-flex">
        <div className="justify-center items-center px-2 gap-1 inline-flex flex-col">
          <div className="text-purple-700 text-[18px] font-bold leading-[24px] flex items-center gap-1">
            <span>{removeDecimals(item?.quantity)}</span>
          </div>
        </div>
        // </div>
      ),

      "exp.date": (item) => (
        <div className="">
          {item?.expiry_date ? (
            <div className="flex-row text-gray-700  items-start gap-1 inline-flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20px"
                height="20px"
                fill="none"
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M2.166 5.038A11.954 11.954 0 0 0 10 1.983a11.954 11.954 0 0 0 7.834 3.056c.11.65.166 1.32.166 2 0 5.226-3.34 9.67-8 11.318-4.66-1.648-8-6.093-8-11.318 0-.682.057-1.35.166-2.001Zm11.541 3.708a1 1 0 0 0-1.414-1.414L9 10.625 7.707 9.332a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4Z"
                  clipRule="evenodd"
                />
              </svg>

              <div className="self-stretch justify-start items-center gap-0.5 inline-flex">
                <div className="text-gray-700 text-base font-medium leading-[14px]">
                  {moment(item?.expiry_date).format("DD.MM.YYYY")}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ),
    },
  };
};
export default tableView;
