import { createSlice } from "@reduxjs/toolkit";
import {
  createGoodsAcceptance,
  deleteGoodsAcceptance,
  fetchGoodsAcceptances,
  fetchGoodsAcceptancesFilters,
  searchGoodsAcceptance,
  updateGoodsAcceptance,
} from "./thunk";
import { updateBoundFilters } from "../updateBoundFilters";

const initialState = {
  goods_cceptances: [],
  links: {},
  loading: "idle",
  error: null,
  filters: {
    filters: { status: 0 },
    sort: null,
    page: 1,
    search: "",
  },
  filtersMeta: {},
  boundFilters: {},
  meta: {},
};

export const carrierSlice = createSlice({
  name: "goods_cceptances",
  initialState,
  reducers: {
    updateBoundFilter: updateBoundFilters,
    fetchGoodsAcceptances: (state, action) => {
      state.goods_cceptances = action.payload;
    },
    updateFilter: (state, action) => {
      state.filters = { ...action.payload };
    },
    updateGoodsAcceptanceStatus: (state, action) => {
      const { status, ids } = action.payload;
      state.goods_cceptances = state.goods_cceptances.map((curr) => {
        if (ids.includes(curr.id)) {
          return { ...curr, status };
        }
        return curr;
      });
    },
    removeGoodsAcceptance: (state, action) => {
      const ids = action.payload;
      state.goods_cceptances = state.goods_cceptances.filter(
        (curr) => !ids.includes(curr.id)
      );
    },
    bulkdeleteGoodsAcceptances: (state, action) => {
      const ids = action.payload;
      state.goods_cceptances = state.goods_cceptances.filter(
        (Carrier) => !ids.includes(Carrier.id)
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGoodsAcceptances.pending, (state, action) => {
        state.loading = "pending";
      })
      .addCase(fetchGoodsAcceptances.fulfilled, (state, action) => {
        state.loading = "idle";
        state.goods_cceptances = action.payload?.data;
        state.links = action.payload.links;
        state.meta = action.payload.meta;
      })
      .addCase(fetchGoodsAcceptances.rejected, (state, action) => {
        if (action.error?.name !== "CanceledError") {
          state.loading = "idle";
          state.error = action.error;
        }
      })
      .addCase(fetchGoodsAcceptancesFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload;
      });
  },
});

export const {
  updateFilter,
  removeGoodsAcceptance,
  updateGoodsAcceptanceStatus,
  updateBoundFilter,
} = carrierSlice.actions;

export {
  fetchGoodsAcceptances,
  createGoodsAcceptance,
  updateGoodsAcceptance,
  deleteGoodsAcceptance,
  searchGoodsAcceptance,
  fetchGoodsAcceptancesFilters,
};

export default carrierSlice.reducer;
