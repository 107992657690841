// urlQueryLoader.js

const urlQueryLoader = (initialObject, removableFilters = []) => {
  const urlParams = new URLSearchParams(window.location.search);
  const updatedObject = { ...initialObject };
  const filters = {};

  const handleFilterKey = (key, value) => {
    const filterKey = key.slice(7, -1);
    const parsedValue = parseParamValue(value);

    if (
      parsedValue !== "" &&
      parsedValue !== null &&
      parsedValue !== undefined
    ) {
      if (filters[filterKey]) {
        if (!Array.isArray(filters[filterKey])) {
          filters[filterKey] = [filters[filterKey]];
        }
        filters[filterKey].push(parsedValue);
      } else {
        // Split the joined values back into an array
        filters[filterKey] = parsedValue?.toString().includes(",")
          ? parsedValue.split(",").map(decodeURIComponent)
          : parsedValue;
      }
    }
  };

  const handleStandardKey = (key, value) => {
    const parsedValue = parseParamValue(value);
    if (
      parsedValue !== "" &&
      parsedValue !== null &&
      parsedValue !== undefined
    ) {
      updatedObject[key] = parsedValue;
    }
  };

  for (const [key, value] of urlParams.entries()) {
    if (key.startsWith("filter[") && key.endsWith("]")) {
      handleFilterKey(key, value);
    } else if (key === "term") {
      updatedObject.search = decodeURIComponent(value); // Handle the term parameter
    } else {
      handleStandardKey(key, value);
    }
  }

  updatedObject.filters = { ...(updatedObject.filters || {}), ...filters };
  return updatedObject;
};

const parseParamValue = (value) => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
};

export default urlQueryLoader;
