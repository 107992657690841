import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DynamicTable from "../dynamic-page";
import util from "../dynamic-page/util";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";
import urlQueryLoader from "../dynamic-page/util/urlQueryLoader";

import filtersView from "./modals-view/filters";
import tableView from "./modals-view/table";

import sortView from "./modals-view/sort";
// import formView from "./modals-view/form";
// ------------------------------
// DEBT
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";
import SearchInput from "./../dynamic-page/debt/searchInput";
// ------------------------------
import getActionList from "../dynamic-page/util/getActionList";

import {
  fetchSalesListings as fetchData,
  // fetchSalesListingsFilter as fetchFilters,
  updateFilter,
  refreshAccountListings,
  fetchDefaultSettings,
  updateBoundFilter,
  fetchSalesListing,
  updateRow,
} from "../../store/fc-restocking";
import { updateSalesListingFulfilment } from "../../store/sales-listings/thunk";
import FooterPagination from "../dynamic-page/elements/FooterPagination";
import hasValue from "../dynamic-form/util/hasValue";
import notifier from "../dynamic-page/util/notifier";

import SalesHistoryModal from "./components/sales-history-modal";
import {
  closeNotification,
  startNotification,
} from "../dynamic-page/util/notifierLive";

import ListingDetailModal from "../../components/listing-detail-modal";
import SettingsModal from "./modals-view/settings";
import { IoSettingsSharp } from "react-icons/io5";
import axios from "axios";
import { createMappedFilters } from "../dynamic-page/util/pageControl";
import callEndpoint from "../dynamic-page/util/callEndpoint";
import useLoadMoreData from "../../utils/useLoadMoreData";
import Icons from "../orders/icons";
import { useLocation } from "react-router-dom";
import Button from "../dynamic-form/elements/Button";

const FcRestocking = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  // Handle selected row in table.
  const [selectedRow, setSelectedRow] = useState(null);

  const [visibleSalesHistoryModal, setVisibleSalesHistoryModal] =
    useState(false);

  const [visibleListingModal, setVisibleListingModal] = useState(false);
  const [salesModalListingData, setSalesModalListingData] = useState(null); // State to store salesListing data

  const openListingModal = (modalListing) => {
    setSalesModalListingData(modalListing); // Set salesListing data in the state
    setVisibleListingModal(true);
  };

  const openSalesHistoryModal = (modalListing) => {
    setSalesModalListingData(modalListing);
    setVisibleSalesHistoryModal(true);
  };

  const handleRefreshListings = async () => {
    const identifiers =
      selectedRow?.map((item) => item?.sales_channel_listing?.identifier) || [];
    const ids = selectedRow?.map((item) => item?.id) || [];

    if (identifiers.length === 0) {
      util.notifier({ message: "Please Select at Least One Row" });
      console.error("No identifier found in the selected row.");
      return;
    }

    setRefreshing(true);

    const notificationId = startNotification({
      title: `Refreshing ${identifiers.length} Listings`,
    });

    try {
      const rs = await dispatch(
        refreshAccountListings({ identifiers })
      ).unwrap();
      processRefreshResult(rs, identifiers, ids, notificationId);
    } catch (error) {
      console.error("Error refreshing account listings:", error);
      closeNotification(notificationId, {
        toast: {
          type: "error",
          message: "Error refreshing account listings",
        },
      });
    } finally {
      setRefreshing(false);
      setSelectedRow(null);
    }
  };

  function processRefreshResult(rs, identifiers, ids, notificationId) {
    closeNotification(notificationId, {
      toast: {
        type: rs.type === "validation_error" ? "error" : rs.type,
        message: rs.message,
        errors: rs.errors,
      },
    });

    if (rs.type !== "success") {
      return;
    }

    updateRowsWithRefreshedData(rs, identifiers, ids);
  }

  function updateRowsWithRefreshedData(rs, identifiers, ids) {
    identifiers.forEach((identifier) => {
      const updatedRowData = rs.data.find(
        (item) => item.identifier === identifier
      );
      const originalRow = pageData.fcRestockings.find(
        (item) => item.sales_channel_listing.identifier === identifier
      );

      dispatch(
        updateRow({
          path: `fcRestockings.[id:${originalRow.id}]`,
          value: {
            ...originalRow,
            sales_channel_listing: updatedRowData,
            _updatedRow: true,
          },
        })
      );
    });

    ids.forEach((id) => {
      setTimeout(() => {
        dispatch(
          updateRow({
            path: `fcRestockings.[id:${id}]._updatedRow`,
            value: false,
          })
        );
      }, 3000);
    });
  }

  // defauld VALUES for the settings modal
  // const [defaultSettings, setdefaultSettings] = useState()

  function filteredValueFromObject(data) {
    let dataToBeFitled = [];
    Object.keys(data).forEach((key) => {
      if (hasValue(data[key]) && typeof data[key] !== "object") {
        dataToBeFitled.push(data[key]);
      }
    });
    return dataToBeFitled.join(",");
  }

  // Initial page at first load.
  useEffect(() => {
    dispatch(fetchDefaultSettings())
      .then((settings) => {
        const defaultSettings = settings?.payload;
        // 'settings' will contain the result of fetchDefaultSettings
        // if (defaultSettings != null) {
        const startPageByFilters = {
          filters: {
            fc_restocking: 1,
            has_restocking: 1,
            ...(defaultSettings && {
              min_total_restocking: filteredValueFromObject(defaultSettings),
            }),
          },
          sort: "-fba_30_days_restock",
          page: 1,
          search: "",
          includes: "",
          limit: 10,
        };

        let queryLoader = urlQueryLoader();
        queryLoader = {
          ...queryLoader,
          filters: {
            ...queryLoader?.filters,
            ...(defaultSettings && {
              min_total_restocking: filteredValueFromObject(defaultSettings),
            }),
          },
        };

        const storeQuery = storeQueryBuilder(startPageByFilters, queryLoader);
        const urlQuery = urlQueryBuilder(storeQuery, null, "term", true);

        const mappedFilters = createMappedFilters(
          storeQuery,
          pageData?.boundFilters
        );

        dispatch(updateBoundFilter(mappedFilters));

        dispatch(updateFilter(storeQuery));
        dispatch(fetchData(urlQuery));
      })
      .catch((error) => {
        console.error("Error fetching default settings:", error);
      });
  }, [dispatch, location]);

  // Get data from store.
  const pageData = useSelector((state) => state.fcRestocking);
  const user = useSelector((state) => state.authUser.authUser);

  // handle refreshing
  const [refreshing, setRefreshing] = useState(false);

  // Handle order details modal.
  const [settingsModalVisibility, setSettingsModalVisibility] = useState(false);

  const { loading, loadMoreData } = useLoadMoreData({
    dispatch,
    pageData,
    fetchData,
  });

  const sortList = sortView({
    callback: (value) => {
      onSortApply(value);
    },
  });

  const actionList = {
    change_fc_eligibility: {
      label: "Change FC Eligibility",
      onClick: (row) => {
        const rowIds = row?.id ? row?.id : selectedRow.map((item) => item.id);
        fulfilmentHandler("fba", rowIds);
      },
    },
    reprocess: {
      label: "Reprocess",
      onClick: (item) => {
        callEndpoint({
          title: "Reprocessing Sales Channel Listing",
          url: `sales-channel-listing/toggle-available`,
          method: "post",
          data: {
            ids: item?.sales_channel_listing?.id
              ? [item?.sales_channel_listing?.id]
              : selectedRow.map((item) => item?.sales_channel_listing?.id),
          },
          pureData: true,
        }).then((res) => {
          if (res.type === "success") {
            onPaginationApply();
          }
        });
      },
      condition: (row) => row?.sales_channel_listing?.available === 0,
    },
    create_supply_request: {
      label: "Create Supply Request",
      onClick: (row) => {
        if (
          row?.sales_channel_listing?.product?.type === 2 ||
          (selectedRow &&
            selectedRow[0]?.sales_channel_listing?.product?.type === 2)
        ) {
          util.notifier({
            message: "Listing Should Be Connected to a Single Product",
          });
          setSelectedRow([]);
          return;
        }
        window.open(
          `/inventory/supply_requests#action=create&product=${
            row?.sales_channel_listing?.product?.id ||
            selectedRow[0]?.sales_channel_listing?.product?.id
          }&reason=fc`,
          "_blank"
        );
        setSelectedRow([]);
      },
      bulkDisable: true,
    },
    create_offer: {
      label: "Create Offer",
      onClick: (row) => {
        window.open(
          `/listing/offers#action=create&
      &identifier=${row?.sales_channel_listing?.identifier}
      &identifier_type=${row?.sales_channel_listing?.identifier_type}
      &account=${row?.account?.id}
      &channel=${row?.sales_channel_listing?.channel?.id}
      `,
          "_blank"
        );
      },
      bulkDisable: true,
    },
    go_to_offers: {
      label: "Go To Offers",
      onClick: (row) => {
        window.open(
          `/listing/offers?search=&include=account&filter%5Bidentifier%5D=${
            row?.sales_channel_listing?.identifier ||
            selectedRow?.[0].sales_channel_listing?.identifier
          }&page=1`,
          "_blank"
        );
      },
    },
  };

  const statusList = {
    1: {
      label: "Pending",
      actions: [
        actionList.reprocess,
        actionList.change_fc_eligibility,
        actionList.create_supply_request,
        actionList.create_offer,
        actionList.go_to_offers,
      ],
    },
  };

  // handle actions of statuses
  //
  const actions = (row) => {
    return getActionList(row, statusList);
  };

  // Handle Pagination

  const onHandleLimit = (limit) => {
    onPaginationApply(1, limit);
  };

  // Apply filter sate to redux store and fetch data.
  const onSearchApply = (search) => {
    setSelectedRow([]);

    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      search: search,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery));

    dispatch(fetchData(urlQuery));
  };

  const onFilterApply = (filters, bound) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    dispatch(updateBoundFilter({ filters, bound }));
    dispatch(updateFilter(storeQuery));

    dispatch(fetchData(urlQuery));
  };

  const onSortApply = (sort) => {
    const currentStoreFilters = pageData?.filters;
    let changeSort;

    if (sort === currentStoreFilters?.sort) {
      changeSort = null;
    } else if (currentStoreFilters?.sort === `-${sort}`) {
      changeSort = sort;
    } else {
      changeSort = `-${sort}`;
    }

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      sort: changeSort,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    dispatch(updateFilter(storeQuery));

    dispatch(fetchData(urlQuery));
  };

  const onPaginationApply = async (page, limit) => {
    setSelectedRow(null);
    const currentStoreFilters = pageData?.filters;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      limit: limit || currentStoreFilters.limit || 10,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    await dispatch(updateFilter(storeQuery), currentStoreFilters);
    await dispatch(fetchData(urlQuery));
  };

  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };

  const fulfilmentHandler = async (item, id, index) => {
    await dispatch(
      updateSalesListingFulfilment({
        type: item,
        payload: Array.isArray(id) ? id : [id],
      })
    )
      .then(({ payload }) => {
        if (payload?.data?.type === "success") {
          if (Array.isArray(id)) {
            onPaginationApply();
            util.notifier(payload?.data);
          } else {
            dispatch(fetchSalesListing(id)).then((rs) => {
              dispatch(
                updateRow({
                  path: `fcRestockings.[id:${id}]`,
                  value: rs.payload.data,
                })
              );
              util.notifier(payload?.data);
            });
          }
        }
      })
      .catch((error) => {
        notifier({ type: "error", content: error });
      });
  };

  const Arrow = Icons.ArrowPath;

  const filterCount =
    Object.values(pageData?.filters?.filters ?? {}).filter(
      (item) => item != null && item !== ""
    ).length - 3;

  const calculateHeight = () => {
    if (window.innerWidth >= 1300)
      return `calc(100vh - ${filterCount > 0 ? 250 : 200}px)`;
    if (window.innerWidth >= 1000)
      return `calc(100vh - ${filterCount > 0 ? 270 : 234}px)`;
    if (window.innerWidth >= 768)
      return `calc(100vh - ${filterCount > 0 ? 340 : 300}px)`;
    return `calc(100vh - ${filterCount > 0 ? 370 : 234}px)`;
  };

  const contentHeight = calculateHeight();

  // Render View of Page
  return (
    <>
      <ArbitProductPage.Page className="flex flex-col">
        {/* Page Title, and buttons of Add,Filter,Sort,Action are here. */}
        <ArbitProductPage.Title
          title={"FC Restocking"}
          //   form={{
          //     callback: (rs) => {
          //       setModalVisibility(true);
          //     },
          //   }}
          sort={{
            items: sortList,
            selected: pageData?.filters?.sort,
          }}
          filter={{
            filters: pageData?.boundFilters,
            pageFilters: pageData?.filters?.filters,
            items: filtersView(pageData?.filters?.filters),
            callback: (rs, bound) => {
              onFilterApply(rs, bound);
            },
            count:
              Object.values(pageData?.filters?.filters ?? {}).filter(
                (item) => item != null && item !== ""
              ).length -
              2 -
              (pageData?.filters?.filters?.min_7_days_restocking_count ===
                undefined ||
              pageData?.filters?.filters?.min_7_days_restocking_count == null
                ? 0
                : 1) -
              (pageData?.filters?.filters?.min_30_days_restocking_count ===
                undefined ||
              pageData?.filters?.filters?.min_30_days_restocking_count == null
                ? 0
                : 1) -
              (pageData?.filters?.filters?.min_60_days_restocking_count ===
                undefined ||
              pageData?.filters?.filters?.min_60_days_restocking_count == null
                ? 0
                : 1) -
              (pageData?.filters?.filters?.min_total_restocking === undefined ||
              pageData?.filters?.filters?.min_total_restocking == null
                ? 0
                : 1),
            discard: [
              "fc_restocking",
              "has_restocking",
              "min_total_restocking",
            ],
          }}
          actionList={actions(selectedRow)}
          options={[
            {
              callBack: handleRefreshListings,

              data: refreshing,
              view: ({ callBack, data }) => (
                <Button
                  item={{
                    label: (
                      <div
                        className={`flex flex-row items-center  justify-center gap-[4px] `}
                      >
                        <div className={"flip-x transform scale-x-[-1]"}>
                          <Arrow
                            className={`h-5 w-5 ${
                              data ? "animate-spin rotate-[reverse]" : ""
                            }`}
                          />
                        </div>
                      </div>
                    ),
                    buttonType: "icon",
                    className: "!w-auto !min-w-[28px] !h-[28px] !p-0 ",
                  }}
                  // onClick={handleRowClick}
                  onClick={() => {
                    if (!data) {
                      callBack();
                    }
                  }}
                />
              ),
              condition: () => selectedRow?.length > 0,
            },
            {
              callBack: () => {
                setSettingsModalVisibility(true);
              },
              order: 3,
              data: refreshing,
              view: ({ callBack, data }) => (
                <Button
                  item={{
                    label: <IoSettingsSharp className={`h-5 w-5`} />,
                    buttonType: "icon",
                    className: "!min-w-[28px] !h-[28px] !p-0 ",
                  }}
                  onClick={() => {
                    if (!data) {
                      callBack();
                    }
                  }}
                />
              ),
            },
          ]}
        />

        {/* Tabs and search input are here. */}
        <div
          className={
            "flex flex-row justify-between px-[16px] py-[4px] items-center"
          }
        >
          <SearchInput
            className="w-[362px]"
            placeholder="Search..."
            onSubmit={onSearchApply}
            defaultValue={pageData?.filters?.search}
          />
        </div>

        {/* Table is here. */}
        <ArbitProductPage.Content className="content">
          <style>
            {`.content .contt { height: ${contentHeight} !important; }`}
          </style>

          <DynamicTable
            view={tableView}
            data={tableView(
              pageData.fcRestockings?.map((item) => {
                return {
                  ...item,
                  status: 1,
                };
              }),
              fulfilmentHandler,
              openSalesHistoryModal, // Pass the function here
              openListingModal
            )}
            actions={actions}
            onSelect={onSelectRow}
            selectedRow={selectedRow}
            loading={pageData.loading}
            meta={pageData?.meta}
            appending={loading}
            loadMoreData={loadMoreData}
            style={{
              table: {
                style: { width: "100%", minWidth: "100%" },
              },
              header: {
                className: "justify-center !px-[16px] ",
                "tracking id": { className: "!pr-[30px]" },
                image: { className: "hidden" },
                type: { className: "!py-[0px] hidden" },
              },
              row: {
                className:
                  "!p-[0px] !items-center !justify-center flex text-[12px]  ",
                // "name": { className: "!min-w-fit min-w-max !w-fit !max-w-fit" },
                description: {
                  className: "!min-w-fit min-w-max !w-fit !max-w-fit",
                },
                _actions: { className: "!py-[0px]" },
                "Listing sales": { className: "!py-[0px]" },
              },
            }}
          />
        </ArbitProductPage.Content>

        {/* Pagination is here. */}
        <FooterPagination
          meta={pageData?.meta}
          pageData={pageData}
          loading={pageData.loading}
          onLimit={onHandleLimit}
        />
      </ArbitProductPage.Page>
      {visibleSalesHistoryModal && (
        <SalesHistoryModal
          show={visibleSalesHistoryModal}
          onClose={() => setVisibleSalesHistoryModal(false)}
          item={salesModalListingData}
        />
      )}

      {visibleListingModal && (
        <ListingDetailModal
          show={visibleListingModal}
          onClose={() => setVisibleListingModal(false)}
          modalListing={salesModalListingData}
          fulfilmentHandler={fulfilmentHandler}
        />
      )}

      {settingsModalVisibility && (
        <div className={"!text-[14px]"}>
          <SettingsModal
            form={(data) => [
              {
                name: "min_7_days_restocking_count",
                label: "For 7 Days Restockings",
                placeholder: "3",
                type: "Input",
                innerClassName: "!bg-gray-50",
                clear: true,
                className: "col-span-1",
                reset: true,
                defaultValue:
                  data?.min_fba_7_days != null
                    ? data?.min_fba_7_days.toString()
                    : null,
              },
              // for 30 days , 60 days
              {
                name: "min_30_days_restocking_count",
                label: "For 30 Days Restockings",
                placeholder: "6",
                type: "Input",
                innerClassName: "!bg-gray-50",
                clear: true,
                className: "col-span-1",
                reset: true,
                defaultValue:
                  data?.min_fba_30_days != null
                    ? data?.min_fba_30_days.toString()
                    : null,
              },
              {
                name: "min_60_days_restocking_count",
                label: "For 60 Days Restockings",
                placeholder: "10",
                type: "Input",
                innerClassName: "!bg-gray-50",
                clear: true,
                className: "col-span-1",
                reset: true,
                defaultValue:
                  data?.min_fba_60_days != null
                    ? data?.min_fba_60_days.toString()
                    : null,
              },
            ]}
            actions={[
              {
                label: "Save",
                onClick: async (data) => {
                  delete data?._validation;

                  const postData = {
                    min_fba_7_days: data?.min_7_days_restocking_count,
                    min_fba_30_days: data?.min_30_days_restocking_count,
                    min_fba_60_days: data?.min_60_days_restocking_count,
                    created_by: user?.id,
                  };
                  if (
                    filteredValueFromObject(data) ===
                    pageData?.filters?.filters?.min_total_restocking
                  ) {
                    setSettingsModalVisibility(false);
                    return;
                  }

                  await axios.post(
                    "/api/v1/account-listings/fc-restocking-settings",
                    postData
                  );
                  onFilterApply({
                    min_total_restocking: filteredValueFromObject(data),
                  });

                  setSettingsModalVisibility(false);
                },
              },
            ]}
            cancel={() => {
              //handleUpdateSingleRow(calledItemInAction)
              // setCalledItemInAction(null)
              setSettingsModalVisibility(false);
            }}
          />
        </div>
      )}
    </>
  );
};

export default FcRestocking;
