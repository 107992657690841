import * as Yup from "yup";
import ProductImage from "../../dynamic-form/components/productImage";
import upperCaseString from "../../dynamic-page/util/upperCaseString";

const formView = (formDefaultValues, params) => {
  return [
    {
      name: "name",
      label: "Name",
      placeholder: "Supply channel name",
      type: "Input",
      clear: false,
      labelClassName: "!text-gray-900 !font-medium",
      reset: false,
      defaultValue: formDefaultValues?.name || null,
      className: "col-span-1",
      validation: Yup.string().required("Name is required"),
    },
    {
      name: "ucode",
      label: "3-letter Short Code",
      placeholder: "ex. BGC,..",
      type: "Input",
      clear: false,
      labelClassName: "!text-gray-900 !font-medium",
      reset: false,
      defaultValue: formDefaultValues?.ucode || null,
      className: "col-span-1",
      validation: Yup.string().required("Ucode is required"),
      disabled: !!formDefaultValues?.id,
    },
    {
      name: "supply_channel_type",
      label: "Type",
      placeholder: "Website",
      type: "Select",
      list: [
        { label: "Website", value: "WEBSITE" },
        { label: "Wholesaler", value: "WHOLESALER " },
        { label: "Manufacturer", value: "MANUFACTURER " },
      ],
      clear: false,
      labelClassName: "!text-gray-900 !font-medium",
      reset: false,
      defaultValue: formDefaultValues?.supply_channel_type || null,
      className: "col-span-1",
      validation: Yup.string().required("Type is required"),
    },
    {
      name: "logo_file",
      label: "Upload Image or Logo",
      placeholder: "Select a Single Product to add more to the Grouped Product",
      type: "Module",
      module: ProductImage,
      className: "col-span-3",
      defaultValue: formDefaultValues?.logo || null,
      labelClassName: "!text-gray-900 !font-medium",
      optional: true,
    },
    {
      name: "phone",
      label: "Phone Number",
      defaultValue: formDefaultValues?.phone || null,
      placeholder: "+90 (541) 230 49 97",
      // innerClassName: "h-[48px] bg-gray-50 !px-[16px] text-[16px]",
      reset: false,
      clear: false,
      labelClassName: "!text-gray-900 !font-medium",
      className: "col-span-1",
      optional: true,
    },
    {
      name: "url",
      label: "Website",
      defaultValue: formDefaultValues?.url || null,
      placeholder: "website.com",
      // innerClassName: "h-[48px] bg-gray-50 !px-[16px] text-[16px]",
      reset: false,
      clear: false,
      labelClassName: "!text-gray-900 !font-medium",
      className: "col-span-1",
      optional: (formData) => formData?.supply_channel_type !== "WEBSITE",
      validation: (formData) => {
        // Generate validation dynamically based on formData
        if (formData.supply_channel_type === "WEBSITE") {
          return Yup.string().required("Website is required");
        } else {
          return Yup.string(); // No validation
        }
      },
    },
    {
      label: "Mail Address",
      name: "email",
      defaultValue: formDefaultValues?.email || null,
      placeholder: "info@website.com",
      // innerClassName: "h-[48px] bg-gray-50 !px-[16px] text-[16px]",
      reset: false,
      clear: false,
      labelClassName: "!text-gray-900 !font-medium",
      className: "col-span-1",
      optional: true,
    },
    {
      name: "primary_contact",
      type: "RadioButton",
      defaultValue: formDefaultValues?.primary_contact || 1,
      className: "col-span-3",
      outerClassName: "!gap-[105px]",
      list: [
        { label: "Set as Primary", value: "PHONE" },
        { label: "Set as Primary", value: "WEBSITE" },
        { label: "Set as Primary", value: "EMAIL" },
      ],
    },
    {
      label: "Adress",
      name: "address",
      defaultValue: formDefaultValues?.address || "",
      placeholder: "ex. Oruc Reis Mah. Tekstilkent Cad. A23 No: 30 Esenler",
      // innerClassName: "h-[48px] bg-gray-50 !px-[16px] text-[16px]",
      reset: false,
      clear: false,
      labelClassName: "!text-gray-900 !font-medium",
      className: "col-span-2",
      optional: true,
    },
    {
      name: "country_id",
      label: "Country", //+ (data?.country ? typeof data?.country === "object" ? data?.country?.id : data?.country : null),
      placeholder: "Select country",
      type: "Select",
      validation: Yup.string().required("Country is required"),
      api: {
        url: "/api/v1/country",
        query: "?filter[name]={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: item?.name,
          flag: item?.flag,
        }),
      },
      features: {
        formatOptionLabel: ({ label, flag }) => (
          <div
            className={"flex flex-row gap-[12px] items-center min-h-[25px] "}
          >
            {flag && (
              <span
                className={
                  "w-[28px] h-[20px] overflow-hidden flex flex-col justify-center items-center  "
                }
              >
                <img
                  alt="flag"
                  src={flag.toLowerCase()}
                  className={"object-cover w-[28px] h-[20px] rounded-[2px]"}
                />
              </span>
            )}
            <span>{upperCaseString(label)}</span>
          </div>
        ),
      },
      className: "col-span-1",
      defaultValue: formDefaultValues?.country_id || null,
      reset: false,
      clear: false,
      labelClassName: "!text-gray-900 !font-medium",
    },
    {
      name: "note",
      label: "Note",
      placeholder: "Enter your notes",
      type: "Textarea",
      rows: "3",
      defaultValue: formDefaultValues?.note || null,
      className: "col-span-3",
      optional: true,
      labelClassName: "!text-gray-900 !font-medium",
    },
  ];
};
export default formView;
