/**
 * @author Redwan Jemal
 * @description ProductComponents
 */

import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import DynamicTable from "../dynamic-page";
import Icons from "../orders/icons";
import { BsFillInfoCircleFill } from "react-icons/bs";
import ImageMagnifier from "../dynamic-page/debt/magnifier";
import util from "../dynamic-page/util";
import PopUPList from "../product-list/componetns/popUPList";
import ProductName from "../dynamic-page/components/productName";
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import PropTypes from "prop-types";
import { fetchBundledProducts as fetchData } from "../../store/products";
import FormModal from "../dynamic-page/FormModal";
import productComponentFormView from "./modals-view/product-component-form";
import constant from "../../constant";
import axios from "axios";
import callEndpoint from "../dynamic-page/util/callEndpoint";
import descriptiveContent from "../../utils/descriptiveContent";
import shortenString from "../dynamic-page/util/shortenString";
import priceHandler from "../../utils/priceHandler";

const tableView = (list, productData, setData, type) => {

  
  const convertedListCount = (item) => {
    if (item?.inventory_detail?.length === 0) {
      return [["No inventory details available"]];
    }
    if (item?.inventory_detail) {
      return item?.inventory_detail?.map((detail) => [
        detail?.name,
        `${detail?.available_quantity}`,
      ]);
    }
  };

  const convertedListCost = (item) => {
    if (item?.inventory_detail?.length === 0) {
      return [["No inventory details available"]];
    }
    if (item?.inventory_detail) {
      return item?.inventory_detail?.map((detail) => [
        detail?.name,
        detail?.cost!==null ? `$${detail?.cost}`:"-",
      ]);
    }
  };

  const getSumOfProducts = (arr) => {
    let sum = 0;
    arr?.map((item) => {
      sum += item?.cost;
    });
    return sum;
  };

  const WeightBox=Icons.weightBox
  const WeightTarazo=Icons.weightTarazo
  const Del=Icons.del
  return {
    list: list ?? [],
    theme: {
      apid: (item) => (
        <div className={"flex flex-col gap-[4px] justify-start w-full"}>
          <div>
            {item?.type === 1 ? (
              <div className="w-[104px] h-[22px] justify-start items-start inline-flex">
                <div className="px-2.5 py-0.5 bg-cyan-100 rounded-md justify-center items-center gap-1 flex">
                  <div className="text-center text-cyan-900 text-xs font-medium leading-[18px]">
                    Single Product
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-[119px] h-[22px] justify-start items-start inline-flex">
                <div className="px-2.5 py-0.5 bg-sky-100 rounded-md justify-center items-center gap-1 flex">
                  <div className="text-center text-indigo-800 text-xs font-medium leading-[18px]">
                    Grouped Product
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={"flex flex-row gap-[4px]"}>
            <button
              className="text-blue-700 text-xs font-normal leading-[18px] hover:text-blue-800 cursor-pointer"
              onClick={() => window.open(`/product/${item?.id}`, "_blank")}
            >
              {item?.apid}
            </button>
            <util.copyText
              className={"text-[12px]"}
              text={item?.apid}
              hint={false}
            />
          </div>
        </div>
      ),
      "created at": (item) => (
        <div className={"flex flex-col gap-[4px] justify-start w-full"}>
          <div className="text-gray-900 text-sm font-normal leading-[14px]">
            {moment(item?.created_at).format("DD.MM.YYYY")}
          </div>
          <div className="text-gray-600 text-xs font-normal leading-[18px]">
            {moment(item?.created_at).format("HH:mm")}
          </div>
        </div>
      ),
      image: (item) => (
        <div
          className={
            "w-[56px] h-[64px] max-h-[64px] justify-center items-center flex"
          }
        >
          <ImageMagnifier src={item?.image} className={"!max-h-[64px]"} />
        </div>
      ),
      product: (item) => (
        <ProductName
          item={item}
          user={item?.brand?.assigned}
          hide={{ type: true }}
        />
      ),
      quantity: (item) => (
        <div className="text-purple-700 text-2xl font-bold flex flex-row justify-end gap-[4px] items-center leading-none">
          {item?.quantity ?? "-"}
        </div>
      ),
      "wh inv.": (item) => {
        
        return (
          <div
            className={
              "flex flex-col gap-[0px] leading-none justify-start w-full"
            }
          >
            <div
              className={`${
                item?.type === 1 ? "text-purple-700" : "text-orange-400"
              } text-2xl font-bold flex flex-row justify-center gap-[4px] items-center leading-none`}
            >
              {descriptiveContent(
                item?.inventory_count != null ? item?.inventory_count : "-",
                "Inventory Count"
              )}
              <util.popUp
                toggle={(selected) => (
                  <BsFillInfoCircleFill className="w-[16px] h-[16px] text-gray-300 hover:text-gray-500 " />
                )}
                action={"hover"}
              >
                <div className="flex flex-col space-y-1">
                  {item?.type === 2 && item?.groups?.length ? (
                    <p className="flex flex-col  text-gray-700 font-normal text-sm gap-2">
                      created from the following:
                      {item?.groups?.map((group) => (
                        <button
                        key={group?.product?.id}
                          className="flex justify-between items-center p-2 shadow cursor-pointer gap-2 rounded-md"
                          onClick={() =>
                            window.open(
                              `/product/${group?.product?.id}`,
                              "_blank"
                            )
                          }
                        >
                          <span className="flex items-center gap-2 ">
                            <img
                              alt={"product"}
                              src={group?.product?.image}
                              className="rounded-md w-5 h-5"
                            />{" "}
                            {shortenString(group?.product?.title, 30, true)}
                          </span>
                          <span className="p-1 bg-gray-100 rounded-md">
                            {group?.quantity} pcs
                          </span>
                        </button>
                      ))}
                    </p>
                  ) : (
                    ""
                  )}
                  <PopUPList list={convertedListCount(item)} />
                </div>
              </util.popUp>
            </div>
            <div className="text-purple-700  text-base font-medium flex flex-row justify-center gap-[4px] items-center leading-none">
              {descriptiveContent(
                getSumOfProducts(item?.inventory_detail) !== 0
                  ? "$" + priceHandler(getSumOfProducts(item?.inventory_detail))
                  : "-",
                "Inventory Cost"
              )}
              <util.popUp
                toggle={(selected) => (
                  <BsFillInfoCircleFill className="w-[12px] h-[12px] text-gray-300 hover:text-gray-500 " />
                )}
                action={"hover"}
              >
             
                  <PopUPList list={convertedListCost(item)} />
                
              </util.popUp>
            </div>
            {/* <div className="text-purple-700 text-base font-medium flex flex-row justify-end gap-[4px] items-center leading-none"> - </div> */}
          </div>
        )
      },
      "avg. cost": (item) => (
        <div className={"flex flex-col gap-[0px] justify-start w-full "}>
          <div className="text-pink-700   text-2xl font-bold leading-normal  flex flex-row justify-center gap-[4px] items-center">
            {descriptiveContent(
              item?.average_cost_usd != null
                ? "$" + item?.average_cost_usd
                : "-",
              "Average Inventory Cost"
            )}
          </div>
          <div className="text-pink-700 text-base font-medium leading-none  flex flex-row justify-center gap-[4px] items-center">
            {descriptiveContent(
              item?.average_cost_try != null
                ? item?.average_cost_try + "₺"
                : "-",
              "Average Inventory Cost"
            )}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              fill="none"
            >
              <path
                fill="#D1D5DB"
                fillRule="evenodd"
                d="M7.2 4.238a.6.6 0 1 1 0-1.2h3a.6.6 0 0 1 .6.6v3a.6.6 0 1 1-1.2 0V5.087L7.024 7.663a.6.6 0 0 1-.848 0L4.8 6.287 2.224 8.863a.6.6 0 0 1-.848-.849l3-3a.6.6 0 0 1 .848 0L6.6 6.39l2.152-2.152H7.2Z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      ),
      weight: (item) => (
        <div className={"flex flex-row gap-[6px] items-center justify-center"}>
          <div className={"flex flex-col gap-[2px] justify-start w-full"}>
            <div className="text-blue-400 text-xs font-normal leading-3">
              FBM
            </div>
            <div
              className={"flex flex-row gap-[2px]  items-center text-blue-700"}
            >
              {descriptiveContent(
                item?.fbm_actual_weight != null ||
                  item?.fbm_dimensional_weight ? (
                  <>
                    {item?.fbm_dimensional_weight >= item?.fbm_actual_weight ? (
                      <WeightBox className="h-[16px]" />
                    ) : (
                      <WeightTarazo className={"h-[16px]"} />
                    )}
                    <div className="text-blue-700 text-2xl font-bold leading-normal">
                      {item?.fbm_dimensional_weight >= item?.fbm_actual_weight
                        ? item?.fbm_dimensional_weight
                        : item?.fbm_actual_weight}
                    </div>
                  </>
                ) : (
                  <div className="text-blue-700 text-2xl font-bold leading-normal">
                    -
                  </div>
                ),
                "FBM Weight"
              )}
            </div>
          </div>
          <div className="flex flex-col gap-[2px] justify-start w-full">
            <div className="text-orange-400 text-xs font-normal leading-3">
              FBA
            </div>
            <div className="flex flex-row gap-[2px] items-center text-orange-700">
              {descriptiveContent(
                item?.fba_actual_weight != null ||
                  item?.fba_dimensional_weight ? (
                  <>
                    {item?.fba_dimensional_weight >= item?.fba_actual_weight ? (
                      <WeightBox className="h-[16px]" />
                    ) : (
                      <WeightTarazo className={"h-[16px]"} />
                    )}
                    <div className="text-orange-700 text-2xl font-bold leading-normal">
                      {item?.fba_actual_weight >= item?.fba_dimensional_weight
                        ? item?.fba_actual_weight
                        : item?.fba_dimensional_weight}
                    </div>
                  </>
                ) : (
                  <div className="text-orange-700 text-2xl font-bold leading-normal">
                    -
                  </div>
                ),
                "FBA Weight"
              )}
            </div>
          </div>
        </div>
      ),
      delete: (item) => (
        <div className="flex flex-row gap-[4px] justify-center items-center">
          <button
            className="text-red-400 text-xs font-normal leading-[18px]"
            onClick={() => {
              callEndpoint({
                title: "Delete Product: " + item?.title,
                url:
                  type === 1
                    ? `product/${productData?.id}/group/${item?.id}`
                    : `product/${item?.id}/group/${productData?.id}`,
                method: "DELETE",
                data: item.id,
              }).then((res) => {
                if (res?.type === "success") {
                  setData((prev) => {
                    return prev.filter((product) => product.id !== item.id);
                  });
                }
              });
            }}
          >
            {descriptiveContent(
              <Del className="h-5 w-5 cursor-pointer hover:text-red-700" />,
              "Detatch Product"
            )}
          </button>
        </div>
      ),
    },
  };
};

const ProductComponents = ({
  product_id,
  type,
  productData,
  components = [],
  setShowProductModal: setModalVisibility,
  showProductModal: modalVisibility,
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(
      type === 1 ? productData.child_products : productData.parent_products
    );
  }, [product_id, dispatch, productData]);

  const handleNotifyUser = (rs) => {
    util.notifier(rs);
  };

  const onSubmitApply = async (data) => {
    const existing = components.map((item) => {
      return {
        product_id: item.id,
        quantity: item.quantity,
      };
    });
    const updatedComponents = [...existing, data];

    const formData = new FormData();

    formData.append("type", 2);

    if (updatedComponents) {
      updatedComponents.forEach((obj, index) => {
        for (let key in obj) {
          formData.append(`products[${index}][${key}]`, obj[key]);
        }
      });
    }
    const response = await axios.post(
      constant.APIURL.UPDATE_PRODUCT + product_id,
      formData,
      { withCredentials: true }
    );
    const newProducts = await axios.get(
      constant.APIURL.GET_PRODUCT + "/" + product_id,
      { withCredentials: true }
    );
    setData(newProducts.data?.data?.child_products);
    handleNotifyUser(response?.data);
    if (response?.data?.type === "success") {
      setModalVisibility(false);
    }

    const startPageByFilters = {
      filters: { type: type, parentId: product_id },
      includes: "",
      sort: null,
      page: 1,
      search: "",
    };
    const storeQuery = storeQueryBuilder(startPageByFilters, {});
    const urlQuery = urlQueryBuilder(storeQuery);

    dispatch(fetchData(urlQuery));
  };

  return (
    <div className="">
      <div className="overflow-x-auto max-w-[100%]">
        <DynamicTable
          view={tableView}
          data={tableView(data, productData, setData, type)}
          // actions={actions}
          mini={true}
          miniWidth={'400'}
          loading={"idle"}
          selectedRow={[]}
          ver={2}
          style={{
            table: {
              style: { width: "100%", minWidth: "100%" },
            },
            header: {
              className: "justify-center !px-[16px]",
              supply: { className: "hidden" },
              image: { className: "!px-[0px]" },
              delete: { className: "hidden" },
              quantity: { th: type === 2 ? "hidden" : "" },
            },
            row: {
              className:
                " !px-[8px] !py-[0px] text-[14px] !items-center justify-center flex ",
              image: {
                className: "!py-[0px] w-[64px] justify-center !px-[0px]",
                td: "!py-[0px] w-[64px]",
              },
              _actions: { className: "!py-[0px]" },
              product: { className: "!w-[100%] !justify-center w-[100%]" },
              note: { className: "!py-[10px]" },
              apid: { td: "!w-[140px]", className: "!max-w-[140px]" },
              quantity: { td: type === 2 ? "hidden" : "" },
              "created at": {
                td: "!w-[140px] !max-w-[140px]",
                className: "!w-[140px] !max-w-[140px]",
              },
              "exp.date": { className: "!pr-[16px]" },
            },
            hiddenActions: true,
            hideSelect: true,
          }}
        />
      </div>

      {modalVisibility && (
        <FormModal
          title={"Products"}
          formView={productComponentFormView}
          visibility={modalVisibility}
          data={[]}
          onClose={() => {
            setModalVisibility(false);
          }}
          onSubmit={onSubmitApply}
        />
      )}
    </div>
  );
};
ProductComponents.propTypes = {
  product_id: PropTypes.number.isRequired,
  type: PropTypes.number.isRequired,
  productData: PropTypes.shape({
    child_products: PropTypes.array,
    parent_products: PropTypes.array,
  }).isRequired,
  components: PropTypes.array,
  setShowProductModal: PropTypes.func.isRequired,
  showProductModal: PropTypes.bool.isRequired,
};

export default ProductComponents;
