import upperCaseString from "../../dynamic-page/util/upperCaseString";

const filtersView = (selectedFilters, filtersMeta) => {
  return [
    {
      name: "title",
      label: "Title",
      placeholder: "Filter by Title",
      type: "Input",
      toggle: true,
      isClearable: true,
      defaultValue: selectedFilters?.title,
    },
    {
      name: "url",
      label: "URL",
      placeholder: "Filter by URL",
      type: "Input",
      toggle: true,
      isClearable: true,
      defaultValue: selectedFilters?.url,
    },
    {
      name: "channel",
      label: "Channel",
      placeholder: "Filter by Channel",
      type: "Select",
      api: {
        url: "/api/v1/channel",
        query: "?term={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: item?.name,
          logo: item?.logo,
        }),
      },
      features: {
        isMulti: true,
        formatOptionLabel: ({ label, logo }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
              className={
                "w-[25px] h-[15px] block overflow-hidden flex flex-col justify-center items-center "
              }
            >
              <img src={logo || "/assets/images/img-placehoder.png"} />
            </span>
            <span>{upperCaseString(label)}</span>
          </div>
        ),
      },
      defaultValue: selectedFilters?.channel,
      toggle: true,
      clear: true,
    },
    {
      name: "price",
      label: "Price",
      placeholder: "Filter by Price",
      type: "Range",
      defaultValue: selectedFilters?.price,
      toggle: true,
      clear: true,
    },
    {
      name: "quantity",
      label: "Quantity",
      placeholder: "Filter by Quantity",
      type: "Range",
      defaultValue: selectedFilters?.quantity,
      toggle: true,
      clear: true,
    },
    {
      name: "product_id",
      label: "Product",
      type: "Select",
      api: {
        url: "/api/v1/product",
        query: "?term={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: upperCaseString(item?.title, "word", true),
        }),
      },
      reset: true,
      clear: true,
      toggle: true,
      style: {
        control: {
          minHeight: "auto",
        },
        menu: {
          whiteSpace: "wrap !important",
        },
      },
      placeholder: "Filter by products",
      defaultValue: selectedFilters?.product_id,
    },
  ];
};

export default filtersView;
