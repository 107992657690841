import { createSlice } from "@reduxjs/toolkit";

import {
  addProductImages,
  createAttributes,
  createProduct,
  createProductFromData,
  deleteProduct,
  deleteProductImage,
  duplicateProduct,
  fetchAttributeGroups,
  fetchAttributes,
  fetchBundledProducts,
  fetchProduct,
  fetchProducts,
  selectMainImage,

  getAttributeTypes,
  getBrands,
  getCategories,
  getProductLocale,
  getRule,
  UpdateAttribute,
  UpdateProduct,
} from "./thunk";
import updateObjectByPath from "../../components/dynamic-form/util/updateState";

const initialState = {
  productlist: [],
  brands: [],
  categories: [],
  attributeTypes: {},
  rule: {},
  links: {},
  filters: {
    title: "",
    apid: "",
    category: "",
    brand: "",
  },
  meta: {},
  filtersMeta: {},
  boundFilters: {},
  locale: [],
  attributeGroups: [],
  attributes: {},
  product: [],
  productImageState: "idle",
  productInBunddle: [],
  products: [],
  purchase: "",
  purchaseStatus: "idle",
  restock: "",
  restockStatus: "idle",
  loading: "idle",
  loadingBundles: "idle",
  error: null,
  isLogin: false,
};

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        Object?.keys(filters).forEach((key) => {
          if (currentBoundFilters[key]) {
            delete filters[key];
          }
        });
        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    addImageToProduct: (state, action) => {
      state.product.images = action.payload;
    },
    deleteImage: (state, action) => {
      const newImages = action.payload.productData.images.filter(
        (item) => item?.id !== action.payload.imageId
      );
      if (action.payload.temp) {
        state.product.image = null;
      }
      state.product.images = newImages;
    },
    makeMainImage: (state, action) => {
      state.product.image = action.payload;
    },
    changeProductTitle: (state, action) => {
      state.product.title = action.payload;
    },
    changeProductBrand: (state, action) => {
      state.product.brand_id = action.payload;
    },
    addAttributes: (state, action) => {
      const attributes = state.product.attributes || {};
      const otherAttributes = attributes.Other || [];
    
      const updatedAttributes = otherAttributes.map((item) =>
        item.attribute_id === action.payload.attribute_id ? action.payload : item
      );
    
      if (!updatedAttributes.find(item => item.attribute_id === action.payload.attribute_id)) {
        updatedAttributes.push(action.payload);
      }
    
      state.product.attributes = {
        ...attributes,
        Other: updatedAttributes,
      };
    },
    updateRow: (state, action) => {
      const updatedState = updateObjectByPath(
        state,
        action.payload.path,
        action.payload.value
      );
      
      return { ...state, ...updatedState };
    },
    removeAttributes: (state, action) => {
      const attributes = state.product.attributes || {};
      const otherAttributes = attributes.Other || [];
    
      const newArr = otherAttributes.filter(
        (item) => item?.attribute_id !== action.payload
      );
    
      state.product.attributes = {
        ...attributes,
        Other: newArr,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProduct.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
        }
      })
      .addCase(fetchProduct.fulfilled, (state, action) => {
        if (state.loading === "pending") {
          state.loading = "idle";
          state.product = action.payload?.data;
        }
      })
      .addCase(fetchProduct.rejected, (state, action) => {
        if (state.loading === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      })
      .addCase(fetchProducts.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
        }
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        if (state.loading === "pending") {
          state.loading = "idle";
          state.products = action.payload?.data;
          state.links = action.payload["links"];
          state.meta = action.payload["meta"];
        }
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        if (state.loading === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      })
      .addCase(getProductLocale.fulfilled, (state, action) => {
        state.locale = action.payload?.data?.data;
      })
      .addCase(fetchAttributeGroups.fulfilled, (state, action) => {
        state.attributeGroups = action.payload?.data;
      })
      .addCase(fetchAttributes.fulfilled, (state, action) => {
        state.attributes = action.payload?.data;
      })
      .addCase(getBrands.fulfilled, (state, action) => {
      
        state.brands = action.payload?.data;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
 
        state.categories = action.payload?.data;
      })
      .addCase(getAttributeTypes.fulfilled, (state, action) => {
        state.attributeTypes = action.payload?.data;
      })
      .addCase(getRule.fulfilled, (state, action) => {
        state.rule = action.payload?.data;
      })
      .addCase(fetchBundledProducts.pending, (state, action) => {
        if (state.loadingBundles === "idle") {
          state.loadingBundles = "pending";
        }
      })
      .addCase(fetchBundledProducts.fulfilled, (state, action) => {
        if (state.loadingBundles === "pending") {
          state.loadingBundles = "idle";
          state.productInBunddle = action.payload?.data;
          state.links = action.payload["links"];
          state.meta = action.payload["meta"];
        }
      })
      .addCase(fetchBundledProducts.rejected, (state, action) => {
        if (state.loadingBundles === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      })
      .addCase(addProductImages.pending, (state, action) => {
        state.productImageState = "pending";
      })
      .addCase(addProductImages.fulfilled, (state, action) => {
        state.productImageState = "idle";
      })
  },
});

export const {
  updateFilter,
  addImageToProduct,
  deleteImage,
  makeMainImage,
  changeProductTitle,
  changeProductBrand,
  removeAttributes,
  updateRow,
  addAttributes,
} = productsSlice.actions;

export {
  addProductImages,
  fetchAttributeGroups,
  fetchProduct,
  fetchProducts,
  deleteProductImage,
  UpdateAttribute,
  fetchAttributes,
  getCategories,
  UpdateProduct,
  selectMainImage,
  getBrands,
  getAttributeTypes,
  getRule,
  createAttributes,
  createProductFromData,
  deleteProduct,
  duplicateProduct,
  getProductLocale,
  createProduct,
  fetchBundledProducts,
};

export default productsSlice.reducer;
