import React from "react";
import util from "../../../dynamic-page/util";
import Icons from "../../icons";
import {
  apiActions,
} from "../../../../store/orders";
import { useDispatch } from "react-redux";
import ConnectProductModal from "../../../dynamic-page/components/ConnectForm";
import { OrderItem } from "../../models-view/forms";
import { HiFilter } from "react-icons/hi";
import descriptiveContent from "../../../../utils/descriptiveContent";
import PropTypes from "prop-types";

const mapListings = (item) => ({
  id: item?.first_item?.id,
  identifier: item?.first_item?.item_sku,
  url: item?.sales_channel_listing?.url,
  label: item?.first_item?.item_title,
  channel: item?.sales_channel_account?.channel?.name,
  brand: item?.sales_channel_account?.name,
  image: item?.first_item?.image_url,
});

const Item = ({  orderId, order, onFilterApply, callBack }) => {
  const productList = order?.first_item;
  const multiple = order?.items_count > 1;
  const dispatch = useDispatch();
  const Link=Icons.link
  let content;

if (multiple) {
  content = (
    <div
      className={
        "text-pink-700 text-xs font-medium flex flex-row gap-[4px] items-center"
      }
    >
      {order?.items_count} Items
    </div>
  );
} else if (productList?.product_id) {
  content = (
    <div className="flex items-center gap-[2px] !text-[12px] whitespace-nowrap">
      <span
        className={`w-[18px] h-[18px] rounded-full  flex justify-center items-center text-xs font-medium ${
          productList?.product_type === 1
            ? "text-teal-700 bg-teal-100"
            : "text-indigo-700 bg-indigo-100"
        } p-[0px]`}
      >
        {productList?.product_type === 1 ? "S" : "G"}
      </span>
      <button
        className={
          "text-blue-700 hover:text-blue-800 cursor-pointer flex flex-row gap-[4px] whitespace-nowrap"
        }
        onClick={() => {
          if (order?.first_item?.product_id) {
            window.open(`/product/${productList?.product_id}`, "_blank");
          }
        }}
      >
        {productList?.brand?.assigned ? (
          <>
            <img
              alt="avatar"
              src={
                productList?.brand?.assigned?.image_url ||
                "/assets/images/defaultAvatar.png"
              }
              className={"w-[18px] h-[18px] rounded-full object-cover"}
            />
            {util.upperCaseString(
              productList?.brand?.assigned?.name,
              "word"
            )}
          </>
        ) : (
          <div className="text-gray-400">Unassigned</div>
        )}
      </button>
      {productList?.product_apid && (
        <util.copyText
          className={"text-[12px]"}
          text={productList?.product_apid}
          hint={false}
        />
      )}

      {productList?.brand?.assigned &&
        descriptiveContent(
          <HiFilter
            onClick={(e) => {
              e.stopPropagation();
              onFilterApply(
                {
                  assigned_user: [order?.first_item?.brand?.assigned?.id],
                },
                null,
                {
                  assigned_user: [
                    {
                      label: order?.first_item?.brand?.assigned?.name,
                      value: order?.first_item?.brand?.assigned?.id,
                    },
                  ],
                }
              );
            }}
            className={` w-3 h-3  cursor-pointer text-gray-300 hover:text-gray-500`}
          />,
          "Filter by Assigned User"
        )}
    </div>
  );
} else {
  content = (
    <div className="flex items-center gap-[2px] !text-[12px]">
      <ConnectProductModal
        key={orderId}
        toggle={() => (
          <div className={"flex flex-row gap-[2px]"}>
            <span className="w-[18px] h-[18px] rounded-full bg-red-100 flex justify-center items-center text-xs font-medium text-red-800 p-[0px]">
              <Link className={"w-[11px] h-[11px]"} />
            </span>
            <div
              className={
                "text-red-700 hover:text-red-800 cursor-pointer whitespace-nowrap"
              }
            >
              Not Connected
            </div>
          </div>
        )}
        callBack={(data) => {
          const product_id = data?.id;
          const connectionAction = "connect-product";
          return dispatch(
            apiActions({
              data: {
                product_id: product_id,
                sku: productList?.item_sku,
              },
              action: connectionAction,
              orderId: `${orderId}/${productList?.id}`,
            })
          ).then((result) => {
            if (result?.payload?.data?.type === "success") {
              util.notifier({
                type: "success",
                message: "Product connected successfully",
              });
              callBack(order, "red");
              return true;
            }
          });
        }}
        mapListings={mapListings}
        noAddMore={true}
        defaultListing2={order}
        salesChannelItem={OrderItem}
        newDesign={true}
        urlParams={{
          title: productList?.item_title,
          image: productList?.image_url || productList?.item_image,
        }}
      />
    </div>
  );
}
  

  return (
    <div className={"w-full  flex flex-col py-1.5 justify-start  h-[64px]"}>
      <div className={"flex flex-row gap-[8px] !h-[34px] justify-between"}>
        <util.truncateText
          maxLines={multiple ? 1 : 2}
          className={"!text-[12px] !leading-[18px] "}
          // innerClassName='flex items-center h-full'
          tooltip={true}
        >
          {productList?.item_title}
        </util.truncateText>
      </div>

      <div className={"flex  justify-between mr-1"}>
      <div
            className={
              "flex flex-row items-center gap-[4px] text-gray-500 whitespace-nowrap"
            }
          >
            <util.popUp
              toggle={(selected) => (
                <div className="text-[12px] leading-[18px] font-medium">
                  {productList?.item_sku}
                </div>
              )}
              action={"hover"}
              // leftSpace={style?.leftSpace}
            >
              <div className={"min-w-max px-[8px] py-[4px]"}>SKU</div>
            </util.popUp>
            <util.copyText
              className={"text-[12px]"}
              text={productList?.item_sku}
              hint={false}
            />
            {productList?.item_sku &&
              descriptiveContent(
                <HiFilter
                  onClick={(e) => {
                    e.stopPropagation();
                    onFilterApply(
                      {
                        item_sku: order?.first_item?.item_sku,
                      },
                      null
                    );
                  }}
                  className={` w-3 h-3 cursor-pointer text-gray-300 hover:text-gray-500`}
                />,
                "Filter by item SKU"
              )}
          </div>
       {content}
      </div>
    </div>
  );
};
Item.propTypes = {
  orderId: PropTypes.string.isRequired,
  order: PropTypes.shape({
    items_count: PropTypes.number,
    first_item: PropTypes.shape({
      id: PropTypes.string,
      product_id: PropTypes.string,
      product_type: PropTypes.number,
      item_sku: PropTypes.string,
      item_title: PropTypes.string,
      image_url: PropTypes.string,
      brand: PropTypes.shape({
        assigned: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          image_url: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
  onFilterApply: PropTypes.func.isRequired,
  callBack: PropTypes.func.isRequired,
};

export default Item;
