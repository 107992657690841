import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DynamicTable from "../dynamic-page";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import urlQueryLoader from "../dynamic-page/util/urlQueryLoader";
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";

import filtersView from "./modals-view/filters";
import tableView from "./modals-view/table";
import useLoadMoreData from "../../utils/useLoadMoreData";

// ------------------------------
// DEBT
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";
// ------------------------------
import {
  fetchShippingCost as fetchData,
  fetchShippingCostFilters as fetchFilters,
  updateBoundFilter,
  updateFilter,
  updateRow as updateSingleRow,
} from "../../store/shipping-cost-page";

import ConfirmModal from "../dynamic-page/util/confirmModal";
import FooterPagination from "../dynamic-page/elements/FooterPagination";
import callEndpoint from "../dynamic-page/util/callEndpoint";
import { createMappedFilters } from "../dynamic-page/util/pageControl";
import { useLocation } from "react-router-dom";

const ShippingCost = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  // Initial page at first load.
  useEffect(() => {
    const startPageByFilters = {
      filters: {},
      sort: null,
      page: 1,
      limit: 10,
      search: "",
      includes: "fromState.country,toState.country",
    };
    const storeQuery = storeQueryBuilder(
      startPageByFilters,
      urlQueryLoader(startPageByFilters)
    );

    const urlQuery = urlQueryBuilder(storeQuery, null, "", true);

    let hash = window.location.hash;
    hash = hash.replace("#", "?"); // Replace first '&' with '?'
    const params = new URLSearchParams(hash);
    const serializedObject = params.get("bounded");
    const parsedObject = JSON.parse(decodeURIComponent(serializedObject));

    const mappedBounded = {
      ...pageData?.boundFilters,
      ...parsedObject,
    };
    const mappedFilters = createMappedFilters(storeQuery, mappedBounded);
    dispatch(updateBoundFilter(mappedFilters));
    dispatch(updateFilter(storeQuery));
    dispatch(fetchData(urlQuery));
    dispatch(fetchFilters());
  }, [location]);

  // Get data from store.
  const pageData = useSelector((state) => state.shippingCost);

  const tableData = useSelector(
    (state) => state.shippingCost?.shippingCost || []
  );

  const { loading, loadMoreData } = useLoadMoreData({
    dispatch,
    pageData,
    fetchData,
  });

  const [selectedRow, setSelectedRow] = useState(null);

  // Handle form modal.
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(null);

  // Initial action list.
  const actionList = {
    delete: {
      label: "Delete",
      bulkDisable: true,
      onClick: (item) => {
        setConfirmationModalVisibility({
          infoText: "Are you sure you want to delete the shipping cost/s?",
          confirmText: "Yes, Delete",
          callBack: (setSubmit) => {
            const rows = item?.id ? [item] : selectedRow;
            const ids = item?.id
              ? [item?.id]
              : selectedRow.map((item) => item?.id);
            callEndpoint({
              title: "Delete Shipping Cost",
              url: `shipping-costs`,
              method: "DELETE",
              data: { shipping_costs: ids },
              pureData: true,
            }).then((res) => {
              if (res.type === "success") {
                setConfirmationModalVisibility(false);
                setSelectedRow([]);
                ids.map((id) => {
                  dispatch(
                    updateSingleRow({
                      path: `shippingCost.[id:${id}]`,
                      value: {
                        ...rows.find((item) => item?.id === id),
                        _updatedRow: true,
                        _updatedColor: "red",
                      },
                    })
                  );
                  setTimeout(() => {
                    dispatch(
                      updateSingleRow({
                        path: `shippingCost.[id:${id}]._deletedRow`,
                        value: true,
                      })
                    );
                    setTimeout(() => {
                      dispatch(
                        updateSingleRow({
                          path: `shippingCost.[id:${id}]`,
                        })
                      );
                    }, 1000);
                  }, 1000);
                });
              }
            });
          },
        });
      },
    },
  };

  // Initial sort list
  const sortList = {
    list: [
      {
        label: "Service Name",
        value: "service_name",
        onClick: (item) => {
          onSortApply("service_name");
        },
      },
      {
        label: "Cost",
        value: "cost",
        onClick: (item) => {
          onSortApply("cost");
        },
      },
      {
        label: "Billable Weight",
        value: "billable_weight",
        onClick: (item) => {
          onSortApply("billable_weight");
        },
      },
    ],
  };

  // handle action of statuses
  const actions = (row) => {
    return {
      count: row?.length,
      list: [actionList.delete],
    };
  };

  const onFilterApply = (filters, bound) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });

    const filterStoreQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    const filterUrlQuery = urlQueryBuilder(filterStoreQuery, null, "term");
    dispatch(updateBoundFilter({ filters, bound }));

    dispatch(fetchData(urlQuery));
    dispatch(fetchFilters(filterUrlQuery));
    dispatch(updateFilter(storeQuery));
  };

  const onSortApply = (sort) => {
    const currentStoreFilters = pageData?.filters;
    let changeSort;

    if (sort === currentStoreFilters?.sort) {
      changeSort = null;
    } else if (currentStoreFilters?.sort === `-${sort}`) {
      changeSort = sort;
    } else {
      changeSort = `-${sort}`;
    }

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      sort: changeSort,
    });
    const urlQuery = urlQueryBuilder(storeQuery);

    dispatch(updateFilter(storeQuery));
    dispatch(fetchData(urlQuery));
  };

  const onServiceNameFilter = (serviceName, bound) => {
    onFilterApply({ service_name: serviceName });
  };

  const onFromWarehouseFilter = (fromWarehouseId, bound) => {
    onFilterApply({ from_state_ids: [fromWarehouseId] }, bound);
  };

  const onStateFilter = (stateId, bound) => {
    onFilterApply({ to_state_ids: [stateId] }, bound);
  };

  const onBillableWeightFilter = (billableWeigth, bound) => {
    onFilterApply(
      { billable_weight: billableWeigth + "," + billableWeigth },
      bound
    );
  };

  const onPaginationApply = async (page, limit) => {
    setSelectedRow(null);
    const currentStoreFilters = pageData?.filters;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      limit: limit || currentStoreFilters.limit || 10,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    await dispatch(updateFilter(storeQuery), currentStoreFilters);
    await dispatch(fetchFilters(urlQuery));
    await dispatch(fetchData(urlQuery));
  };

  const onHandleLimit = (limit) => {
    onPaginationApply(null, limit);
  };

  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };

  return (
    <>
      <ArbitProductPage.Page className="flex flex-col ">
        {/* Page Title, and buttons of Add,Filter,Sort,Action are here. */}
        <ArbitProductPage.Title
          title={"Shipping Costs"}
          // params={params}
          sort={{
            items: sortList,
            selected: pageData?.filters?.sort,
          }}
          filter={{
            discard: ["created_at"],
            filters: pageData?.boundFilters,
            pageFilters: pageData?.filters?.filters,
            items: filtersView(
              pageData?.filters?.filters,
              pageData?.filtersMeta,
              pageData?.boundFilters
            ),
            callback: (rs, bound) => {
              onFilterApply(rs, bound);
            },
            count: Object.values(pageData?.filters?.filters ?? {}).filter(
              (item) =>
                Array.isArray(item)
                  ? item?.length > 0
                  : item != null && item !== ""
            ).length,
          }}
          actionList={actions(selectedRow)}
        />

        {/* Table is here. */}
        <ArbitProductPage.Content>
          <DynamicTable
            view={tableView}
            mini={true}
            miniWidth="189"
            data={tableView(
              tableData,
              onServiceNameFilter,
              onFromWarehouseFilter,
              onStateFilter,
              onBillableWeightFilter,
              actionList
            )}
            actions={actions}
            onSelect={onSelectRow}
            selectedRow={selectedRow}
            meta={pageData?.meta}
            appending={loading}
            loadMoreData={loadMoreData}
            loading={pageData?.loading}
            style={{
              hiddenActions: true,
              header: {
                className: "flex justify-start !px-4 !py-4 whitespace-nowrap",
                th: "!h-[50px]",

                "PER KG": {
                  className: "text-right flex !justify-end",
                },
                weight: {
                  className: "text-right flex !justify-end",
                },
                cost: {
                  className: "text-right flex !justify-end",
                },
                _actions: {
                  className: "!px-[5px]  ",
                },
                "FROM state, country": {
                  className: "!min-w-[184.4px]",
                },
                "TO STATE, COUNTRY": {
                  className: "!min-w-[184.4px]",
                },
              },
              row: {
                className:
                  "flex items-start justify-start  !w-max !px-4 !py-2 ",
                td: "!h-[60px]",
                cost: {
                  className: "!w-full text-right flex !justify-end items-end ",
                },
                weight: {
                  className: "!w-full text-right flex !justify-end items-end",
                },
                "PER KG": {
                  className: "!w-full text-right flex !justify-end items-end",
                },
                provider: {
                  className: "!min-w-[136px]",
                },
                country: {
                  className: "!min-w-[220px] !py-0",
                },
                "": {
                  className: " !py-1",
                  td: "w-[100%] ",
                },
                _actions: {
                  className: "!px-0 !py-3  ",
                },
              },
            }}
          />
        </ArbitProductPage.Content>
        <FooterPagination
          meta={pageData?.meta}
          pageData={pageData}
          loading={pageData?.loading}
          onLimit={onHandleLimit}
          resultsText={"shipping costs"}
        />
      </ArbitProductPage.Page>
      {confirmationModalVisibility && (
        <ConfirmModal
          show={confirmationModalVisibility}
          infoText={
            confirmationModalVisibility?.infoText ||
            "Are you sure you want to mark this order as shipped?"
          }
          onClose={() => setConfirmationModalVisibility(false)}
          confirmText={
            confirmationModalVisibility?.confirmText || "Yes, Mark as Shipped"
          }
          confirmColor="green"
          cancelText="Cancel"
          cancelColor="red"
          styles={{
            confirmButton: "!bg-green-50 ",
            cancelButton: "!bg-red-50 ",
            infoText: "!font-medium",
          }}
          onConfirm={(setSubmit) => {
            confirmationModalVisibility?.callBack(setSubmit);
          }}
        />
      )}
    </>
  );
};

export default ShippingCost;
