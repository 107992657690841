import React, { useCallback, useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import axios from "axios";
import { debounce } from "lodash";
import { HiChevronLeft, HiChevronRight, HiPlus } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";

function SelectItems({
  title,
  url,
  mapItems,
  include,
  createNewRedirect,
  ItemCard,
  multiple,
  data,
  setData,
  defaultItem,
  connectLoading,
  params,
  urlParams,
  newDesign,
  noAddMore,
  tooltip,
}) {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [searching, setSearching] = useState(true);
  const abortControllerRef = useRef(null);

  useEffect(() => {
    setInputValue("");
    setResults([]);
  }, [searching]);

  useEffect(() => {
    if (defaultItem != null) {
      setSelectedProduct(defaultItem?.id ? [defaultItem] : defaultItem);
      setSearching(false);
    }
  }, [defaultItem]);

  const fetchItems = useCallback(
    async (inputValue, page) => {
      if (!inputValue) {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        return;
      }

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      const newController = new AbortController();
      abortControllerRef.current = newController;

      setLoading(true);
      try {
        const response = await axios.get(
          `/api/v1/${url}?term=${inputValue}&${
            params ? params + "&" : ""
          }limit=3&page=${page || 1}${include || ""}&include=brand.assigned`,
          { withCredentials: true, signal: newController.signal }
        );

        if (newController.signal.aborted) {
          return;
        }

        setResults({
          results: response.data.data.map(mapItems),
          meta: response?.data?.meta,
        });
      } catch (e) {
      } finally {
        setLoading(false);
      }
    },
    [params, url, mapItems]
  );

  const debouncedFetchItems = useCallback(debounce(fetchItems, 500), [
    fetchItems,
  ]);

  const handleInputChange = (value) => {
    setInputValue(value);
    if (!value) {
      setLoading(false);
      setResults([]);
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    } else {
      debouncedFetchItems(value, 1);
    }
  };

  return (
    <div className="app flex flex-col     ">
      {/* <div className="flex justify-between items-center">
        
      </div> */}
      {multiple && searching && selectedProduct?.length > 0 && (
        <SearchResults
          inputValue={inputValue}
          fetchItems={fetchItems}
          results={{ results: selectedProduct }}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          searching={searching}
          setSearching={setSearching}
          ItemCard={ItemCard}
          data={data}
          setData={setData}
          loading={false}
          connectLoading={connectLoading}
          title={title}
          tooltip={tooltip}
          selection={true}
          multiple={multiple}
          noAddMore={noAddMore}
        />
      )}
      {searching && (
        <SearchBar
          newDesign={newDesign}
          title={title}
          loading={loading}
          inputValue={inputValue}
          setInputValue={handleInputChange}
          setSearching={setSearching}
          multiple={multiple}
          fetchItems={debouncedFetchItems}
          onResults={(newResults) => setResults(newResults)}
          createNewRedirect={createNewRedirect}
          data={data}
          setData={setData}
          selectedProduct={selectedProduct}
          urlParams={urlParams}
          tooltip={tooltip}
        />
      )}
      <div className="">
        <SearchResults
          inputValue={inputValue}
          fetchItems={fetchItems}
          results={!searching ? { results: selectedProduct } : results}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          searching={searching}
          setSearching={setSearching}
          ItemCard={ItemCard}
          data={data}
          title={title}
          tooltip={tooltip}
          setData={setData}
          loading={loading}
          multiple={multiple}
          noAddMore={noAddMore}
          connectLoading={connectLoading}
        />
      </div>
    </div>
  );
}

export default SelectItems;

const SearchBar = ({
  fetchItems,
  loading,
  inputValue,
  setInputValue,
  title,
  createNewRedirect,
  multiple,
  setSearching,
  newDesign,
  selectedProduct,
  urlParams,
  tooltip,
}) => {
  // const debouncedFetchProducts = useCallback(debounce(fetchItems, 500), []);

  const handleInputChange = useCallback(async (inputValue) => {
    setInputValue(inputValue);
  });
  return (
    <div className="flex flex-col w-[630px] gap-2 ">
      <div className="flex justify-between items-center ">
        <div className="flex items-center gap-2.5">
          <p className="text-[16px] leading-6 font-semibold">{title} </p>
          {tooltip ?? tooltip}
        </div>
        <div className="flex gap-2.5 items-center">
          {createNewRedirect && (
            <button
              className="text-blue-700 text-[14px] flex font-medium leading-[21px] w-full hover:text-blue-800 cursor-pointer items-center gap-1"
              onClick={() => {
                //window.open(`/product/single_products#action=create&title=${item?.title}&image=${item?.image}`, '_blank')
                window.open(
                  `/product/list#action=add${
                    urlParams?.title ? "&title=" + encodeURIComponent(urlParams?.title) : ""
                  }${urlParams?.image ? "&image=" + urlParams?.image : ""}`,
                  "_blank"
                );
              }}
            >
              Didn’t find? Create new...
            </button>
          )}
        </div>
        {/* {multiple && selectedProduct && (
          <Icons.reply
            className="w-4 h-4 text-blue-700 cursor-pointer"
            onClick={() => {
              setInputValue("");
              setSearching(false);
            }}
          />
        )} */}
      </div>
      <div className="relative flex w-[630px] bg-gray-50 items-center border  rounded-lg rounded-bl-lg   border-gray-300 ">
        <input
          type="text"
          placeholder="Search..."
          value={inputValue || ""}
          onChange={(e) => handleInputChange(e.target.value)}
          className="w-[93%] p-2 bg-gray-50 border-none !outline-none rounded-lg "
        />
        <button
          className="absolute flex justify-center items-center right-0 p-2 border  border-blue-700 bg-blue-700 w-[42px] h-[42px] rounded-r-lg text-white rounded-tr-lg rounded-br-lg  
        "
        >
          <FiSearch className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
};

const SearchResults = ({
  results,
  fetchItems,
  inputValue,
  setSelectedProduct,
  setSearching,
  searching,
  ItemCard,
  selectedProduct,
  setData,
  data,
  loading,
  connectLoading,
  selection,
  title,
  tooltip,
  multiple,
  noAddMore,
}) => {
  const meta = results?.meta;
  const handlePagination = async (page) => {
    await fetchItems(inputValue, page);
  };
  let content;
  if (!loading) {
    if (results?.results?.length > 0) {
      content = results.results.map((item, index) => (
        <ItemCard
          key={item?.id}
          item={item}
          index={index}
          results={results}
          searching={searching}
          setSelectedProduct={setSelectedProduct}
          setSearching={setSearching}
          selectedProduct={selectedProduct}
          data={data}
          setData={setData}
          connectLoading={connectLoading}
          selection={selection}
        />
      ));
    } else {
      content = null;
    }
  } else {
    content = <Skeleton width={630} height={176} count={3} />;
  }
  return (
    <div className="flex flex-col gap-2   ">
      <div className="flex justify-between items-center gap-1.5">
        {!searching && (
          <div className="flex justify-between items-center gap-1.5">
            <p className="text-[16px] leading-6 font-semibold">{title} </p>
            {tooltip}
          </div>
        )}
        {multiple &&
          selectedProduct?.length > 0 &&
          !searching &&
          !noAddMore && (
            <button
              className="text-blue-700 font-medium text-[14px] flex items-center gap-1 justify-end   hover:text-blue-800 cursor-pointer"
              onClick={() => {
                setSearching(true);
              }}
            >
              <HiPlus className="w-3 h-3 text-blue-700" />
              Add more
            </button>
          )}
      </div>

      <div className="max-h-custom lg overflow-y-auto ">{content}</div>
      {/* next and prev buttons rendered dependinb on meta.currentpage */}
      {results?.results?.length > 0 && meta?.last_page > 1 && (
        <div className="  flex justify-between">
          {meta?.current_page !== 1 ? (
            <button
              className="text-gray-500 flex items-center text-[16px] leading-6"
              disabled={meta?.current_page === 1}
              onClick={() => handlePagination(meta?.current_page - 1)}
            >
              <HiChevronLeft className="w-5 h-5 mr-1" /> Previous
            </button>
          ) : (
            <div></div>
          )}
          {meta?.current_page !== meta?.last_page ? (
            <button
              className="text-gray-500 flex items-center text-[16px] leading-6"
              disabled={meta?.current_page === meta?.total_pages}
              onClick={() => handlePagination(meta?.current_page + 1)}
            >
              Next <HiChevronRight className="w-5 h-5 ml-1" />
            </button>
          ) : (
            <div></div>
          )}
        </div>
      )}
    </div>
  );
};

SelectItems.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  mapItems: PropTypes.func,
  include: PropTypes.string,
  createNewRedirect: PropTypes.func,
  ItemCard: PropTypes.elementType.isRequired,
  multiple: PropTypes.bool,
  data: PropTypes.array,
  setData: PropTypes.func,
  defaultItem: PropTypes.object,
  connectLoading: PropTypes.bool,
  params: PropTypes.string,
  urlParams: PropTypes.object,
  newDesign: PropTypes.bool,
  noAddMore: PropTypes.bool,
  tooltip: PropTypes.node,
};

SearchResults.propTypes = {
  results: PropTypes.shape({
    results: PropTypes.array,
    meta: PropTypes.shape({
      last_page: PropTypes.number,
      current_page: PropTypes.number,
    }),
  }).isRequired,
  fetchItems: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  setSelectedProduct: PropTypes.func.isRequired,
  setSearching: PropTypes.func.isRequired,
  searching: PropTypes.bool.isRequired,
  ItemCard: PropTypes.elementType.isRequired,
  selectedProduct: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  connectLoading: PropTypes.bool,
  selection: PropTypes.bool,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.node,
  multiple: PropTypes.bool,
  noAddMore: PropTypes.bool,
};
