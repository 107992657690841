import Icons from "../../icons";
import React, { useEffect, useState } from "react";
import { apiActions } from "../../../../store/orders";
import ShipmentLabelList from "./shipmentLabelList";
import priceHandler from "../../../../utils/priceHandler";
import { useDispatch, useSelector } from "react-redux";
import { IoStorefront } from "react-icons/io5";
import descriptiveContent from "../../../../utils/descriptiveContent";
import callEndpoint from "../../../dynamic-page/util/callEndpoint";
import PropTypes from "prop-types";
import Button from "../../../dynamic-form/elements/Button";
import PopupUp from "../../../dynamic-page/util/popUp";
import CopyText from "../../../dynamic-page/util/copyText";
import shortenString from "../../../dynamic-page/util/shortenString";
import notifier from "../../../dynamic-page/util/notifier";
import upperCase from "../../../dynamic-page/util/upperCaseString";

const ChevronRight = Icons.chevronRight;
const QuickShip = Icons.quickShip;
const Track = Icons.track;

const ShipmentLabel = ({ shipment, orderItem, callBack, updateRow }) => {
  const dispatch = useDispatch();

  const [shipmentData, setShipmentData] = useState(null);
  const data = useSelector((state) => state.orders.orders);

  useEffect(() => {
    const findItem = data?.find((item) => item?.id === orderItem?.id);
    const innerShipment = shipment;
    const billableWeight =
      findItem?.billable_weight || orderItem.billable_weight;
    const returnData = { ...innerShipment, billableWeight: billableWeight };

    setShipmentData(returnData);
  }, [data, shipment]);

  const handleCreateShipment = () => {
    if (!orderItem?.ship_to_state) {
      notifier({
        title: "State is required",
        message:
          "You cannot create a label without selecting the city in the order details. Please select the destination city.",
        status: "info",
      });
      return;
    }
    callBack({
      action: "createShipment",
      data: {
        id: orderItem?.id,
        defaultWarehouse: orderItem?.allocation_warehouse_id || 3,
        items_qty: orderItem?.items_total_qty,
        ship_to_state: { id: orderItem?.ship_to_state?.id },
        billable_weight: orderItem?.billable_weight,
      },
    });
  };
  const handleCreateQuickShipment = () => {
    callEndpoint({
      title: "Create Label",
      url: `orders/create-shipment-label/${orderItem?.id}/${orderItem?.allocation_warehouse_id}/${shipment?.suggested_shipping_cost?.id}`,
      method: "post",
    }).then((rs) => {
      if (rs?.type === "success") {
        updateRow(orderItem?.id);
      }
    });
  };

  const handlePrintLabel = (print) => {
    callBack({
      action: "printLabel",
      data: {
        id: orderItem?.id,
      },
    });
  };
  const handleViewLabel = (print) => {
    callBack({
      action: "viewLabel",
      data: {
        id: orderItem?.id,
      },
    });
  };

  const handleFilterState = (stateId, state) => {
    callBack({
      action: "filterState",
      data: {
        stateId: stateId,
        state: state,
      },
    });
  };

  const [disabled, setDisabled] = useState(false);
  const handleIsPrimaryToggle = (id) => {
    setDisabled(true);
    dispatch(
      apiActions({
        action: "shipment-labels/mark-as-primary",
        itemId: id,
      })
    ).then((rs) => {
      setDisabled(false);
      notifier({
        type: rs.payload?.data?.type,
        message: rs.payload?.data?.message,
      });
      if (rs.payload?.data?.type === "success") {
        updateRow(orderItem?.id);
      }
    });
  };
  const labelListRef = React.useRef(null);

  const handleViewSecodaryLabel = (label) => {
    labelListRef.current?.close();
    callBack({
      action: "viewLabel",
      data: {
        file: label?.label_file_png,
      },
    });
  };

  const primaryShipmentLabel = shipmentData?.shipment_labels?.find((item) => {
    if (item?.is_primary === 1 && item.is_hidden === 0) {
      return item;
    }
  });

  const hiddenPrimaryShipmentLabel = shipmentData?.shipment_labels?.find(
    (item) => {
      return item?.is_primary === 1 && item.is_hidden === 1;
    }
  );

  const toggle = (selected) => (
    <ToggleButton
      selected={selected}
      orderItem={orderItem}
      handleCreateShipment={handleCreateShipment}
    />
  );

  const listToggle = (selected) => (
    <ToggleComponent selected={selected} shipmentData={shipmentData} />
  );

  return (
    <div className={"max-w-[450px] min-w-[264px] w-full"}>
      {shipmentData && (
        <div
          className={"flex flex-col gap-[4px]  justify-start w-full"}
          style={{ width: "100%" }}
        >
          <div
            className={
              "flex flex-row justify-between items-center gap-[16px] w-full"
            }
            style={{ width: "100%" }}
          >
            <div className="w-full ">
              {primaryShipmentLabel && !hiddenPrimaryShipmentLabel && (
                <div
                  className={
                    "flex flex-row gap-[4px]  w-full items-center justify-between"
                  }
                >
                  <div className="flex flex-row gap-[4px]  w-full items-center ">
                    <PopupUp
                      toggle={(selected) =>
                        primaryShipmentLabel?.from_state?.country?.flag ? (
                          <button
                            onClick={() =>
                              handleFilterState(
                                primaryShipmentLabel?.from_state?.id,
                                {
                                  value: primaryShipmentLabel?.from_state?.id,
                                  label: primaryShipmentLabel?.from_state?.name,
                                }
                              )
                            }
                          >
                            {descriptiveContent(
                              <img
                                alt="flag"
                                className={
                                  "w-[22.4px] mt-1 rounded-[3px] h-[16px] cursor-pointer object-cover "
                                }
                                src={
                                  primaryShipmentLabel?.from_state?.country
                                    ?.flag
                                }
                              />,
                              primaryShipmentLabel?.from_state?.name
                            )}
                          </button>
                        ) : (
                          <IoStorefront
                            className={"w-4 h-4 text-green-300"}
                            onClick={() =>
                              handleFilterState(
                                primaryShipmentLabel?.from_state?.id
                              )
                            }
                          />
                        )
                      }
                      action={"hover"}
                    >
                      <div className={"text-[12px] text-gray-900 font-medium"}>
                        {primaryShipmentLabel?.from_state?.name}
                      </div>
                    </PopupUp>
                    {descriptiveContent(
                      primaryShipmentLabel?.international_carrier?.logo ? (
                        <img
                          alt="logo"
                          className={"w-[16px] h-[16px] object-contain"}
                          src={
                            primaryShipmentLabel?.international_carrier?.logo
                          }
                        />
                      ) : (
                        <Track className={"w-[16px] h-4 text-gray-300"} />
                      ),
                      primaryShipmentLabel?.international_carrier?.name
                    )}
                    <button
                      onClick={() => {
                        if (primaryShipmentLabel?.tracking_url) {
                          window.open(
                            primaryShipmentLabel?.tracking_url,
                            "_blank"
                          );
                        }
                      }}
                      className={
                        "cursor-pointer flex flex-row gap-[4px] items-center "
                      }
                    >
                      <span className=" text-[#1A56DB]">
                        {primaryShipmentLabel?.tracking_number}
                      </span>
                    </button>
                    <CopyText
                      className={"text-[12px]"}
                      text={primaryShipmentLabel?.tracking_number}
                      hint={""}
                    />
                  </div>
                  <span className="text-[14px]  leading-[21px] font-semibold text-[#BF125D]">
                    {primaryShipmentLabel?.billed_cost && (
                      <span>${primaryShipmentLabel?.billed_cost}</span>
                    )}
                  </span>
                </div>
              )}
              {!primaryShipmentLabel &&
                !hiddenPrimaryShipmentLabel &&
                shipmentData?.suggested_shipping_cost && (
                  <div
                    className={
                      "flex flex-row   gap-[4px] box-content cursor-pointer w-full items-center"
                    }
                  >
                    {descriptiveContent(
                      shipmentData?.suggested_shipping_cost?.carrier
                        ?.international_carrier?.logo ? (
                        <img
                          alt="logo"
                          className={"min-w-[20px] h-[20px]"}
                          src={
                            shipmentData?.suggested_shipping_cost?.carrier
                              ?.international_carrier?.logo
                          }
                        />
                      ) : (
                        <Track className={"w-[16px] h-4 text-gray-300"} />
                      ),
                      shipmentData?.suggested_shipping_cost
                        ?.international_carrier?.name
                    )}

                    <span
                      className={
                        "text-[12px] leading-[18px] font-medium text-gray-900"
                      }
                    >
                      {
                        shipmentData?.suggested_shipping_cost?.carrier
                          ?.international_carrier?.name
                      }
                    </span>
                    <span className="text-[12px] leading-[12px] text-gray-600">
                      {shipmentData?.suggested_shipping_cost?.service_name
                        ? shortenString(
                            shipmentData?.suggested_shipping_cost?.service_name,
                            30
                          )
                        : "-"}
                    </span>
                  </div>
                )}
            </div>

            {((primaryShipmentLabel && hiddenPrimaryShipmentLabel) ||
              shipmentData?.shipment_labels?.length > 1) && (
              <PopupUp toggle={listToggle} ref={labelListRef}>
                <ShipmentLabelList
                  disabled={disabled}
                  shipmentData={shipmentData}
                  actions={{
                    handleIsPrimaryToggle: handleIsPrimaryToggle,
                    handleViewSecodaryLabel: handleViewSecodaryLabel,
                  }}
                />
              </PopupUp>
            )}
          </div>

          {primaryShipmentLabel && !hiddenPrimaryShipmentLabel && (
            <div
              className={
                "flex flex-row w-full max-w- justify-between gap-[16px]"
              }
              style={{ width: "100%" }}
            >
              <span className={"text-gray-600  flex-grow"}>
                {/* <util.expandableText
                  maxLines={1}
                  className={"text-[12px]"}
                  innerClassName="w-max"
                  tooltip={true}
                  forcePosition={"top"}
                  tooltipClassName={"text-[14px] text-gray-900 !max-w-[350px]"}
                  action={"hover"}
                  delay={500}
                  hideCopy={true}
                > */}
                {primaryShipmentLabel?.service_name
                  ? upperCase(primaryShipmentLabel?.service_name, "word")
                  : "-"}
                {/* </util.expandableText> */}
              </span>
              <div
                className={
                  "text-blue-600 h-[16px] flex flex-row items-center gap-[4px]"
                }
              >
                <Button
                  item={{ buttonType: "flat", title: "Create Label" }}
                  onClick={handleCreateShipment}
                >
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_33418_7505)">
                      <path
                        d="M7 0.5C5.61553 0.5 4.26215 0.910543 3.11101 1.67971C1.95987 2.44888 1.06266 3.54213 0.532846 4.82121C0.00303297 6.1003 -0.13559 7.50776 0.134506 8.86563C0.404603 10.2235 1.07129 11.4708 2.05026 12.4497C3.02922 13.4287 4.2765 14.0954 5.63437 14.3655C6.99224 14.6356 8.3997 14.497 9.67879 13.9672C10.9579 13.4373 12.0511 12.5401 12.8203 11.389C13.5895 10.2378 14 8.88447 14 7.5C13.9979 5.64414 13.2597 3.86491 11.9474 2.55262C10.6351 1.24033 8.85586 0.502145 7 0.5ZM10.1257 8.23684H7.73684V10.6257C7.73684 10.8211 7.65921 11.0085 7.52103 11.1467C7.38284 11.2849 7.19542 11.3625 7 11.3625C6.80458 11.3625 6.61716 11.2849 6.47898 11.1467C6.34079 11.0085 6.26316 10.8211 6.26316 10.6257V8.23684H3.87432C3.6789 8.23684 3.49148 8.15921 3.35329 8.02102C3.21511 7.88284 3.13748 7.69542 3.13748 7.5C3.13748 7.30457 3.21511 7.11716 3.35329 6.97897C3.49148 6.84079 3.6789 6.76316 3.87432 6.76316H6.26316V4.37431C6.26316 4.17889 6.34079 3.99147 6.47898 3.85329C6.61716 3.7151 6.80458 3.63747 7 3.63747C7.19542 3.63747 7.38284 3.7151 7.52103 3.85329C7.65921 3.99147 7.73684 4.17889 7.73684 4.37431V6.76316H10.1257C10.3211 6.76316 10.5085 6.84079 10.6467 6.97897C10.7849 7.11716 10.8625 7.30457 10.8625 7.5C10.8625 7.69542 10.7849 7.88284 10.6467 8.02102C10.5085 8.15921 10.3211 8.23684 10.1257 8.23684Z"
                        fill="#1A56DB"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_33418_7505">
                        <rect
                          width="14"
                          height="14"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </Button>

                <Button
                  item={{ buttonType: "flat", title: "Show Label" }}
                  onClick={handleViewLabel}
                >
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 2.25C3.2284 2.25 0 6.252 0 7.5C0 8.8065 2.4822 12.75 7 12.75C11.5178 12.75 14 8.8065 14 7.5C14 6.252 10.7716 2.25 7 2.25ZM7 9.75C6.58466 9.75 6.17865 9.61804 5.8333 9.37081C5.48796 9.12357 5.2188 8.77217 5.05985 8.36104C4.90091 7.9499 4.85932 7.4975 4.94035 7.06105C5.02138 6.62459 5.22139 6.22368 5.51508 5.90901C5.80877 5.59434 6.18295 5.38005 6.59031 5.29323C6.99767 5.20642 7.41991 5.25097 7.80364 5.42127C8.18736 5.59157 8.51534 5.87996 8.74609 6.24997C8.97684 6.61998 9.1 7.05499 9.1 7.5C9.1 8.09674 8.87875 8.66903 8.48492 9.09099C8.0911 9.51295 7.55695 9.75 7 9.75Z"
                      fill="#1A56DB"
                    />
                  </svg>
                </Button>

                <Button
                  item={{ buttonType: "flat", title: "Print Label" }}
                  onClick={handlePrintLabel}
                >
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_33418_7048)">
                      <path
                        d="M3.5 14.5H10.5C10.6857 14.5 10.8637 14.4263 10.995 14.295C11.1263 14.1637 11.2 13.9857 11.2 13.8V10.3H2.8V13.8C2.8 13.9857 2.87375 14.1637 3.00503 14.295C3.1363 14.4263 3.31435 14.5 3.5 14.5Z"
                        fill="#1A56DB"
                      />
                      <path
                        d="M12.6 5.4H1.4C1.0287 5.4 0.672601 5.5475 0.41005 5.81005C0.1475 6.0726 0 6.4287 0 6.8V11C0 11.3713 0.1475 11.7274 0.41005 11.9899C0.672601 12.2525 1.0287 12.4 1.4 12.4V10.3C1.4 9.9287 1.5475 9.5726 1.81005 9.31005C2.0726 9.0475 2.4287 8.9 2.8 8.9H11.2C11.5713 8.9 11.9274 9.0475 12.1899 9.31005C12.4525 9.5726 12.6 9.9287 12.6 10.3V12.4C12.9713 12.4 13.3274 12.2525 13.5899 11.9899C13.8525 11.7274 14 11.3713 14 11V6.8C14 6.4287 13.8525 6.0726 13.5899 5.81005C13.3274 5.5475 12.9713 5.4 12.6 5.4Z"
                        fill="#1A56DB"
                      />
                      <path
                        d="M11.9 4V1.9C11.9 1.5287 11.7525 1.1726 11.4899 0.91005C11.2274 0.6475 10.8713 0.5 10.5 0.5H3.5C3.1287 0.5 2.7726 0.6475 2.51005 0.91005C2.2475 1.1726 2.1 1.5287 2.1 1.9V4H11.9Z"
                        fill="#1A56DB"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_33418_7048">
                        <rect
                          width="14"
                          height="14"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </Button>
              </div>
            </div>
          )}

          {!primaryShipmentLabel && (
            <div
              className={
                "flex flex-row justify-between  gap-[40px] items-center"
              }
            >
              {shipmentData?.suggested_shipping_cost?.cost ? (
                <div
                  className={
                    "text-pink-700 text-[14px] leading-[21px] font-semibold"
                  }
                >
                  {"$" +
                    priceHandler(shipmentData?.suggested_shipping_cost?.cost)}
                </div>
              ) : (
                "-"
              )}

              <div className={"flex flex-row gap-[4px]"}>
                {shipmentData?.suggested_shipping_cost &&
                  (orderItem?.billable_weight <= 0 ||
                  !orderItem?.shipping_content ||
                  !orderItem?.ship_to_state ? (
                    <PopupUp toggle={toggle} action={"hover"}>
                      <div className="flex flex-col gap-1">
                        <span>
                          {shipmentData?.billableWeight <= 0 &&
                            "Billable weight is required"}
                        </span>
                        <span>
                          {!orderItem?.shipping_content &&
                            "Shipping content is required"}
                        </span>
                        <span>
                          {!orderItem?.ship_to_state &&
                            "Destination State is required"}
                        </span>
                      </div>
                    </PopupUp>
                  ) : (
                    <Button
                      item={{
                        buttonType: "primary",
                        className:
                          "h-[24px] !bg-[#1A56DB] w-[40px] px-[4px] !rounded-[6px]",
                        disabled:
                          orderItem?.billable_weight <= 0 ||
                          !orderItem?.shipping_content ||
                          !orderItem?.ship_to_state,
                      }}
                      onClick={handleCreateQuickShipment}
                    >
                      <QuickShip className={"w-[20px] p-[2px]"} />
                    </Button>
                  ))}

                {orderItem?.billable_weight <= 0 ||
                !orderItem?.shipping_content ||
                !orderItem?.ship_to_state ? (
                  <PopupUp
                    toggle={(selected) => (
                      <Button
                        item={{
                          buttonType: "alt",
                          className:
                            "!rounded-[6px] h-[24px] item-center flex gap-[8px]  !text-[#1A56DB]  !px-[12px] bg-transparent hover:!text-white hover:bg-[#1A56DB]",
                          disabled:
                            orderItem?.billable_weight <= 0 ||
                            !orderItem?.shipping_content ||
                            !orderItem?.ship_to_state,
                        }}
                        onClick={handleCreateShipment}
                      >
                        <span>Carriers</span>
                        <ChevronRight className={"w-[13px] p-[2px]"} />
                      </Button>
                    )}
                    action={"hover"}
                  >
                    <div className="flex flex-col gap-1">
                      {shipmentData?.billableWeight <= 0 && (
                        <span>
                          {shipmentData?.billableWeight <= 0 &&
                            "Billable weight is required"}
                        </span>
                      )}
                      {!orderItem?.shipping_content && (
                        <span>
                          {!orderItem?.shipping_content &&
                            "Shipping content is required"}
                        </span>
                      )}
                      {!orderItem?.ship_to_state && (
                        <span>
                          {!orderItem?.ship_to_state &&
                            "Destination State is required"}
                        </span>
                      )}
                    </div>
                  </PopupUp>
                ) : (
                  <Button
                    item={{
                      buttonType: "alt",
                      className:
                        "h-[24px] item-center flex gap-[8px]  !text-[#1A56DB] !px-[12px] bg-transparent hover:!text-white hover:bg-[#1A56DB] !rounded-[6px]",
                      disabled:
                        orderItem?.billable_weight <= 0 ||
                        !orderItem?.shipping_content ||
                        !orderItem?.ship_to_state,
                    }}
                    onClick={handleCreateShipment}
                  >
                    <span>Carriers</span>
                    <ChevronRight className={"w-[13px] p-[2px]"} />
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

ShipmentLabel.propTypes = {
  shipment: PropTypes.object.isRequired, // Define the type and requirement
  orderItem: PropTypes.object.isRequired,
  callBack: PropTypes.func.isRequired,
  updateRow: PropTypes.func.isRequired,
};

const ToggleComponent = ({ selected, shipmentData }) => (
  <div
    className={`h-[20px] min-w-[20px] flex flex-row justify-center items-center bg-purple-200 rounded-[10px] text-purple-900 font-medium hover:bg-purple-600 hover:text-white transition cursor-pointer ${
      selected && " text-white !bg-purple-600"
    }`}
  >
    {shipmentData?.shipment_labels?.length}
  </div>
);

ToggleComponent.propTypes = {
  selected: PropTypes.bool.isRequired,
  shipmentData: PropTypes.object.isRequired,
};

const ToggleButton = ({ selected, orderItem, handleCreateShipment }) => (
  <Button
    item={{
      buttonType: "alt",
      className:
        "!rounded-[6px] h-[24px] item-center flex gap-[8px]  !text-[#1A56DB]  !px-[12px] bg-transparent hover:!text-white hover:bg-[#1A56DB]",
      disabled:
        !orderItem?.content ||
        +orderItem?.billable_weight === 0 ||
        +orderItem?.transfer_items_count === 0,
    }}
    onClick={handleCreateShipment}
  >
    <span>Carriers</span>
    <ChevronRight className={"w-[13px] p-[2px]"} />
  </Button>
);

ToggleButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  orderItem: PropTypes.object.isRequired,
  handleCreateShipment: PropTypes.func.isRequired,
};

export default ShipmentLabel;
