const sortView = ({ callback }) => {
  return {
    list: [
      {
        label: "Title",
        value: "title",
        onClick: (item) => {
          callback("title");
        },
      },
      {
        label: "Created by",
        value: "created_by",
        onClick: (item) => {
          callback("created_by");
        },
      },
      {
        label: "Created at",
        value: "created_at",
        onClick: (item) => {
          callback("created_at");
        },
      },
      {
        label: "Inventory Count",
        value: "inventory_count",
        onClick: (item) => {
          callback("inventory_count");
        },
      },
      {
        label: "Last Purchase Cost",
        value: "last_purchase_cost",
        onClick: (item) => {
          callback("last_purchase_cost");
        },
      },
      {
        label: "Purchase Price",
        value: "purchase_price",
        onClick: (item) => {
          callback("purchase_price");
        },
      },
      {
        label: "Cost",
        value: "cost",
        onClick: (item) => {
          callback("cost");
        },
      },
      {
        label: "Total Ordered Count",
        value: "order_count",
        onClick: (item) => {
          callback("order_count");
        },
      },
      {
        label: "Resupply Count",
        value: "restocking_qty",
        onClick: (item) => {
          callback("restocking_qty");
        },
      },
    ],
  };
};

export default sortView;
