// urlQueryBuilder.js

const urlQueryBuilder = (updatedFilters, base, searchType, reset) => {
  const urlArray = base ? [base] : [];

  // Destructure updatedFilters
  const { includes, filters, sort, search, limit, page, tab } = updatedFilters;

  // Handle search parameters
  if (searchType === "term")
    urlArray.push(`term=${encodeURIComponent(search || "")}`);
  if (searchType === "search")
    urlArray.push(`search=${encodeURIComponent(search || "")}`);
  if (searchType === "filter")
    urlArray.push(`filter[name]=${encodeURIComponent(search || "")}`);

  if (includes) {
    urlArray.push(`include=${includes}`);
  }

  // Handle filters
  if (filters) {
    Object.keys(filters).forEach((key) => {
      if (hasValue(filters[key])) {
        if (Array.isArray(filters[key])) {
          // Join array values with a delimiter (e.g., comma)
          const joinedValues = filters[key]
            .map((value) => encodeURIComponent(value))
            .join(",");
          urlArray.push(`filter[${key}]=${joinedValues}`);
        } else {
          urlArray.push(`filter[${key}]=${encodeURIComponent(filters[key])}`);
        }
      }
    });
  }

  if (sort) {
    urlArray.push(`sort=${sort}`);
  }

  if (limit) {
    urlArray.push(`limit=${limit}`);
  }
  
  if (tab) {
    urlArray.push(`tab=${tab}`);
  }

  const backendQueryArray = [...urlArray];
  if (page) {
    backendQueryArray.push(`page=${page}`);
  }

  // Get existing URL parameters to preserve them
  const currentUrl = new URL(window.location.href);
  const currentSearchParams = new URLSearchParams(currentUrl.search);

  // Build new URLSearchParams object
  const newSearchParams = new URLSearchParams();

  // Copy existing parameters that are not being updated
  currentSearchParams.forEach((value, key) => {
    const isUpdatingKey =
      key === "search" ||
      key === "sort" ||
      key === "limit" ||
      key === "tab" ||
      key === "include" ||
      key.startsWith("filter[") ||
      urlArray.some((param) => param.startsWith(`${key}=`));

    if (!isUpdatingKey) {
      newSearchParams.append(key, value);
    }
  });

  // Add updated parameters
  urlArray.forEach((param) => {
    const [key, value] = param.split("=");
    newSearchParams.set(key, value);
  });

  // Build the new search string
  const newQueryString = newSearchParams.toString();
  let returnUrl = newQueryString ? `?${newQueryString}` : "";

  // Handle special case for search
  if (search && searchType !== "term" && searchType !== "filter") {
    returnUrl = `/search${returnUrl}`;
  }

  // Update the URL only if it has changed and not resetting
  const newUrl = `${currentUrl.origin}${currentUrl.pathname}${returnUrl}${currentUrl.hash}`;
  if (newUrl !== window.location.href) {
    console.log(newUrl);
    window.history.pushState({}, "", newUrl);
  }

  const backendQueryString = backendQueryArray.join("&");
  return backendQueryString
    ? `?${backendQueryString
        .replace(/&?boundFilters=[^&]*/g, "")
        .replace(/&{2,}/g, "&")
        .replace(/\?&/, "?")}`
    : "";
};

function hasValue(value) {
  return value !== undefined && value !== null && value !== "";
}

export default urlQueryBuilder;
