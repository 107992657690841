// ------------------------------
// Good Acceptance
// ------------------------------

// Import main libraries
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

// Import dynamic page libraries
import getActions from "../dynamic-page/util/getActions";
import TabButton from "../dynamic-page/elements/TabButton";
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";
import urlQueryLoader from "../dynamic-page/util/urlQueryLoader";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import FooterPagination from "../dynamic-page/elements/FooterPagination";
import util from "../dynamic-page/util";
import DynamicTable from "../dynamic-page";
// DEBT
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";
import SearchInput from "./../dynamic-page/debt/searchInput";
import ConfirmModal from "../dynamic-page/util/confirmModal";

// Import model-view libraries
import filtersView from "./models-view/filters";
import sortView from "./models-view/sort";
import tableView from "./models-view/table";

// Import redux actions
import {
  deleteGoodsAcceptance,
  fetchGoodsAcceptance as fetchData,
  fetchGoodsAcceptanceFilters as fetchFilters,
  restoreGoodsAcceptance,
  updateBoundFilter,
  updateFilters as updateFilter,
  updateGoodsAcceptance,
} from "../../store/goods-acceptance";

import formView from "./models-view/form";
import FormModal from "../dynamic-page/FormModal";
import { createMappedFilters } from "../dynamic-page/util/pageControl";
import useLoadMoreData from "../../utils/useLoadMoreData";
import PropTypes from "prop-types";

// Main Component
const GoodAceptances = ({ product_id }) => {
  // Define hooks.
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const termParam = searchParams.get("term");
  const [searchTerm, setSearchTerm] = useState(termParam || "");

  // First page load.
  useEffect(() => {
    const startPageByFilters = {
      search: "",
      sort: "-created_at",
      page: 1,
      filters: { status: "", ...(product_id && { product_id: product_id }) },
      limit: 10,
      includes: [
        "product.brand.assigned,warehouse,supplier,purchase,purchase.currency,purchase.supplyRequest",
      ],
    };
    const storeQuery = storeQueryBuilder(
      startPageByFilters,
      urlQueryLoader(startPageByFilters)
    );
    const urlQuery = urlQueryBuilder(
      storeQuery,
      "type=goods_acceptances",
      "term",
      true
    );

    const mappedFilters = createMappedFilters(
      storeQuery,
      pageData?.boundFilters
    );
    dispatch(updateBoundFilter(mappedFilters));
    dispatch(updateFilter(storeQuery));
    dispatch(fetchData(urlQuery));
    dispatch(fetchFilters(urlQuery));
  }, [location]);

  // Get data from store.
  const pageData = useSelector((state) => state.goodsAcceptance);
  const tableData = useSelector(
    (state) => state.goodsAcceptance.goodsAcceptance
  );

  const { loading, loadMoreData } = useLoadMoreData({
    dispatch,
    pageData,
    fetchData,
  });

  // Handle selected row in table.
  const [selectedRow, setSelectedRow] = useState(null);

  // Handle form modal.
  const [calledItemInAction, setCalledItemInAction] = useState(null);

  // Handle modal.
  const [modalVisibility, setModalVisibility] = useState(null);
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(false);
  const [formData, setFormData] = useState({});

  const onHandleSave = (payload) => {
    if (!payload?.delivery_date) {
      delete payload?.delivery_date;
    } else {
      payload.delivery_date = moment(payload?.delivery_date).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    }
    if (!payload?.expiry_date) {
      delete payload?.expiry_date;
    } else {
      payload.expiry_date = moment(payload?.expiry_date).format("YYYY-MM-DD");
    }
    dispatch(
      updateGoodsAcceptance({
        ...payload,
        // delivery_date: moment(payload?.delivery_date).format('YYYY-MM-DD HH:mm:ss'),
        // expiry_date: moment(payload?.expiry_date).format('YYYY-MM-DD')
      })
    ).then((res) => {
      util.notifier(res.payload?.data);
      if (res.payload?.data?.type === "success") {
        setModalVisibility(null);
        onPaginationApply();
      }
    });
  };

  const giveSelectedItemId = (item) => {
    const orderId = item ? [item.id] : selectedRow.map((item) => item.id);
    setSelectedRow([]);
    return orderId;
  };

  const actionList = {
    edit: {
      label: "Edit",
      bulkDisable: true,
      onClick: (item) => {
        setModalVisibility("edit");
        setFormData(item);
      },
      condition: (item) => {
        return item?.is_deleted === false;
      },
    },
    delete: {
      label: "Delete",
      bulkDisable: true,
      onClick: (item) => {
        setCalledItemInAction(item?.id);
        setConfirmationModalVisibility(true);
      },
      condition: (item) => {
        return item?.is_deleted === false;
      },
    },

    restore: {
      label: "Restore",
      onClick: (item) => {
        dispatch(restoreGoodsAcceptance({ ids: giveSelectedItemId(item) }))
          .unwrap()
          .then((res) => {
            util.notifier(res.data);
          })
          .catch((err) => {
            // toast.error(err?.message)
            toast.error(err?.message || "Something went wrong");
          });
      },
      condition: (item) => {
        return item?.is_deleted === true;
      },
    },
  };

  // handle actions of statuses
  const actions = (row) => {
    return getActions(row, actionList);
  };

  // Initial sort list
  const sortList = sortView({
    callback: (value) => {
      onSortApply(value);
    },
  });

  // Handle Tabs
  const onHandleTab = (item) => {
    // onFilterApply({status: item})
    switch (item) {
      case "awaiting_supply":
        navigate(`/inventory/supply_requests?term=${searchTerm}`);
        break;
      case "awaiting_payment":
        navigate(`/inventory/supply_purchases?term=${searchTerm}`);
        break;
      case "awaiting_delivery":
        navigate(`/inventory/goods_acceptance?term=${searchTerm}`);
        break;
    }
  };

  const onHandleLimit = (limit) => {
    onPaginationApply(1, limit);
  };

  // Apply filter sate to redux store and fetch data.
  const onSearchApply = (search) => {
    setSelectedRow([]);
    setSearchTerm(search);

    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      search: search,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onFilterApply = (filters, bound) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    dispatch(updateBoundFilter({ filters, bound }));
    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const handleTableFilters = (filters, bound) => {
    onFilterApply(filters, bound);
  };

  const onSortApply = (sort) => {
    const currentStoreFilters = pageData?.filters;
    let changeSort;

    if (sort === currentStoreFilters?.sort) {
      changeSort = null;
    } else if (currentStoreFilters?.sort === `-${sort}`) {
      changeSort = sort;
    } else {
      changeSort = `-${sort}`;
    }

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      sort: changeSort,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onPaginationApply = async (page, limit) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      limit: limit || currentStoreFilters.limit || 10,
    });

    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    await dispatch(updateFilter(storeQuery), currentStoreFilters);
    await dispatch(fetchFilters(urlQuery));
    await dispatch(fetchData(urlQuery));
  };

  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };

  const exportMapper = (data) => {
    return data?.length
      ? data?.map((item) => {
          let productType = "-";

          if (item?.product?.type === 1) {
            productType = "Single";
          } else if (item?.product?.type) {
            productType = "Grouped";
          }
          return {
            "Acceptance Id": item?.id || "-",
            "Accepted At":
              moment(item?.accepted_at)?.format("YYYY-MM-DD HH:mm:ss") || "-",
            Quantity: item?.quantity || "-",
            "Acceptance Note": item?.note || "-",
            "Product APID": item?.product?.apid || "-",
            "Product Name": item?.product?.title || "-",
            "Product Assign": item?.product?.brand?.assigned?.name || "-",
            "Product Brand": item?.product?.brand?.name || "-",
            "Product Type": productType,
            "Product Image Url": item?.product?.image || "-",
            "Warehouse Name": item?.warehouse?.name || "-",
            "Supplier Name": item?.supplier?.name || "-",
            "Supply Request Id": item?.purchase?.supply_request?.id || "-",
            "Supply Requested At":
              moment(item?.purchase?.supply_request?.request_date_time)?.format(
                "YYYY-MM-DD HH:mm:ss"
              ) || "-",
            "Supply Requested Max Acc. Price":
              item?.purchase?.supply_request?.max_acceptable_price?.[
                item?.purchase?.supply_request?.max_acceptable_price_currency
                  ?.code
              ] || "-",
            "Supply Requested Reason":
              item?.purchase?.supply_request?.reason || "-",
            "Supply Requested Qty": item?.purchase?.supply_request?.qty || "-",
            "Supply Requested Comment":
              item?.purchase?.supply_request?.comment || "-",
            "Supply Purchase Id": item?.purchase?.id || "-",
            "Purchased At":
              moment(item?.purchase?.created_at)?.format(
                "YYYY-MM-DD HH:mm:ss"
              ) || "-",
            "Supply Purchase Currency": item?.purchase?.currency?.code || "-",
            "Supply Purchased Price":
              item?.purchase?.purchase_price?.[
                item?.purchase?.currency?.code
              ] || "-",
          };
        })
      : [];
  };

  const headers = [
    "Acceptance Id",
    "Accepted At",
    "Quantity",
    "Acceptance Note",
    "Product APID",
    "Product Name",
    "Product Assign",
    "Product Brand",
    "Product Type",
    "Product Image Url",
    "Warehouse Name",
    "Supplier Name",
    "Supply Request Id",
    "Supply Requested At",
    "Supply Requested Maxap",
    "Supply Requested Reason",
    "Supply Requested Qty",
    "Supply Requested Comment",
    "Supply Purchase Id",
    "Purchased At",
    "Supply Purchase Currency",
    "Supply Purchased Price",
  ];

  const filterCount = Object.values(pageData?.filters?.filters ?? {}).filter(
    (item) => item !== null && item !== ""
  ).length;

  // Determine if current_status_id is defined and not null

  const miniWidth = filterCount === 0 ? "200" : "238";

  return (
    <>
      <ArbitProductPage.Page className="flex flex-col">
        {!product_id && (
          <>
            <ArbitProductPage.Title
              title={"Supply"}
              allowExport={true}
              exported={{
                exportData: selectedRow?.length > 0 ? selectedRow : tableData,
                exportMapper: exportMapper,
                headers: headers,
              }}
              loading={pageData.loading}
              filter={{
                filters: pageData?.boundFilters,
                pageFilters: pageData?.filters?.filters,
                items: filtersView(pageData?.filters?.filters),
                callback: (rs, bound) => {
                  onFilterApply(rs, bound);
                },
                count: Object.values(pageData?.filters?.filters ?? {}).filter(
                  (item) => item != null && item !== ""
                ).length,
              }}
              sort={{
                items: sortList,
                selected: pageData?.filters?.sort,
              }}
              form={{
                callback: (rs) => {
                  window.open("/inventory/goods_acceptance/new", "_blank");
                },
              }}
              actionList={actions(selectedRow)}
            />

            <div
              className={
                "flex flex-row justify-between px-[16px] py-[4px] items-center"
              }
            >
              <SearchInput
                className="w-[362px]"
                placeholder="Search..."
                onSubmit={onSearchApply}
                defaultValue={pageData?.filters?.search}
              />

              <TabButton
                className="whitespace-nowrap"
                selectedClassName="whitespace-nowrap"
                callBack={onHandleTab}
                active="awaiting_delivery"
                list={[
                  {
                    id: "awaiting_supply",
                    name: "Awaiting Purchase",
                  },
                  {
                    id: "awaiting_payment",
                    name: "Awaiting Acceptance",
                  },
                  {
                    id: "awaiting_delivery",
                    name: "Goods Acceptances",
                  },
                ]}
              />
            </div>
          </>
        )}

        <ArbitProductPage.Content className="content">
          {!product_id && (
            <style>
              {!product_id
                ? `
                             @media (768px <= width <= 1000px) {
                                .content .contt {
                                    height: calc(100vh - 220px) !important;
                                }
                            }
                            @media (max-width: 767px) {
                                .content .contt {
                                    height: calc(100vh - 314px) !important;
                                }
                            }
                    `
                : `
                    height: 200px !important;
                    `}
            </style>
          )}
          <DynamicTable
            view={tableView}
            mini={true}
            miniWidth={miniWidth}
            hiddenFields={product_id ? ["product", "image"] : []}
            data={tableView(tableData, handleTableFilters)}
            actions={actions}
            onSelect={onSelectRow}
            selectedRow={selectedRow}
            meta={pageData?.meta}
            appending={loading}
            loadMoreData={loadMoreData}
            loading={pageData.loading}
            style={{
              table: {
                style: { width: "100%", minWidth: "100%" },
              },
              header: {
                className: "justify-start px-[16px]",
                id: { className: "hidden" },
                note: { className: "hidden" },
                image: { className: "hidden" },
                status: { className: "hidden" },
                product: { className: "!flex !justify-center !mr-[90px]" },
                "exp.date": { className: "!pr-[36px] !flex !justify-start" },
                qty: { className: " !flex !justify-center" },
              },
              row: {
                className:
                  " !px-[16px] !py-[0px] !h-[64px] text-[14px] !items-center justify-center !min-w-fit whitespace-nowrap flex ",
                image: {
                  className: "!py-[0px] w-[81px] justify-center",
                  td: "!py-[0px] w-[65px]",
                },
                _actions: { className: "!py-[0px]" },
                note: { className: "!py-[10px] !px-[8px]" },
                id: { className: "!pr-[4px]" },
                status: { className: "!pr-[4px]" },
                "accepted at": { className: " !px-[8px]" },
                product: {
                  className: `!justify-center w-[100%] !pl-[0px] !min-w-auto`,
                  td: `!justify-center w-[100%]`,
                },
                "supply channel": {
                  td: product_id ? "w-[50%]" : "",
                },
                warehouse: {
                  // className: " !justify-start",
                  td: product_id ? "w-[50%]" : "",
                },
                "exp.date": { className: "!pr-[36px] !pl-[12px]" },
              },
            }}
          />
        </ArbitProductPage.Content>

        {!product_id && (
          <FooterPagination
            meta={{ ...pageData?.meta, limit: pageData?.filters?.limit }}
            pageData={pageData}
            loading={pageData.loading}
            onLimit={onHandleLimit}
          />
        )}
      </ArbitProductPage.Page>
      {modalVisibility === "edit" && (
        <FormModal
          title={"Accept Goods"}
          formView={formView}
          visibility={modalVisibility === "edit"}
          data={formData}
          onClose={() => {
            setModalVisibility(null);
          }}
          onSubmit={(data) => {
            onHandleSave(data);
          }}
        />
      )}

      {confirmationModalVisibility && (
        <ConfirmModal
          show={confirmationModalVisibility}
          infoText="Are you sure you want to delete the goods acceptance?         (Remember, this action will only delete the goods acceptance and the supply will return to the purchased tab.)"
          onClose={() => setConfirmationModalVisibility(false)}
          confirmText="Yes, Delete Acceptance"
          confirmColor="red"
          cancelText="Cancel"
          cancelColor="blue"
          styles={{
            confirmButton: "!bg-red-50 ",
            cancelButton: "!bg-white ",
            infoText: "!font-medium",
            size: "lg",
          }}
          onConfirm={(setSubmit) => {
            dispatch(deleteGoodsAcceptance(calledItemInAction))
              .unwrap()
              .then((res) => {
                setSubmit(false);
                util.notifier(res.data);
                if (res.data?.type === "success") {
                  setConfirmationModalVisibility(false);
                  onPaginationApply();
                }
              })
              .catch((err) => {
                util.notifier({ ...err?.message, type: "error" });
              });
          }}
        />
      )}

      {/*<AddAceptanceModal
                show={modalVisibility === "edit"}
                setVisible={setModalVisibility}
                onAdd={onAdd}
                onUpdate={onUpdate}
                onAddFromProduct={onAddFromProduct}
                onUpdateFromProduct={onUpdateFromProduct}
                supplies={supPurchasesData}
                products={productsData}
                data={updateItem}
                type={updateType}
                optionsAccounts={optionsAccounts}
                onClose={() => setVisibleAddAceptanceModal(false)}
            />*/}
    </>
  );
};
GoodAceptances.propTypes = {
  product_id: PropTypes.string.isRequired, // Ensures that product_id is a string and required
};

export default GoodAceptances;
