import FormModal from "../../dynamic-page/FormModal";
import callEndpoint from "../../dynamic-page/util/callEndpoint";
import FormView from "./assignModal";

const actionList = {
  add: {
    label: "Add Brand",
    onClick: ({ forms, data }) => {
      forms?.["add"].open(data);
    },
    condition: false,
  },

  edit: {
    label: "Edit",
    onClick: ({ forms, actions, data }) => {
      forms?.["add"].open(data);
    },
    bulkDisable: true,
  },

  delete: {
    label: "Delete",
    onClick: ({
      data,
      refreshPage,
      setConfirmationModalVisibility,
      removeRow,
      setSelectedRow,
    }) => {
      setConfirmationModalVisibility({
        infoText: "Are you sure you want to delete the selected brand/s?",
        confirmText: "Yes, Delete",
        callBack: (setSubmit) => {
          const ids = data?.id ? [data?.id] : data?.map((item) => item?.id);
          callEndpoint({
            title: "Delete Brands",
            method: "delete",
            url: `brands`,
            data: {
              ids: ids,
            },
            pureData: true,
          }).then((res) => {
            setSubmit(false);
            if (res?.type === "success") {
              setSelectedRow([]);
              setConfirmationModalVisibility(null);
              refreshPage();
            }
          });
        },
      });
    },
  },

  unassign: {
    label: "Unassign",
    onClick: ({
      data,
      refreshPage,
      replaceRow,
      setConfirmationModalVisibility,
      setSelectedRow,
    }) => {
      setConfirmationModalVisibility({
        infoText: "Are you sure you want to unassign the selected brand/s?",
        confirmText: "Yes, Unassign",
        callBack: (setSubmit) => {
          const ids = data?.id ? [data?.id] : data?.map((item) => item?.id);
          callEndpoint({
            title: "Unassign Brands",
            method: "post",
            url: `brands/bulk-unassign`,
            data: {
              brand_ids: ids,
            },
            pureData: true,
          }).then((res) => {
            setSubmit(false);
            if (res?.type === "success") {
              setSelectedRow([]);
              setConfirmationModalVisibility(null);
              replaceRow(ids, res?.data);
            }
          });
        },
      });
    },
    condition: (item) => item?.assigned,
  },

  assign: {
    label: "Assign Employees",
    onClick: ({
      forms,
      data,
      setVisibleExternalModal,
      extra,
      replaceRow,
      refreshPage,
      setSelectedRow,
    }) => {
      if (data?.assigned_user_id) {
        callEndpoint({
          title: "Assign Employees",
          method: "post",
          url: `brands/bulk-assign`,
          data: data,
          pureData: true,
        }).then((response) => {
          if (response?.type === "success") {
            replaceRow(data?.brand_ids[0], response?.data[0]);
          }
        });
        return;
      }
      const ids = data?.id ? [data?.id] : data?.map((item) => item?.id);
      const onSubmitApply = async (data, close) => {
        callEndpoint({
          title: "Assign Employees",
          method: "post",
          url: `brands/bulk-assign`,
          data: { brand_ids: ids, assigned_user_id: data?.assigned_user_id },
          pureData: true,
        }).then((response) => {
          if (response?.type === "success") {
            close();
            setSelectedRow([]);

            replaceRow(ids, response?.data);
          }
        });
      };
      forms?.["assign"].open(
        data,
        <FormModal
          prefix="Assign"
          title={" Employees"}
          containerClassName="!px-5 !pb-5"
          formView={FormView}
          data={[]}
          onClose={() => {
            setVisibleExternalModal(null);
          }}
          onSubmit={onSubmitApply}
          container={(child) => (
            <div
              className={`max-w-[650px] grid grid-cols-2 items-start gap-y-0.5 gap-x-1 w-full justify-center  border-t border-t-gray-200 !pt-[4px]`}
            >
              {child}
            </div>
          )}
        />
      );
    },
    // condition:
  },

  save: {
    label: "Save",
    onClick: ({ data, refreshPage, close, callBack, clear, replaceRow }) => {
      callEndpoint({
        title: "Save Brand",
        method: data?.id ? "put" : "post",
        url: data?.id ? `brands/${data?.id}` : `brands`,
        data: data,
        pureData: true,
      }).then((response) => {
        callBack();
        if (response?.type === "success") {
          close();
          clear();
          refreshPage();
        }
      });
    },
    condition: false,
  },
};

export default actionList;
