import { createSlice } from "@reduxjs/toolkit";

import {
  createGoodsAcceptance,
  createGoodsAcceptanceFromProduct,
  deleteGoodsAcceptance,
  fetchGoodsAcceptance,
  fetchGoodsAcceptanceFilters,
  restoreGoodsAcceptance,
  updateGoodsAcceptance,
  updateGoodsAcceptanceFromProduct,
} from "./thunk";
import { updateBoundFilters } from "../updateBoundFilters";

const initialState = {
  goodsAcceptance: [],
  links: {},
  loading: "idle",
  error: null,
  filters: {},
  meta: {},
  filtersMeta: {},
};

export const goodsAcceptanceSlice = createSlice({
  name: "goodsAcceptance",
  initialState,
  reducers: {
    updateBoundFilter: updateBoundFilters,
    updateFilters: (state, action) => {
      state.filters = { ...action.payload };
    },
    removeGoodsAcceptance: (state, action) => {
      const ids = action.payload;
      state.goodsAcceptance = state.goodsAcceptance.filter(
        (curr) => !ids.includes(curr.id)
      );
    },
    fetchLoading: (state, action) => {
      state.loading = "pending";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGoodsAcceptance.rejected, (state, action) => {
        if (action.error?.name !== "CanceledError") {
          state.loading = "idle";
          state.error = action.error;
        }
      })
      .addCase(fetchGoodsAcceptance.fulfilled, (state, action) => {
        state.loading = "idle";
        state.goodsAcceptance = action.payload?.append
          ? [...state.goodsAcceptance, ...action.payload.data]
          : action.payload.data;
        state.links = action.payload?.links;
        state.meta = action.payload?.meta;
      })
      .addCase(fetchGoodsAcceptanceFilters.fulfilled, (state, action) => {
        //state.loading = "idle";
        state.filtersMeta = action.payload;
      });
  },
});

export const {
  updateFilters,
  removeGoodsAcceptance,
  updateBoundFilter,
  fetchLoading,
} = goodsAcceptanceSlice.actions;

export {
  fetchGoodsAcceptance,
  createGoodsAcceptance,
  createGoodsAcceptanceFromProduct,
  updateGoodsAcceptanceFromProduct,
  updateGoodsAcceptance,
  deleteGoodsAcceptance,
  restoreGoodsAcceptance,
  fetchGoodsAcceptanceFilters,
};

export default goodsAcceptanceSlice.reducer;
