import callEndpoint from "../../dynamic-page/util/callEndpoint";

const actionList = {
  add: {
    label: "Add Brand",
    onClick: ({ forms, data }) => {
      forms?.["add"].open(data);
    },
    condition: false,
  },

  edit: {
    label: "Edit",
    onClick: ({ forms, actions, data }) => {
      forms?.["add"].open(data);
    },
    bulkDisable: true,
    condition: (data) => {
      return data?.is_protected !== 1;
    },
  },

  activate: {
    label: "Activate",
    onClick: ({
      data,
      refreshPage,
      setConfirmationModalVisibility,
      replaceRow,
    }) => {
      setConfirmationModalVisibility({
        infoText: "Are you sure you want to activate the selected attribute/s?",
        confirmText: "Yes, Activate",
        callBack: (setSubmit) => {
          const ids = data?.id ? [data?.id] : data?.map((item) => item?.id);
          callEndpoint({
            title: "Activate attributes",
            method: "put",
            url: `attribute/toggle-status`,
            data: {
              ids: ids,
            },
            pureData: true,
          }).then((res) => {
            setSubmit(false);
            if (res?.type === "success") {
              setConfirmationModalVisibility(null);
              if (ids?.length === 1) {
                replaceRow(data?.id, { ...data, status: 1 });
              } else {
                refreshPage();
              }
            }
          });
        },
      });
    },
    condition: (data) => {
      return data?.status === 0 && data?.is_protected !== 1;
    },
  },

  deactivate: {
    label: "Deactivate",
    onClick: ({
      data,
      refreshPage,
      setConfirmationModalVisibility,
      replaceRow,
    }) => {
      setConfirmationModalVisibility({
        infoText:
          "Are you sure you want to deactivate the selected attribute/s?",
        confirmText: "Yes, Deactivate",
        callBack: (setSubmit) => {
          const ids = data?.id ? [data?.id] : data?.map((item) => item?.id);
          callEndpoint({
            title: "Deactivate attributes",
            method: "put",
            url: `attribute/toggle-status`,
            data: {
              ids: ids,
            },
            pureData: true,
          }).then((res) => {
            setSubmit(false);
            if (res?.type === "success") {
              setConfirmationModalVisibility(null);
              if (ids?.length === 1) {
                replaceRow(data?.id, { ...data, status: 0 });
              } else {
                refreshPage();
              }
            }
          });
        },
      });
    },
    condition: (data) => {
      return data?.status === 1 && data?.is_protected !== 1;
    },
  },

  delete: {
    label: "Delete",
    onClick: ({
      data,
      filters,
      setConfirmationModalVisibility,
      setSelectedRow,
      removeRow,
      replaceRow,
    }) => {
      setConfirmationModalVisibility({
        infoText: "Are you sure you want to delete the selected attribute/s?",
        confirmText: "Yes, Delete",
        callBack: (setSubmit) => {
          const ids = data?.id ? [data?.id] : data?.map((item) => item?.id);
          callEndpoint({
            title: "Delete attributes",
            method: "delete",
            url: `attribute/` + ids[0],
            data: {
              attributes: ids,
            },
            pureData: true,
          }).then((res) => {
            setSubmit(false);
            if (res?.type === "success") {
              setSelectedRow([]);
              setConfirmationModalVisibility(null);
              // console.log(filters.status);
              if (filters?.status) removeRow(ids);
              else {
                const mapped = Array.isArray(data) ? data[0] : data;
                replaceRow(ids[0], { ...mapped, status: 2 });
              }
            }
          });
        },
      });
    },
    condition: (data) => {
      return data?.status !== 2 && data?.is_protected !== 1;
    },
    bulkDisable: true,
  },
  updateClass: {
    label: "",
    onClick: ({
      forms,
      data,
      setVisibleExternalModal,
      extra,
      replaceRow,
      refreshPage,
      ...rest
    }) => {
      callEndpoint({
        title: "Updating Attribute Class",
        method: "put",
        url: "attribute/change-group/" + data?.attributeId,
        data: { group_id: data?.group_id },
        pureData: true,
      }).then((response) => {
        if (response?.type === "success") {
          refreshPage();
          // replaceRow(data?., response?.data[0]);
        }
      });
    },
    condition: false,
  },

  updateIsMultiple: {
    label: "",
    onClick: ({
      forms,
      data,
      setVisibleExternalModal,
      extra,
      replaceRow,
      refreshPage,
      ...rest
    }) => {
      callEndpoint({
        title: "Updating Attribute",
        method: "put",
        url: "attribute/toggle-multiple/" + data?.id,
        pureData: true,
      }).then((response) => {
        if (response?.type === "success") {
          // refreshPage();
          replaceRow(data?.id, {
            ...data,
            is_multiple: data?.is_multiple === 1 ? 0 : 1,
          });
        }
      });
    },
    condition: false,
  },

  updateIsMultilingual: {
    label: "",
    onClick: ({
      forms,
      data,
      setVisibleExternalModal,
      extra,
      replaceRow,
      refreshPage,
      ...rest
    }) => {
      callEndpoint({
        title: "Updating Attribute",
        method: "put",
        url: "attribute/toggle-multilingual/" + data?.id,
        pureData: true,
      }).then((response) => {
        if (response?.type === "success") {
          // refreshPage();
          replaceRow(data?.id, {
            ...data,
            is_multilingual: data?.is_multilingual === 1 ? 0 : 1,
          });
        }
      });
    },
    condition: false,
  },

  save: {
    label: "Save",
    onClick: ({ data, refreshPage, close, callBack, clear, replaceRow }) => {
      let payload = data;
      let validatoin_rules = {};

      if (data?.maxValue) {
        validatoin_rules = {
          ...validatoin_rules,
          maxValue: data?.maxValue,
        };
      }
      if (data?.minValue) {
        validatoin_rules = {
          ...validatoin_rules,
          minValue: data?.minValue,
        };
      }
      if (data?.minCount) {
        validatoin_rules = {
          ...validatoin_rules,
          minCount: data?.minCount,
        };
      }
      if (data?.maxCount) {
        validatoin_rules = {
          ...validatoin_rules,
          maxCount: data?.maxCount,
        };
      }
      if (data?.possibleOptions) {
        validatoin_rules = {
          enum: data?.possibleOptions?.split(",").map((item) => item.trim()),
        };
      }
      // if non of the above then the validation rules will be the type of the attribute as a key and value (same) also all letters in lower case
      if (
        !data?.maxCount &&
        !data?.minCount &&
        !data?.minValue &&
        !data?.maxValue &&
        !data?.possibleOptions
      ) {
        validatoin_rules = {
          [data?.type.toLowerCase()]: data?.type.toLowerCase(),
        };
      }

      if (validatoin_rules) {
        payload = {
          ...payload,
          validation_rules: validatoin_rules,
        };
      }

      callEndpoint({
        title: "Save Attribute",
        method: data?.id ? "put" : "post",
        url: data?.id ? `attribute/${data?.id}` : `attribute`,
        data: payload,
        pureData: true,
      }).then((response) => {
        callBack();
        if (response?.type === "success") {
          close();
          clear();
          refreshPage();
        }
      });
    },
    condition: false,
  },
};

export default actionList;
