import { createSlice } from "@reduxjs/toolkit";

import {
  addNote,
  connectProduct,
  deleteNote,
  disconnectProduct,
  fetchEmployees,
  fetchNotes,
  fetchOffersFilters,
  fetchSalesListings,
  fetchSalesListingsFilter,
  updateSalesListingFulfilment,
} from "./thunk";
import updateObjectByPath from "../../components/dynamic-form/util/updateState";
import { updateBoundFilters } from "../updateBoundFilters";

const initialState = {
  salesListings: [],
  links: {},
  identifierFilters: [],
  filters: {
    main_identifier_filter: null,
    created_at_filter: null,
    updated_at_filter: null,
    identifier_type_filter: null,
    brand_filter: [],
    channel_filter: [],
    assigned_filter: [],
    review_count_filter: [],
    rating_filter: [],
    variant_count_filter: [],
    buybox_owner_filter: [],
    buybox_lowest_price: [],
    category_filter: [],
    category_degree_filter: [],
    sales_rank_filter: [],
    bb_oos_90_filter: [],
    sales_channel_top_filter: [],
    fba_offers_count_filter: [],
    fbm_offers_count_filter: [],
    velocitied_daily_sales_filter: [],
    velocitied_monthly_sales_filter: [],
    velocitied_daily_sales_value_filter: [],
    velocitied_monthly_sales_value_filter: [],
    buybox_price_filter: [],
    fba_price_filter: [],
    fbm_sellable_filter: [],
    fba_sellable_filter: [],
    fba_inbound_filter: [],
    fba_reserved_qty_filter: [],
    fba_60_days_restock_limit_filter: [],
    main_identifier_filter: "",
    fba_margin_filter: [],
    fbm_margin_filter: [],
    fba_profit_filter: [],
    fbm_profit_filter: [],
    fbm_price_filter: [],
    stock_left_filter: [],
  },
  boundFilters: {},
  meta: {},
  filtersMeta: {},
  boundFilters: {},
  employees: [],
  loading: "idle",
  error: null,
  filterLoading: "idle",
  isLogin: false,
};

export const salesListingsSlice = createSlice({
  name: "salesListings",
  initialState,
  reducers: {
    updateBoundFilter: updateBoundFilters,
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    },
    updateRow: (state, action) => {
      const updatedState = updateObjectByPath(
        state,
        action.payload.path,
        action.payload.value
      );
      return { ...state, ...updatedState };
    },
    fetchLoading: (state, action) => {
      state.loading = "pending";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSalesListings.fulfilled, (state, action) => {
        state.loading = "idle";
        state.salesListings = action.payload?.append
          ? [...state.salesListings, ...action.payload.data]
          : action.payload.data;
        state.links = action.payload["links"];
        state.meta = action.payload["meta"];
      })
      .addCase(fetchSalesListings.rejected, (state, action) => {
        if (state.loading === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      })
      .addCase(fetchSalesListingsFilter.fulfilled, (state, action) => {
        state.filtersMeta = action.payload?.data;
        state.filterLoading = "idle";
      })
      .addCase(fetchSalesListingsFilter.pending, (state, action) => {
        state.filterLoading = "pending";
      })
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        state.employees = action.payload?.data;
      })
      .addCase(fetchOffersFilters.fulfilled, (state, action) => {
        state.identifierFilters = action.payload?.data;
      });
  },
});

export const { updateFilter, updateRow, updateBoundFilter, fetchLoading } =
  salesListingsSlice.actions;

export {
  fetchSalesListings,
  updateSalesListingFulfilment,
  fetchSalesListingsFilter,
  fetchEmployees,
  connectProduct,
  disconnectProduct,
  fetchNotes,
  addNote,
  deleteNote,
  fetchOffersFilters,
};

export default salesListingsSlice.reducer;
