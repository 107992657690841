import upperCaseString from "../../dynamic-page/util/upperCaseString";
import { useSelector } from "react-redux";

const FiltersView = (selectedFilers, bound) => {
  const filtersMeta = useSelector((state) => state?.orders?.filtersMeta);
    console.log(selectedFilers, bound)
  return [
  
    {
      name: "order_status",
      label: "Order Status",
      placeholder: "Filter by order status",
      type: "Select",
      list: filtersMeta?.data?.data?.statuses?.map((item) => ({
        label: upperCaseString(item?.name),
        value: item?.id,
        count: item?.count,
      })),
      defaultValue: selectedFilers?.order_status,
      toggle: true,
      clear: true,
    },
    {
      name: "order_number",
      label: "Order Number",
      placeholder: "Filter by order number",
      type: "Input",
      toggle: true,
      isClearable: true,
      defaultValue: selectedFilers?.order_number,
    },
    {
      name: "sales_channel",
      label: "Sales Channel",
      placeholder: "Filter by sales channel",
      type: "Select",
      features: {
        isMulti: true,
      },
      api: {
        url: "/api/v1/channel?filter[designation]=TARGET",
        view: "/api/v1/channel",
        query: "&filter[name]={query}",
        optionValue: (item) => ({
          value: item.id,
          label: item.name,
        }),
      },
      defaultValue: selectedFilers?.sales_channel,
      toggle: true,
      clear: true,
      checkAll: true,
    },
    {
      name: "sales_account",
      label: "Seller Account",
      placeholder: "Filter by seller account name",
      type: "Select",
      api: {
        url: `/api/v1/accounts?filter[status]=active`,
        query: "&filter[name]={query}",
        view: "/api/v1/accounts",
        optionValue: (item) => ({
          value: item.id,
          label: item.name,
        }),
      },
      defaultValue: selectedFilers?.sales_account,
      toggle: true,
      clear: true,
      features: {
        isMulti: true,
      },
      checkAll: true,
    },
    {
      name: "sales_account_country",
      label: "Sales Account Country",
      placeholder: "Filter by sales account country",
      type: "Select",
      api: {
        url: "/api/v1/country",
        query: "?filter[name]={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: item?.name,
          flag: item?.flag,
        }),
      },
      features: {
        isMulti: true,
        formatOptionLabel: ({ label, flag }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span className="w-[25px] h-[15px] block overflow-hidden flex flex-col justify-center items-center">
              <img src={flag.toLowerCase()} />
            </span>
            <span>{upperCaseString(label)}</span>
          </div>
        ),
      },
      defaultValue: selectedFilers?.sales_account_country,
      toggle: true,
      clear: true,
    },
    {
      name: "items_count",
      label: "Items Count",
      placeholder: "Filter by items count",
      type: "Range",
      defaultValue: selectedFilers?.items_count,
      toggle: true,
      clear: true,
    },
    {
      name: "total_quantity",
      label: "Total Quantity",
      placeholder: "Filter by total quantity",
      type: "Range",
      defaultValue: selectedFilers?.total_quantity,
      toggle: true,
      clear: true,
    },
    {
      name: "item_title",
      label: "Item Title",
      placeholder: "Filter by item title",
      type: "Input",
      defaultValue: selectedFilers?.item_title ?  decodeURIComponent(selectedFilers?.item_title) : '',
      toggle: true,
      clear: true,
      encode: true,
    },
    {
      name: "item_sku",
      label: "Item SKU",
      placeholder: "Filter by item SKU",
      type: "Input",
      defaultValue: selectedFilers?.item_sku,
      toggle: true,
      clear: true,
    },
    {
      name: "billable_weight",
      label: "Billable Weight",
      placeholder: "Filter by billable weight",
      type: "Range",
      defaultValue: selectedFilers?.billable_weight,
      toggle: true,
      clear: true,
    },
    {
      name: "actual_weight",
      label: "Actual Weight",
      placeholder: "Filter by actual weight",
      type: "Range",
      defaultValue: selectedFilers?.actual_weight,
      toggle: true,
      clear: true,
    },
    {
      name: "dim_weight",
      label: "Dim Weight",
      placeholder: "Filter by dim weight",
      type: "Range",
      defaultValue: selectedFilers?.dim_weight,
      toggle: true,
      clear: true,
    },
    {
      name: "ship_to_name",
      label: "Ship To Name",
      placeholder: "Filter by ship to name",
      type: "Input",
      defaultValue: selectedFilers?.ship_to_name,
      toggle: true,
      clear: true,
    },
    {
      name: "ship_to_state",
      label: "State of Order",
      placeholder: "Filter by state of order",
      type: "Select",
      api: {
        url: "/api/v1/state",
        query: "?filter[name]={query}",
        view: "/api/v1/state",
        optionValue: (item) => ({
          value: item?.id,
          label: item?.name,
          country: item?.country?.name || "",
          flag: item?.country?.flag || "",
        }),
      },
      features: {
        isMulti: true,
        formatOptionLabel: ({ label, flag, country }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span className="min-w-[25px] h-[15px] block overflow-hidden flex flex-col justify-center items-center">
              <img
                src={flag.toLowerCase() || "/assets/images/placeholder.png"}
              />
            </span>
            <span>
              {upperCaseString(label)}, {upperCaseString(country)}
            </span>
          </div>
        ),
      },
      defaultValue: selectedFilers?.ship_to_state,
      toggle: true,
      clear: true,
    },
    {
      name: "ship_to_country",
      label: "Country of Order",
      placeholder: "Filter by country of order",
      disablePreload: true,
      type: "Select",
      api: {
        url: "/api/v1/country",
        query: "?filter[name]={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: item?.name,
          flag: item?.flag,
        }),
      },
      features: {
        isMulti: true,
        formatOptionLabel: ({ label, flag }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span className="w-[25px] h-[15px] block overflow-hidden flex flex-col justify-center items-center">
              <img src={flag.toLowerCase()} />
            </span>
            <span>{upperCaseString(label)}</span>
          </div>
        ),
      },
      defaultValue: selectedFilers?.ship_to_country,
      toggle: true,
      clear: true,
    },
    {
      name: "order_date",
      label: "Order Date",
      placeholder: "Filter by order date",
      type: "DateRange",
      defaultValue: selectedFilers?.order_date,
      toggle: true,
      clear: true,
    },
    {
      name: "order_age",
      label: "Order Age",
      placeholder: "Filter by order age",
      type: "Range",
      defaultValue: selectedFilers?.order_age,
      toggle: true,
      clear: true,
    },
    {
      name: "shipment_label_tracking_number",
      label: "Shipment Tracking Number",
      placeholder: "Filter by shipment tracking number",
      type: "Input",
      defaultValue: selectedFilers?.shipment_label_tracking_number,
      toggle: true,
      clear: true,
    },
    {
      name: "shipment_label_service_name",
      label: "Shipment Label Service Name",
      placeholder: "Filter by shipment label service name",
      type: "Input",
      defaultValue: selectedFilers?.shipment_label_service_name,
      toggle: true,
      clear: true,
    },
    {
      name: "customer_order_count",
      label: "Customer Order Count",
      placeholder: "Filter by customer order count",
      type: "Range",
      defaultValue: selectedFilers?.customer_order_count,
      toggle: true,
      clear: true,
    },
    {
      name: "assigned_user",
      label: "Assigned User",
      placeholder: "Filter by user",
      type: "Select",
      api: {
        url: "/api/v1/users",
        view: "/api/v1/employees",
        query: "?filter[name]={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: item?.name,
          avatar: item?.image_url || item?.profile_image,
        }),
      },
      features: {
        isMulti: true,
        formatOptionLabel: ({ label, avatar }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span className="block overflow-hidden flex flex-col justify-center items-center">
              <img
                src={avatar || "/assets/images/defaultAvatar.png"}
                className="w-5 h-5 rounded-full object-contain"
              />
            </span>
            <span>{upperCaseString(label)}</span>
          </div>
        ),
      },
      defaultValue: selectedFilers?.assigned_user,
      toggle: true,
      clear: true,
    },
    {
      name: "product",
      label: "Product",
      type: "Select",
      api: {
        url: "/api/v1/product",
        query: "?term={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: upperCaseString(item?.title, "word", true),
        }),
      },
      reset: true,
      clear: true,
      toggle: true,
      style: {
        control: {
          minHeight: "auto",
        },
        menu: {
          whiteSpace: "wrap !important",
        },
      },
      placeholder: "Filter by products",
      defaultValue: selectedFilers?.product,
    },
    {
      name: "shipment_label_state_id",
      label: "Shipment Label State",
      placeholder: "Filter by Shipment Label State",
      disablePreload: true,
      type: "Select",
      api: {
        url: "api/v1/state",
        query: "?filter[name]={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: item?.name,
        }),
      },
      features: {
        isMulti: true,
      },
      defaultValue: selectedFilers?.shipment_label_state_id,
      toggle: true,
      clear: true,
    },
    {
      name: "type",
      label: "Type",
      placeholder: "Filter by Type",
      type: "Checkbox",
      list: filtersMeta?.data?.data?.types?.map((item) => ({
        label: item?.name,
        value: item?.id,
        count: item?.count,
      })),
      defaultValue: selectedFilers?.type,
      toggle: true,
      clear: true,
      checkAll: true,
    },
    {
      name: "has_label",
      label: "Has Labels",
      placeholder: "Filter by Has Labels",
      type: "RadioButton",
      list: [
        {
          label: "Has Labels",
          value: "1",
        },
        {
          label: "No Label",
          value: "0",
        },
      ],
      defaultValue: selectedFilers?.has_label,
      toggle: true,
      radioContainerClass: "!flex-col",
    },
  ];
};

export default FiltersView;