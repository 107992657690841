import { createSlice } from "@reduxjs/toolkit";
import {
  CreateWarehouse,
  DeleteWarehouse,
  fetchWarehouseFilters,
  GetAllWarehouses,
  GetWarehouseById,
  UpdateWarehouse,
} from "./thunk";
import updateObjectByPath from "../../components/dynamic-form/util/updateState";
import { updateBoundFilters } from "../updateBoundFilters";

const initialState = {
  warehouses: [],
  selectedWarehouse: null,
  deleteId: null,
  loading: "idle",
  page: "list",
  status: null,
  error: null,
  filters: {},
  filtersMeta: {},
  filterLoading: "idle",
  boundFilters: {},
};

export const warehouseSlice = createSlice({
  name: "warehouses",
  initialState,
  reducers: {
    updateBoundFilter: updateBoundFilters,
    updateRow: (state, action) => {
      const updatedState = updateObjectByPath(
        state,
        action.payload.path,
        action.payload.value
      );
      return { ...state, ...updatedState };
    },
    updateFilters: (state, action) => {
      state.filters = { ...action.payload };
    },
    warehouseUpdate: (state, action) => {
      state.warehouses = action;
    },
    fetchLoading: (state, action) => {
      state.loading = "pending";
    },
    updateTransferCost: (state, action) => {
      const findIndex = state.warehouses.findIndex(
        (item) => item?.id === action.payload.id
      );
      state.warehouses[findIndex].transfer_cost = action.payload.cost;
    },
    updateNote: (state, action) => {
      const findIndex = state.warehouses.findIndex(
        (item) => item?.id === action.payload.id
      );
      state.warehouses[findIndex].note = action.payload.note;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllWarehouses.fulfilled, (state, action) => {
      
        state.warehouses = action.payload?.append
          ? [...state.warehouses, ...action.payload?.data]
          : action.payload?.data;
        state.status = action.payload?.status;
        state.meta = action.payload?.meta;
        state.loading = "idle";
      })
      .addCase(GetAllWarehouses.rejected, (state, action) => {
        state.loading = "idle";
        state.error = action.error.message;
        state.warehouses = [];
      })
      .addCase(GetWarehouseById.pending, (state) => {
        state.loading = "pending";
        state.page = "detail";
        state.selectedWarehouse = null;
      })
      .addCase(GetWarehouseById.fulfilled, (state, action) => {
        state.selectedWarehouse = action?.payload?.data;
        state.status = action.payload?.status;
        state.meta = action.payload?.meta;
        state.loading = "idle";
        state.page = "list";
      })
      .addCase(GetWarehouseById.rejected, (state, action) => {
        state.loading = "idle";
        state.page = "list";
        state.selectedWarehouse = null;
        state.error = action.payload;
      })
      // filters
      .addCase(fetchWarehouseFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload;
        state.filterLoading = "idle";
      })
      .addCase(fetchWarehouseFilters.pending, (state, action) => {
        state.filterLoading = "pending";
      })
      // creating new
      .addCase(CreateWarehouse.pending, (state) => {
        state.selectedWarehouse = null;
        state.loading = "pending";
        state.page = "detail";
      })
      .addCase(CreateWarehouse.fulfilled, (state, action) => {
        const created = action?.payload?.data?.data;
        if (created) {
          state.warehouses = [created, ...state.warehouses];
          state.selectedWarehouse = created;
        }
        state.error = null;
        state.loading = "idle";
        state.page = "list";
      })

      .addCase(CreateWarehouse.rejected, (state, action) => {
        state.loading = "idle";
        state.page = "list";
        state.error = action.payload;
        state.selectedWarehouse = null;
      })

      // Updating
      .addCase(UpdateWarehouse.pending, (state) => {
        state.selectedWarehouse = null;
        state.loading = "pending";
        state.page = "detail";
      })
      .addCase(UpdateWarehouse.fulfilled, (state, action) => {
        const updatedWarehouse = action.payload?.data?.data;

        if (updatedWarehouse) {
          state.warehouses = state.warehouses.map((warehouse) =>
            warehouse.id === updatedWarehouse.id ? updatedWarehouse : warehouse
          );
        }
        state.error = null;
        state.loading = "idle";
        state.page = "list";
      })

      .addCase(UpdateWarehouse.rejected, (state, action) => {
        state.loading = "idle";
        state.page = "list";
        state.error = action.payload;
      })
      .addCase(DeleteWarehouse.pending, (state) => {
        state.loading = "pending";
        state.page = "detail";
      })
      .addCase(DeleteWarehouse.fulfilled, (state, action) => {
        const deletedWarehouseId = action.payload?.data?.data;

        state.warehouses = state.warehouses.filter(
          (warehouse) => !deletedWarehouseId.includes(warehouse.id)
        );
        state.error = null;
        state.loading = "idle";
        state.page = "list";
      })
      .addCase(DeleteWarehouse.rejected, (state, action) => {
        state.loading = "idle";
        state.page = "list";
        state.error = action.payload;
      });
  },
});

export const {
  updateFilters,
  warehouseUpdate,
  updateRow,
  updateBoundFilter,
  fetchLoading,
  updateTransferCost,
  updateNote,
} = warehouseSlice.actions;

export {
  CreateWarehouse,
  UpdateWarehouse,
  GetAllWarehouses,
  fetchWarehouseFilters,
  GetWarehouseById,
  DeleteWarehouse,
};

export default warehouseSlice.reducer;
