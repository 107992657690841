import { createSlice } from "@reduxjs/toolkit";
import { fetchNotifications, fetchNotificationsFilters } from "./thunk";
import updateObjectByPath from "../../components/dynamic-form/util/updateState";
import { updateBoundFilters } from "../updateBoundFilters";

const initialState = {
  notification: [], // This array will hold your data
  links: {},
  loading: "idle",
  error: null,
  isLogin: false,
  filtersMeta: {},
  boundFilters: {},
  filters: {
    filters: { status: "" },
    sort: null,
    page: 1,
    search: "",
  },
};

export const toastNotificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    updateBoundFilter: updateBoundFilters,
    addNotification: (state, action) => {
      //console.log("addNotification", action.payload)
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    updateNotificationData: (state, action) => {
      const updatedState = updateObjectByPath(
        state,
        action.payload.path,
        action.payload.value
      );
      return { ...state, ...updatedState };
    },
    deleteAllNotifications: (state) => {
      state.notification = [];
    },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    fetchLoading: (state, action) => {
      state.loading = "pending";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.loading = "idle";
        state.notification = action.payload?.append
          ? [...state.notification, ...action.payload?.data]
          : action.payload?.data;
        state.links = action.payload["links"];
        state.meta = action.payload["meta"];
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        if (state.loading === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      })
      .addCase(fetchNotificationsFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload?.data?.data;
      });
  },
});

export const {
  addNotification,
  updateNotificationData,
  deleteAllNotifications,
  updateFilter,
  updateBoundFilter,
  fetchLoading,
} = toastNotificationSlice.actions;
export { fetchNotifications, fetchNotificationsFilters };
export default toastNotificationSlice.reducer;
