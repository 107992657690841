import React from "react";
import icons from "../../icons";
import { HiExclamationCircle } from "react-icons/hi";
import StatusHistory from "./statusHistory";
import descriptiveContent from "../../../../utils/descriptiveContent";
import PopupUp from "../../../dynamic-page/util/popUp";
import PropTypes from "prop-types";
import notifier from "../../../dynamic-page/util/notifier";

const OrderStatus = ({
  item,
  color = "gray",
  text,
  callBack,
  orderId,
  refund_amount,
  type,
  order,
  isAwaiting,
  statusId: status,
}) => {
  const disableCheck = (item) => {
    return false;
  };

  const generateStatusIcon = (
    iconColor,
    iconComponent,
    tooltip,
    border,
    hideShadow
  ) => (
    <div className="w-8 h-8 justify-center items-center flex">
      <div
        className={`grow shrink ${
          type !== "return" && type !== "notification" && !hideShadow
            ? "shadow-md"
            : ""
        } basis-0 self-stretch rounded-[50px] flex-col justify-center items-center inline-flex bg-${iconColor}-100 ${
          border && "border-2 border-" + iconColor + "-700"
        }`}
      >
        {tooltip ? (
          <PopupUp toggle={() => iconComponent} action={"click"}>
            {tooltip}
          </PopupUp>
        ) : (
          iconComponent
        )}
      </div>
    </div>
  );

  const generateDivider = (dividerColor = "gray") => (
    <div
      className={`grow shrink shadow-md basis-0 h-1.5 bg-${dividerColor}-100`}
    />
  );

  const Wrapper = ({ children }) => (
    <PopupUp
      toggle={() => (
        <div className="w-[140px] h-8  justify-center items-start inline-flex">
          <div className="grow shrink basis-0 h-8 justify-center items-center flex">
            {children}
          </div>
        </div>
      )}
      action={"hover"}
      forcePosition={"top"}
    >
      {text}
    </PopupUp>
  );
  Wrapper.propTypes = {
    children: PropTypes.node.isRequired, // Enforces that 'children' is required and should be a React node
  };

  const primary_label = item?.order?.shipment?.shipment_labels?.find(
    (label) => label?.is_primary
  )?.tracking_number;

  const tooltip = (
    <StatusHistory
      product={item?.order?.first_item?.arbitbox_product?.id}
      isAwaiting={isAwaiting}
      id={orderId}
      primary_label={primary_label}
    />
  );
  const notificationsTooltip = <p>{text}</p>;

  const handlePrintLabel = (id) => {
    if (disableCheck(item)) {
      notifier({
        type: "info",
        message:
          "Please fill in the FBA/FBM weight and dimensions for all products connected to the order before mark as shipped. Missing weight will block the order from being shipped.",
      });
      return;
    }
    callBack({
      action: "markAsShipped",
      data: {
        id: id,
        modal: true,
      },
    });
  };

  const firstIcon = ({ item }) => {
    if (status !== 14) {
      return generateStatusIcon(
        "green",
        <icons.link2 className="text-green-600" />,
        tooltip
      );
    } else {
      return generateStatusIcon(
        "gray",
        <icons.link2 className="text-gray-600" />,
        tooltip
      );
    }
  };

  const secondIcon = ({ item }) => {
    if (
      status === 2 ||
      status === 10 ||
      status === 15 ||
      text === "Awaiting Label"
    ) {
      return generateStatusIcon(
        "green",
        <icons.cube className="text-green-600" />,
        tooltip
      );
    } else {
      return generateStatusIcon(
        "gray",
        <icons.cube className="text-gray-600" />,
        tooltip
      );
    }
  };

  const hasContentOrBillable = (item) => {
    if (item?.shipping_content && item?.billable_weight) {
      return (
        <icons.truck
          className={` ${
            disableCheck(item)
              ? "text-gray-300 "
              : "text-blue-600 cursor-pointer"
          } `}
          onClick={() => handlePrintLabel(orderId)}
        />
      );
    } else {
      return (
        <PopupUp
          toggle={() => (
            <div>
              <icons.truck className={` text-gray-500 `} />
            </div>
          )}
          action={"hover"}
          forcePosition={"bottom"}
        >
          <div className="flex flex-col gap-1 ">
            {!item?.billable_weight && (
              <span className="text-gray-900 leading-[21px] text-[12px] ">
                Billable weight is required
              </span>
            )}
            {!item?.shipping_content && (
              <span className="text-gray-900 leading-[21px] text-[12px] ">
                Shipping content is required
              </span>
            )}
          </div>
        </PopupUp>
      );
    }
  };

  const thirdIcon = ({ item }) => {
    let color;

    if (disableCheck(item)) {
      color = "gray";
    } else if (item?.order?.billable_weight && item?.order?.shipping_content) {
      color = "blue";
    } else {
      color = "gray";
    }
    if (status === 10) {
      return generateStatusIcon(
        "green",
        <icons.truck className="text-green-600" />,
        tooltip
      );
    } else if (status === 2) {
      return generateStatusIcon(
        color,
        hasContentOrBillable(item?.order),
        null,
        item?.order?.billable_weight && item?.order?.shipping_content
      );
    } else if (!item?.ready_to_ship && !item?.shipped && status !== 15) {
      return generateStatusIcon(
        "gray",
        <icons.truck className="text-gray-600" />,
        tooltip
      );
    } else if (status === 15) {
      return generateStatusIcon(
        "yellow",
        descriptiveContent(
          <icons.truck
            className="text-yellow-600 cursor-pointer"
            onClick={() => {
              window.open("/logistics/transfers", "_blank");
            }}
          />,
          "Create Transfer"
        ),
        null,
        true
      );
    }
  };

  let statusId;
  if (status === 5) {
    statusId = 5;
  } else if (status === 9) {
    statusId = 4;
  } else if (status === 6) {
    statusId = 2;
  } else {
    statusId = 1;
  }
  if (type === "return") {
    if (refund_amount > 0) {
      statusId = 2;
    } else {
      statusId = 3;
    }
  }
  if (type === "notification") {
    if (order?.is_cancelled && order?.cancel_requested) {
      statusId = 6;
    } else if (!order?.is_cancelled && order?.cancel_requested) {
      statusId = 7;
    } else {
      statusId = 8;
    }
  }

  const statusList = {
    //awaiting
    1: (
      <Wrapper>
        {firstIcon({ item })}
        {generateDivider(item?.ready_to_ship ? "green" : "gray")}
        {secondIcon({ item })}
        {generateDivider(item?.shipped ? "green" : "gray")}
        {thirdIcon({ item })}
      </Wrapper>
    ),

    6: (
      <Wrapper>
        {generateStatusIcon(
          "red",
          <HiExclamationCircle className="text-red-600 w-5 h-5" />,
          notificationsTooltip
        )}
        {generateDivider("red")}
        {generateStatusIcon(
          "red",
          <icons.xMark className="text-red-600  w-3 h-3" />,
          notificationsTooltip
        )}
      </Wrapper>
    ),
    // shipped
    // 10: (
    //   <Wrapper>
    //     {generateStatusIcon(
    //       "green",
    //       <icons.link2 className="text-green-600" />
    //     )}
    //     {generateDivider("green")}
    //     {generateStatusIcon("green", <icons.cube className="text-green-600" />)}
    //     {generateDivider("green")}
    //     {generateStatusIcon(
    //       "green",
    //       <icons.truck className="text-green-600" />
    //     )}
    //   </Wrapper>
    // ),
    4: (
      <Wrapper>
        <div className=" h-8 py-1.5 bg-purple-100 rounded-[6px] justify-center items-center w-[96px] flex">
          {generateStatusIcon(
            "purple",
            <div className="flex items-center gap-2 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="11"
                viewBox="0 0 12 11"
                fill="none"
              >
                <g clipPath="url(#clip0_34111_15903)">
                  <path
                    d="M1.2 8.18907L3.033 6.32467C3.01621 6.21713 3.0052 6.10875 3 6C3.00095 5.19102 3.31733 4.41545 3.87973 3.84341C4.44213 3.27138 5.20464 2.94958 6 2.94861C6.10691 2.9537 6.21347 2.9647 6.3192 2.98157L7.3656 1.91724C6.92025 1.79493 6.46129 1.73134 6 1.72805C2.7672 1.72805 0 4.9845 0 6C0.156483 6.84257 0.577575 7.61074 1.2 8.18907Z"
                    fill="#6C2BD9"
                  />
                  <path
                    d="M7.62 5.21518L11.2242 1.54924C11.2815 1.49295 11.3272 1.42561 11.3587 1.35115C11.3901 1.27669 11.4067 1.19661 11.4074 1.11558C11.408 1.03455 11.3929 0.954187 11.3627 0.879186C11.3325 0.804186 11.288 0.736047 11.2316 0.678746C11.1753 0.621446 11.1083 0.576131 11.0346 0.545445C10.9608 0.51476 10.8818 0.499319 10.8022 0.500023C10.7225 0.500727 10.6438 0.517562 10.5706 0.549547C10.4974 0.581531 10.4311 0.628023 10.3758 0.686311L6.7716 4.35225C6.65874 4.29708 6.54049 4.25413 6.4188 4.22409L6.3978 4.21738C6.1013 4.14649 5.7919 4.1538 5.49895 4.2386C5.206 4.3234 4.93919 4.48289 4.72382 4.70196C4.50844 4.92102 4.35164 5.1924 4.26826 5.49037C4.18489 5.78834 4.17771 6.10303 4.2474 6.40461C4.2474 6.41194 4.2522 6.41804 4.2534 6.42475C4.28294 6.549 4.32538 6.6697 4.38 6.78482L0.7758 10.4508C0.718494 10.5071 0.672785 10.5744 0.641339 10.6489C0.609894 10.7233 0.593342 10.8034 0.59265 10.8844C0.591958 10.9655 0.607139 11.0458 0.637307 11.1208C0.667476 11.1958 0.712028 11.264 0.768363 11.3213C0.824699 11.3786 0.89169 11.4239 0.965428 11.4546C1.03917 11.4852 1.11817 11.5007 1.19784 11.5C1.27751 11.4993 1.35624 11.4824 1.42944 11.4505C1.50265 11.4185 1.56885 11.372 1.6242 11.3137L5.2284 7.64775C5.34158 7.70331 5.46025 7.74647 5.5824 7.77652C5.589 7.77652 5.595 7.78079 5.6022 7.78262C5.89871 7.85351 6.2081 7.8462 6.50105 7.7614C6.794 7.6766 7.06081 7.51711 7.27618 7.29804C7.49156 7.07898 7.64836 6.8076 7.73174 6.50963C7.81511 6.21166 7.82229 5.89697 7.7526 5.59539C7.7526 5.58806 7.7478 5.58135 7.746 5.57403C7.71647 5.45025 7.67424 5.32998 7.62 5.21518Z"
                    fill="#6C2BD9"
                  />
                  <path
                    d="M10.6926 3.92078L8.9784 5.66435C9.03287 6.11954 8.98478 6.58138 8.83777 7.01488C8.69077 7.44837 8.44871 7.84216 8.12992 8.1664C7.81113 8.49065 7.42398 8.73686 6.99779 8.88638C6.57159 9.03591 6.11753 9.08482 5.67 9.02942L4.599 10.1188C5.05943 10.2197 5.52901 10.271 6 10.2719C9.8718 10.2719 12 7.0631 12 6C12 5.28842 11.0178 4.24606 10.6926 3.92078Z"
                    fill="#6C2BD9"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_34111_15903">
                    <rect width="12" height="12" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span className="whitespace-nowrap text-[14px] font-medium leading-[21px] text-purple-800 ">
                On hold
              </span>
            </div>,
            tooltip,
            null,
            true
          )}
        </div>
      </Wrapper>
    ),
    5: (
      <Wrapper>
        <div className=" py-[2px] px-[12px] whitespace-nowrap rounded-[6px] bg-red-100 text-red-800 text-[14px] leading-[21px] font-medium justify-center items-center w-full flex">
          <span>Cancelled by buyer</span>
        </div>
      </Wrapper>
    ),
    7: (
      <Wrapper>
        <div className="  h-8 py-1.5 bg-rose-100 shadow rounded-[50px] justify-center items-center w-full flex">
          {generateStatusIcon(
            "red",
            <HiExclamationCircle className="text-red-600  w-5 h-5" />,
            notificationsTooltip
          )}
        </div>
      </Wrapper>
    ),
    8: (
      <Wrapper>
        <div className=" h-8 py-1.5 bg-gray-100 shadow rounded-[50px] justify-center items-center w-full flex">
          {generateStatusIcon(
            "gray",
            <HiExclamationCircle className="text-gray-600  w-5 h-5" />,
            notificationsTooltip
          )}
        </div>
      </Wrapper>
    ),
    2: (
      <Wrapper>
        <div className=" h-8 py-1.5 bg-red-100 shadow rounded-[50px] justify-center items-center w-[96px]  flex">
          {generateStatusIcon(
            "red",
            <icons.cash className="text-red-600  " />,
            text === "Refunded" ? tooltip : notificationsTooltip,
            null,
            true
          )}
        </div>
      </Wrapper>
    ),
    3: (
      <Wrapper>
        <div className=" h-8 py-1.5 bg-yellow-100 shadow rounded-[50px] justify-center items-center w-full flex">
          {generateStatusIcon(
            "yellow",
            <icons.cash className="text-yellow-600  " />,
            notificationsTooltip
          )}
        </div>
      </Wrapper>
    ),
  };
  if (statusId) return statusList[statusId];
};

export default OrderStatus;
