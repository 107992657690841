import { createSlice } from "@reduxjs/toolkit";
import { fetchInventory } from "./thunk";
import { updateBoundFilters } from "../updateBoundFilters";

const initialState = {
  mainInventory: [],
  links: {},
  loading: "idle",
  error: null,
  filters: {},
  meta: {},
  filtersMeta: {},
};

export const mainInventorySlice = createSlice({
  name: "mainInventory",
  initialState,
  reducers: {
    updateBoundFilter: updateBoundFilters,
    updateFilter(state, action) {
      state.filters = { ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInventory.pending, (state, action) => {
        state.loading = "pending";
      })
      .addCase(fetchInventory.rejected, (state, action) => {
        if (action.error?.name !== "CanceledError") {
          state.loading = "idle";
          state.error = action.error;
        }
      })
      .addCase(fetchInventory.fulfilled, (state, action) => {
        state.loading = "idle";
        state.mainInventory = action.payload?.data;
        state.links = action.payload?.links;
        state.meta = action.payload?.meta;
      });
  },
});

export const { updateFilter, updateBoundFilter } = mainInventorySlice.actions;

export { fetchInventory };

export default mainInventorySlice.reducer;
