import React, { useEffect, useRef, useState } from "react";
import Icons from "../../orders/icons";
import shortenString from "../../dynamic-page/util/shortenString";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

const Text = ({
  item,
  value,
  validationMessage,
  handleChange,
  defaultValue,
  formData,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [innerText, setInnerText] = useState(value || defaultValue || "");
  const [isEmpty, setIsEmpty] = useState(value?.length <= 0);
  const contentRef = useRef(null);

  useEffect(() => {
    const currentText = value || defaultValue || "";
    setInnerText(currentText);
    setIsEmpty(currentText.length <= 0);

    // Automatically enter edit mode and focus if initial text is empty
    if (!currentText && item?.autoFocus) {
      setTimeout(() => enterEditMode(), 0);
    }
  }, [value, defaultValue]);

  const disabled =
    typeof item.disabled === "function"
      ? item.disabled(formData)
      : item.disabled || false;

  const handleInput = () => {
    setIsEmpty(contentRef.current.textContent.length <= 0);
  };

  const handleClose = (event) => {
    const { relatedTarget, key } = event;
    const text = contentRef.current.textContent;
  
    // Enter veya SaveButton ile kapatıldığında
    if (relatedTarget?.id === "SaveButton" || key === "Enter") {
      // Geçersiz sayı formatını kontrol et
      if (item.onlyNumber && /^\d{1,9}\.$/.test(text)){
        toast.error("Invalid number format");
        contentRef.current.textContent = value; 
        setIsEmpty(true);
        setIsEditing(false);
        return;
      }
  
      // Not nullable kontrolü
      if (item?.notNullable && text.trim().length < item?.notNullable) {
        contentRef.current.textContent = value;
        setIsEmpty(true);
        setIsEditing(false);
        return;
      } else {
        handleChange(text, item.name);
      }
    } else {
      // Düzenlemeyi iptal et ve eski değeri geri yükle
      contentRef.current.textContent = value;
    }
  
    const selection = window.getSelection();
    selection.removeAllRanges();
    setIsEditing(false);
    setIsEmpty(text.length <= 0);
  };
  const enterEditMode = () => {
    if (contentRef.current) {
      contentRef.current.focus();
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(contentRef.current);
      selection.removeAllRanges();
      selection.addRange(range);
      setIsEditing(true);
    }
  };

  const handleAction = (event) => {
    event.stopPropagation();
    if (!isEditing) {
        enterEditMode();
    } else {
        handleClose({relatedTarget: {id: "SaveButton"}});
    }
};

 const handleKeyInteraction = (event) => {
    const { key } = event;
    if (item.onlyNumber && !/[\d.]/.test(key) && key !== "Backspace") {
      event.preventDefault();
    }
    if (key === "Enter" || key === "Escape") {
      handleClose(event);
      if (key === "Enter") {
        event.preventDefault();
      }
    }
  };

  const Check=Icons.check
  const Edit = Icons.editOutline

  let displayText = "";

if (innerText) {
  if (isEditing) {
    displayText = innerText;
  } else {
    displayText = innerText.length > 45 ? shortenString(innerText, 45) : innerText;
  }
} 
  return (
    <div
      className={`flex items-center justify-start h-auto rounded-md min-h-[24px] border-transparent border ${
        item?.reverse ? "!flex-row-reverse" : "flex-row"
      } ${disabled ? "disabled:text-gray-400" : ""} ${
        isEditing ? "relative z-50" : ""
      }`}
    >
     
      {item?.unit && <span className="ml-0.5">KG</span>}
      <div
        role="presentation"
        contentEditable={isEditing}
        tabIndex={-1}
        ref={contentRef}
        onInput={handleInput}
        onBlur={handleClose}
        onKeyDown={handleKeyInteraction}
        className={` transition h-full ${
          isEditing ? "bg-white py-2 cursor-text rounded-sm" : ""
        } focus:outline-none ${item.innerClassName || ""} ${
          validationMessage ? "border-red-500 border-2" : ""
        }  ${
            item?.editableText && 'w-full max-w-[170px] overflow-x-auto overflow-y-hidden whitespace-nowrap hide-scrollbar'
        }` }
      >
        {displayText}
      </div>

      {item?.unitEnd &&  <span className="ml-0.5">kg</span>}
      <div  className={`${item?.buttonClassName || ""}`}>
        <button
          id="SaveButton"
          className={`${
            !isEditing ? "hidden" : ""
          } transition cursor-pointer active:bg-blue-700 active:text-white text-white hover:bg-blue-600 bg-blue-500 rounded-full w-7 h-7 flex items-center justify-center`}
        >
          <Check className="w-3.5 h-3.5" />
        </button>
        <button
          onClick={handleAction}
          id="EditButton"
          className={`${
            isEditing ? "hidden" : ""
          } transition ml-2 cursor-pointer active:bg-blue-500 active:text-white text-gray-300 hover:text-blue-500 hover:bg-gray-100 bg-transparent rounded-full w-4 h-4 flex items-center justify-center`}
        >
          <Edit className="w-4 h-4" />
        </button>
      </div>

      {isEmpty && (
        <span className="opacity-50 cursor-default">{item?.placeholder}</span>
      )}
    </div>
  );
};

Text.propTypes = {
  item: PropTypes.object.isRequired,
  value: PropTypes.string,
  validationMessage: PropTypes.string, // Add this line
  handleChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  formData: PropTypes.object,
};

export default Text;