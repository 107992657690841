import React from "react";
import Icons from "../../orders/icons";
import PropTypes from "prop-types";
const CopyText = ({
  children,
  productCopyIcon,
  text,
  hint = false,
  iconClass,
}) => {
  const CopyOrders = Icons.copyOrders;
  return (
    <>
      {(text || children) && (
        <div className={"cursor-pointer flex items-center"}>
          <div className={"flex flex-row items-center relative "}>
            <span className={"overflow-hidden flex items-center text-gray-600"}>
              <button
                className={"group"}
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(text || children);
                }}
              >
                <span
                  className={
                    "text-white bg-blue-500 rounded-[6px] px-[2px] absolute group-active:w-[100%] h-[100%] top-0 right-0 transition opacity-0 group-active:opacity-100 text-[12px] flex items-center"
                  }
                >
                  {!hint ? <CopyOrders className={iconClass} /> : "Copied!"}
                </span>
                <button
                  className={
                    "text-gray-300 group-hover:text-gray-500 transition group-active:text-"
                  }
                  onClick={() => {
                    navigator.clipboard.writeText(text || children);
                  }}
                >
                  {productCopyIcon ? (
                    <CopyOrders
                      className={`${iconClass}text-gray-300 hover:text-gray-500 w-4 h-4`}
                    />
                  ) : (
                    <CopyOrders
                      className={`${iconClass}text-gray-300 hover:text-gray-500 w-3 h-3`}
                    />
                  )}
                </button>
              </button>
            </span>
          </div>
        </div>
      )}
    </>
  );
};
CopyText.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
  productCopyIcon: PropTypes.bool,
  hint: PropTypes.bool,
  iconClass: PropTypes.string,
};

export default CopyText;
