import { createSlice } from "@reduxjs/toolkit";
import updateObjectByPath from "./../../components/dynamic-form/util/updateState";

import { fetchFilters, fetchRows } from "./thunk";
import { createMappedFilters } from "../../components/dynamic-page/util/pageControl";

const initialState = {
  rows: [],
  loading: "idle",
  filters: {
    filters: { status: 0 },
    sort: null,
    page: 1,
    search: "",
    limit: 20,
    include: null,
    tab: null,
  },
  boundFilters: {},
  filtersMeta: {},
  meta: {},
  filterLoading: "idle",
};

export const dynamicPageSlice = createSlice({
  name: "dynamicPage",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
    
      if (!filters && !bound) {
        // Handle case where both filters and bound are not provided
        return;
      }
    
      if (!bound) {
        // Handle case where bound is not provided
        bound = state.boundFilters;
      }
    
    
      const boundFilters = createMappedFilters(
        { filters: filters },
        bound,
        true
      );
    
    
      // Encode the boundFilters
      const boundFiltersString = encodeURIComponent(
        JSON.stringify(boundFilters)
      );
    
      // Append or update the encoded string in the URL
      const url = new URL(window.location.href);
      url.searchParams.set("boundFilters", boundFiltersString);
    
      // Update the URL using history.replaceState to avoid adding to history stack
      window.history.replaceState({}, "", url.toString());
    
      // Update state if necessary
      state.boundFilters = boundFilters;
    },
    updateRow: (state, action) => {
      const updatedState = updateObjectByPath(
        state,
        `rows.[id:${action.payload.id}]${
          action.payload.path ? "." + action.payload.path : ""
        }`,
        action.payload.value
      );
      return { ...state, ...updatedState };
    },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    fetchLoading: (state, action) => {
      state.loading = "pending";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRows.fulfilled, (state, action) => {
        state.loading = "idle";
        state.rows = action.payload?.append
          ? [
              ...state.rows,
              ...action.payload.data.data?.filter(
                (row) => !state.rows.find((r) => r.id === row.id)
              ),
            ]
          : action.payload.data.data;
        state.meta = action.payload?.data?.meta;
      })
      .addCase(fetchRows.rejected, (state, action) => {
        if (action.error?.name !== "CanceledError") {
          state.loading = "idle";
          state.error = action.error;
        }
      })
      .addCase(fetchFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload.data.data || action.payload.data;
        state.filterLoading = "idle";
      })
      .addCase(fetchFilters.pending, (state, action) => {
        state.filterLoading = "pending";
      });
  },
});

export const { updateRow, updateFilter, updateBoundFilter, fetchLoading } =
  dynamicPageSlice.actions;
/*export {
    
    fetchOrders, fetchSingleOrder, viewOrder, fetchOrdersFilters, createOrder,
    bulkDeleteOrders, bulkRestoreOrders,
    apiActions, apiDetailActions,
    fetchShipmentCarrier, createShipment, //Shipment, carrier
    addNote, fetchNotes, deleteNote, // Notes
    updateBillableWeight, //Weight
    createSupplyRequest, createSupplyRequestOrderItem // Supply request
}*/
export { fetchRows, fetchFilters };
export default dynamicPageSlice.reducer;
