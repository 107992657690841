import upperCaseString from "../../../../../dynamic-page/util/upperCaseString";
import React from "react";
import Icons from "../../../../icons";
import SingleGroupProductBadge from "../../../../../dynamic-page/components/SingleGroupProductBadge";

const addProductView = (data, orderItems) => {
    const Shipment=Icons.shipmentDetail
    console.log(data, orderItems);
    let items = [];
    items = [
        {
            name: "product",
            label: "Quick search for the  Product",
            type: "Select2",
            noPortal: true,
            api: {
                url: "/api/v1/product?include=brand.assigned",
                query: "&term={query}",
                filter: orderItems?.length > 0 ?  orderItems?.map((item) => item?._data?.product?.product?.id) : null,
                optionValue: (item) => ({
                    value: item?.id,
                    label: upperCaseString(item?.title, "word", true),
                    product: item,
                }),
            },
            defaultValue: data?._data?.product?.value || null,
            disablePreload: true,
            reset: false,
            clear: false,
            className: "w-full ",
            features: {
                formatOptionLabel: ({label, product}) => {
                    return (
                        <div
                            className={"flex flex-row gap-[8px] items-center min-h-[25px]"}
                        >
              <span
                  className={
                      " min-w-[45px] gap-1 overflow-hidden flex flex-row justify-center items-center "
                  }
              >
                <SingleGroupProductBadge product={product}/>
                  {product?.image ? (
                      <img
                        alt="product"
                          className={"w-[20px] h-[20px] rounded-md "}
                          src={product?.image}
                      />
                  ) : (
                      <img
                      alt="def"
                          className={"w-[20px] h-[20px] rounded-md"}
                          src={"/assets/images/noImage.png"}
                      />
                  )}
              </span>
                            <span>{upperCaseString(label)}</span>
                        </div>
                    );
                },
            },
            style: {
                control: {
                    height: "40px !important",
                    minHeight: "auto",
                    background: "rgba(0,0,0,0.03)",
                },
                menu: {
                    whiteSpace: "wrap !important ",
                    zIndex: 9999,
                },
                option: {
                    padding: "4px 8px !important",
                },
            },
            placeholder: (
                <div className={"flex flex-row gap-[8px] items-center text-[14px]"}>
                    <Shipment className={"w-[16px] h-[16px]"}/>
                    Quick search for the Product
                </div>
            ),
        },
    ];

    return items;
};

export default addProductView;
