import Icons from "../../../orders/icons";
import axios from "axios";
import { toast } from "react-toastify";
import {
  changeProductTitle,
  fetchProduct,
  UpdateProduct,
} from "../../../../store/products";
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import constants from "../../../../constant";
import util from "../../../dynamic-page/util";
import DirtySelect from "../../../dynamic-form/elements/DirtySelect";
import PropTypes from "prop-types";

const Essentials = ({ id, productData }) => {

  const Close=Icons.close

  const dispatch = useDispatch();
  const datas = useSelector((state) => state.productReducer.product);

  const mapOptionsToValues = (options) => {
    return options.map((option) => ({
      value: option?.id,
      label: option?.name,
    }));
  };

  const fetchBrands = async (params) => {
    let url = constants.APIURL.GET_BRANDS;
    if (params) url = url.concat(params);
    try {
      const response = await axios.get(url, { withCredentials: true });
      return response.data;
    } catch (error) {
      console.error("Error fetching brands:", error);
      throw error;
    }
  };

  const fetchCategories = async (params) => {
    let url = constants.APIURL.GET_CATEGORIES;
    if (params) url = url.concat(params);
    try {
      const response = await axios.get(url, { withCredentials: true });
      return response.data;
    } catch (error) {
      console.error("Error fetching categories:", error);
      throw error;
    }
  };

  const loadOptions = async (inputValue) => {
    let url = "?filter[name]=" + inputValue;
    return new Promise((resolve) => {
      fetchBrands(url)
        .then((response) => {
          const options = mapOptionsToValues(response.data);
          resolve(options);
        })
        .catch((error) => {
          console.error("Error loading brand options:", error);
        });
    });
  };

  const loadOptions2 = async (inputValue) => {
    let url = "?term=" + inputValue;
    return new Promise((resolve) => {
      fetchCategories(url)
        .then((response) => {
          const options = mapOptionsToValues(response.data);
          resolve(options);
        })
        .catch((error) => {
          console.error("Error loading category options:", error);
        });
    });
  };

  const brands = useSelector((state) => state.productReducer.brands);
  const categories = useSelector((state) => state.productReducer.categories);




  

  const updateProduct = async (productTitle, productBrands, unit_id) => {
    const payload = {
      type: datas?.type,
      title: productTitle,
      brand_id: productBrands,
      ...(unit_id && { unit_id: unit_id }),
    };
    try {
      const response = await dispatch(UpdateProduct({ id, payload })).unwrap();
      if (response?.status === 200) {
        dispatch(fetchProduct({ id, productLanguage: "40" }));
        toast.success(response?.data?.message);
      } else if (response?.status === 422) {
        util.notifier(response?.data);
      }
    } catch (error) {
      console.error("Error updating product:", error);
      toast.error("An error occurred while updating the product.");
    }
  };
 

  const updateProduct2 = async (productTitle, productBrands, unit_id) => {
    const payload = {
      type: datas?.type,
      title: productTitle,
      category_id: productBrands,
      ...(unit_id && { unit_id: unit_id }),
    };
    try {
      const response = await dispatch(UpdateProduct({ id, payload })).unwrap();
      if (response?.status === 200) {
        dispatch(fetchProduct({ id, productLanguage: "40" }));
        toast.success(response?.data?.message);
      } else if (response?.status === 422) {
        util.notifier(response?.data);
      }
    } catch (error) {
      console.error("Error updating product:", error);
      toast.error("An error occurred while updating the product.");
    }
  };
  const handleDeleteCategory = async (productTitle, productBrands, unit_id) => {
    const payload = {
      type: datas?.type,
      title: datas?.title,
      category_id: "",
      ...(datas?.unit?.id && { unit_id: datas?.unit?.id }),
    };
    try {
      const response = await dispatch(UpdateProduct({ id, payload })).unwrap();
      if (response?.status === 200) {
        dispatch(fetchProduct({ id, productLanguage: "40" }));
        toast.success(response?.data?.message);
      } else if (response?.status === 422) {
        util.notifier(response?.data);
      }
    } catch (error) {
      console.error("Error updating product:", error);
      toast.error("An error occurred while updating the product.");
    }
  };
  const onBrandChange = (lastvalue) => {
    updateProduct(datas?.title, lastvalue.value, datas?.unit?.id);
  };
  const onCategoriesChange = (lastvalue) => {
    updateProduct2(datas?.title, lastvalue.value, datas?.unit?.id);
  };



  const handleValueClicked = async (props) => {
    const payload = {
      type: productData?.type,
      // "category_id": datas?.category_id,
      title: props.value,
      brand_id: productData?.brand_id,
      ...(productData?.unit?.id && { unit_id: productData?.unit?.id }),
    };
    await dispatch(UpdateProduct({ id, payload }))
      .unwrap()
      .then((response) => {
        if (response?.status === 200) {
          props.reset({ value: props?.value });
          dispatch(changeProductTitle(props.value));
          toast.success(response?.data?.message);
        } else if (response?.status === 422) {
          util.notifier(response?.data);
        }
      });
  };
 

  return (
    <div className="flex flex-col gap-4  w-full">
      <div className="flex gap-3 ">
        <DirtySelect
          isValue={productData?.title}
          id={id}
          tooltipPosition={"top"}
          className={"w-[100%] xl:w-[80%]"}
          titleClassName={"!text-[16px] !leading-6"}
          title={"Product Name"}
          placeholder={"ex. book"}
          handleValueClicked={handleValueClicked}
        />
        <div className="w-[100%] xl:w-[20%]   flex flex-col gap-2   rounded-[8px]">
          <div className="flex  gap-1 items-center justify-between">
            <babel className="text-[14px] leading-normal font-medium text-gray-900">
              Brand
            </babel>
          
          </div>
          <AsyncSelect
            loadOptions={loadOptions}
            placeholder={"Search Brands"}
            defaultValue={datas?.brand?.name}
            defaultOptions
            cacheOptions
            options={brands}
            value={datas?.brand_id ? { value: datas?.brand?.id, label: datas?.brand?.name }:null}
            onChange={onBrandChange}
            optionHeight={50}
            styles={{
              control: (provided, state) => ({
                ...provided,
                height: 42,
                minHeight: 42,
                // width: 300,
                width: "100%",
                minWidth: "100%",
                borderRadius: 8,
                border: state.isFocused
                  ? "1px solid #00A3FF"
                  : "1px solid #D1D5DB", // border-gray-300
                backgroundColor: "#F9FAFB", // text-gray-50
                boxShadow: state.isFocused ? "0px 0px 0px 1px #00A3FF" : "none",
                "&:hover": {
                  border: state.isFocused
                    ? "1px solid #00A3FF"
                    : "1px solid #E5E5E5",
                },
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? "#00A3FF" : "white",
                color: state.isSelected ? "white" : "black",
                "&:hover": {
                  backgroundColor: "#00A3FF",
                  color: "white",
                },
              }),
              menu: (provided, state) => ({
                ...provided,
                borderRadius: 10,
                border: state.isFocused
                  ? "1px solid #00A3FF"
                  : "1px solid #E5E5E5",
                boxShadow: state.isFocused ? "0px 0px 0px 1px #00A3FF" : "none",
                "&:hover": {
                  border: state.isFocused
                    ? "1px solid #00A3FF"
                    : "1px solid #E5E5E5",
                },
              }),
              container: (provided, state) => ({
                ...provided,
                width: "100%",
                minWidth: "100%",
                borderRadius: 10,
              }),
            }}
          />
        </div>
      </div>
      <div className="w-[100%]  flex flex-col gap-2 rounded-[8px]">
      <div className="flex  gap-1 items-center justify-between">
            <babel className="text-[14px] leading-[21px] font-medium text-gray-900">
              Arbitbox Category
            </babel>
            {datas?.category?.id && <button onClick={handleDeleteCategory}>
              <Close className="text-gray-300 hover:text-gray-500 w-5 h-5" />
            </button>}
          </div>
        <AsyncSelect
          loadOptions={loadOptions2}
          placeholder={"Search Categories"}
          defaultOptions
          cacheOptions
          options={categories}
          value={
            datas?.category?.id
              ? { value: datas?.category?.id, label: datas?.category?.name }
              : null
          }
          onChange={onCategoriesChange}
          optionHeight={50}
          styles={{
            control: (provided, state) => ({
              ...provided,
              height: 42,
              minHeight: 42,
              // width: 300,
              width: "100%",
              minWidth: "100%",
              borderRadius: 10,
              border: state.isFocused
                ? "1px solid #00A3FF"
                : "1px solid #D1D5DB", // border-gray-300
              backgroundColor: "#F9FAFB", // text-gray-50
              boxShadow: state.isFocused ? "0px 0px 0px 1px #00A3FF" : "none",
              "&:hover": {
                border: state.isFocused
                  ? "1px solid #00A3FF"
                  : "1px solid #E5E5E5",
              },
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected ? "#00A3FF" : "white",
              color: state.isSelected ? "white" : "black",
              "&:hover": {
                backgroundColor: "#00A3FF",
                color: "white",
              },
            }),
            menu: (provided, state) => ({
              ...provided,
              borderRadius: 10,
              border: state.isFocused
                ? "1px solid #00A3FF"
                : "1px solid #E5E5E5",
              boxShadow: state.isFocused ? "0px 0px 0px 1px #00A3FF" : "none",
              "&:hover": {
                border: state.isFocused
                  ? "1px solid #00A3FF"
                  : "1px solid #E5E5E5",
              },
            }),
            container: (provided, state) => ({
              ...provided,
              width: "100%",
              minWidth: "100%",
              borderRadius: 10,
            }),
          }}
        />
      </div>
    </div>
  );
};

Essentials.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  productData: PropTypes.shape({
    title: PropTypes.string,
    brand_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    unit: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    type: PropTypes.string,
  }).isRequired,
};

export default Essentials;
