import { createSlice } from "@reduxjs/toolkit";

import {
  fetchTransfers,
  fetchNotes,
  deleteNote,
  addNote,
  fetchSingleTransfer,
  fetchTransfersFilters
} from "./thunk";
import updateObjectByPath from "../../components/dynamic-form/util/updateState";
import { updateBoundFilters } from "../updateBoundFilters";

const initialState = {
  transfers: [],
  links: {},
  loading: "idle",
  filterLoading: "idle",
  error: null,
  isLogin: false,
  filtersMeta: {},
  boundFilters: {},
  filters: {},
};

export const transfersSlice = createSlice({
  name: "transfers",
  initialState,
  reducers: {
    updateBoundFilter: updateBoundFilters,
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    updateRow: (state, action) => {
      const updatedState = updateObjectByPath(
        state,
        action.payload.path,
        action.payload.value
      );
      return { ...state, ...updatedState };
    },
    fetchLoading: (state, action) => {
      state.loading = "pending";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransfers.fulfilled, (state, action) => {
        state.loading = "idle";
        state.transfers = action.payload?.append
          ? [...state.transfers, ...action.payload.data.data]
          : action.payload?.data.data;
        state.links = action.payload?.data["links"];
        state.meta = action.payload?.data["meta"];
      })
      .addCase(fetchTransfers.rejected, (state, action) => {
        if (state.loading === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      })
      .addCase(fetchTransfersFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload?.data;
        state.filterLoading = "idle";
      })
      .addCase(fetchTransfersFilters.pending, (state, action) => {
        
        state.filterLoading = "pending";
      })
      .addCase(fetchTransfersFilters.rejected, (state, action) => {
       
        state.filterLoading = "idle";
      })
  },
});

export const {
  updateFilter,
  updateBoundFilter,
  extraReducers,
  updateRow,
  fetchLoading,
} = transfersSlice.actions;

export {
  fetchTransfers,
  fetchNotes,
  deleteNote,
  addNote,
  fetchSingleTransfer,
  fetchTransfersFilters,
};

export default transfersSlice.reducer;
