import { HiFilter } from "react-icons/hi";
import Util from "../util";
import SingleGroupProductBadge from "./SingleGroupProductBadge";
import descriptiveContent from "../../../utils/descriptiveContent";
import CopyText from "../util/copyText";
import TruncateText from "../util/truncateText";
import PopupUp from "../util/popUp";

const productName = ({
  item,
  hide,
  user,
  handleTableFilters,
  secondItem,
  showApid,
  pageData,
}) => (
  <div className={` gap-[8px] flex flex-col justify-start w-[100%] `}>
    <div
      className={"flex flex-row gap-[8px] whitespace-pre-wrap   cursor-pointer"}
    >
      <TruncateText
        maxLines={2}
        productCopyIcon={true}
        className={"text-[12px] leading-[15px] text-gray-900   "}
        tooltip={true}
        callBack={() => {
          if (item?.id) {
            window.open(`/product/${item?.id}`, "_blank");
          }
        }}
      >
        {Util.upperCaseString(item?.title || secondItem?.title)}
      </TruncateText>
    </div>
    <div className={"flex justify-between "}>
      <div className={"flex flex-row gap-[4px] text-gray-500"}>
        {item?.brand_name || item?.brand?.name ? (
          <p
            className={
              "text-[12px] leading-[14px] flex items-center gap-1 font-medium text-gray-500 "
            }
          >
            {Util.shortenString(item?.brand_name || item?.brand?.name, 30)}{" "}
            {!hide?.brandCopy && (
              <CopyText
                className={"text-[9px]"}
                text={item?.brand_name || item?.brand?.name}
                hint={false}
                productCopyIcon={true}
              />
            )}
            {!hide?.brandFilter &&
              descriptiveContent(
                <HiFilter
                  onClick={() => {
                    handleTableFilters(
                      {
                        brand_id: [item?.brand?.id],
                        assign_id: pageData?.filters?.filters?.assign_id
                          ? [pageData?.filters?.filters?.assign_id]
                          : null,
                        product_id: pageData?.filters?.filters?.product_id
                          ? [pageData?.filters?.filters?.product_id]
                          : null,
                      },
                      {
                        brand_id: {
                          value: item?.brand?.id,
                          label: item?.brand?.name,
                        },
                        product_id: {
                          value:
                            pageData?.boundFilters?.product_id?.value || null,
                          label:
                            pageData?.boundFilters?.product_id?.label || null,
                        },
                        assign_id: {
                          value:
                            pageData?.boundFilters?.assign_id?.value || null,
                          label:
                            pageData?.boundFilters?.assign_id?.label || null,
                        },
                      }
                    );
                  }}
                  className="text-gray-300 hover:text-gray-500 w-[12px] h-[12px] cursor-pointer"
                />,
                "Filter by Brand"
              )}
          </p>
        ) : (
          "-"
        )}
      </div>

      <button
        className="flex items-center gap-[2px] !text-[12px] cursor-pointer"
        onClick={() => {
          window.open(`/product/${item?.id}`, "_blank");
        }}
      >
        {!hide?.type && <SingleGroupProductBadge product={item} />}

        {user ? (
          <div
            className={
              "text-blue-700 font-medium text-[12px] items-center leading-normal flex flex-row gap-[4px] "
            }
          >
            <PopupUp
              toggle={() => (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleTableFilters(
                      {
                        assign_id: [user?.id],
                        product_id: pageData?.filters?.filters?.product_id
                          ? [pageData?.filters?.filters?.product_id]
                          : null,
                        brand_id: pageData?.filters?.filters?.brand_id
                          ? [pageData?.filters?.filters?.brand_id]
                          : null,
                      },
                      {
                        assign_id: {
                          value: user?.id,
                          label: user?.name,
                        },
                        product_id: {
                          value:
                            pageData?.boundFilters?.product_id?.value || null,
                          label:
                            pageData?.boundFilters?.product_id?.label || null,
                        },
                        brand_id: {
                          value:
                            pageData?.boundFilters?.brand_id?.value || null,
                          label:
                            pageData?.boundFilters?.brand_id?.label || null,
                        },
                      }
                    );
                  }}
                >
                  <img
                    alt="profile"
                    src={user?.image_url || "/assets/images/defaultAvatar.png"}
                    className={"w-[18px] h-[18px] rounded-full object-cover"}
                  />
                </button>
              )}
              action="hover"
            >
              <span className="text-gray-900 font-medium leading-[21px] ">
                {user?.name}
              </span>
            </PopupUp>

            {showApid ? (
              <span>{item?.apid}</span>
            ) : (
              Util.upperCaseString(user?.name, "word")
            )}
            <button
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <CopyText
                className={"text-[4px]"}
                text={item?.apid}
                hint={false}
              />
            </button>
          </div>
        ) : (
          <div className="text-gray-400 flex gap-1 items-center">
            {showApid ? (
              <span className="text-blue-700 font-medium text-[12px] leading-normal">
                {item?.apid}
              </span>
            ) : (
              <span>Unassigned</span>
            )}
            <button
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <CopyText
                className={"text-[4px]"}
                text={item?.apid}
                hint={false}
              />
            </button>
          </div>
        )}
        {descriptiveContent(
          <HiFilter
            onClick={(e) => {
              e.stopPropagation();
              handleTableFilters(
                {
                  assign_id: pageData?.filters?.filters?.assign_id
                    ? [pageData?.filters?.filters?.assign_id]
                    : null,
                  brand_id: pageData?.filters?.filters?.brand_id
                    ? [pageData?.filters?.filters?.brand_id]
                    : null,
                  product_id: [item?.id],
                },
                {
                  product_id: {
                    value: item?.id,
                    label: item?.apid,
                  },
                  assign_id: {
                    value: pageData?.boundFilters?.assign_id?.value || null,
                    label: pageData?.boundFilters?.assign_id?.label || null,
                  },
                  brand_id: {
                    value: pageData?.boundFilters?.brand_id?.value || null,
                    label: pageData?.boundFilters?.brand_id?.label || null,
                  },
                }
              );
            }}
            className="text-gray-300 hover:text-gray-500 w-[12px] h-[12px] cursor-pointer"
          />,
          "Filter by APID"
        )}
      </button>
    </div>
  </div>
);

export default productName;
