import { createSlice } from "@reduxjs/toolkit";
import {
  bulkdeleteCarrierServices,
  bulkStatusupdateCarrierServices,
  createCarrierService,
  deleteCarrierService,
  fetchCarrierServices,
  fetchCarrierServicesFilters,
  restoreCarrierServices,
  searchCarrierService,
  updateCarrierService,
} from "./thunk";
import { updateBoundFilters } from "../updateBoundFilters";

const initialState = {
  carrier_service: [],
  links: {},
  loading: "idle",
  error: null,
  filters: {
    filters: { status: 0 },
    sort: null,
    page: 1,
    search: "",
  },
  filtersMeta: {},
  boundFilters: {},
  meta: {},
};

export const carrierSlice = createSlice({
  name: "carrier_service",
  initialState,
  reducers: {
    updateBoundFilter: updateBoundFilters,
    fetchCarrierServices: (state, action) => {
      state.carrier_service = action.payload;
    },
    updateFilter: (state, action) => {
      state.filters = { ...action.payload };
    },
    updateCarrierServiceStatus: (state, action) => {
      const { status, ids } = action.payload;
      state.carrier_service = state.carrier_service.map((curr) => {
        if (ids.includes(curr.id)) {
          return { ...curr, status };
        }
        return curr;
      });
    },
    removeCarrierService: (state, action) => {
      const ids = action.payload;
      state.carrier_service = state.carrier_service.filter(
        (curr) => !ids.includes(curr.id)
      );
    },
    bulkdeleteCarrierServices: (state, action) => {
      const ids = action.payload;
      state.carrier_service = state.carrier_service.filter(
        (Carrier) => !ids.includes(Carrier.id)
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCarrierServices.pending, (state, action) => {
        state.loading = "pending";
      })
      .addCase(fetchCarrierServices.fulfilled, (state, action) => {
        state.loading = "idle";
        state.carrier_service = action.payload?.data;
        state.links = action.payload.links;
        state.meta = action.payload.meta;
      })
      .addCase(fetchCarrierServices.rejected, (state, action) => {
        if (action.error?.name !== "CanceledError") {
          state.loading = "idle";
          state.error = action.error;
        }
      })
      .addCase(fetchCarrierServicesFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload;
      });
  },
});

export const {
  updateFilter,
  updateBoundFilter,
  removeCarrierService,
  updateCarrierServiceStatus,
} = carrierSlice.actions;

export {
  fetchCarrierServices,
  createCarrierService,
  updateCarrierService,
  deleteCarrierService,
  searchCarrierService,
  bulkdeleteCarrierServices,
  bulkStatusupdateCarrierServices,
  restoreCarrierServices,
  fetchCarrierServicesFilters,
};

export default carrierSlice.reducer;
