import { createSlice } from "@reduxjs/toolkit";
import {
  createInventory,
  deleteInventory,
  fetchInventory,
  fetchInventoryFilters,
  updateInventory,
} from "./thunk";
import { updateBoundFilters } from "../updateBoundFilters";

const initialState = {
  inventory: [],
  links: {},
  loading: "idle",
  error: null,
  filters: {},
  meta: {},
  filtersMeta: {},
};

export const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    updateBoundFilter: updateBoundFilters,
    fetchInventory: (state, action) => {
      state.inventory = action.payload;
    },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    updateInventoryStatuses: (state, action) => {
      const { status, ids } = action.payload;
      state.inventory = state.inventory.map((curr) => {
        if (ids.includes(curr.id)) {
          return { ...curr, status };
        }
        return curr;
      });
    },
    removeInventory: (state, action) => {
      const ids = action.payload;
      state.inventory = state.inventory.filter(
        (curr) => !ids.includes(curr.id)
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInventory.pending, (state, action) => {
        state.loading = "pending";
      })
      .addCase(fetchInventory.rejected, (state, action) => {
        if (action.error?.name !== "CanceledError") {
          state.loading = "idle";
          state.error = action.error;
        }
      })
      .addCase(fetchInventory.fulfilled, (state, action) => {
        state.loading = "idle";
        state.inventory = action.payload?.data;
        state.links = action.payload?.links;
        state.meta = action.payload?.meta;
      })
      .addCase(fetchInventoryFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload.data;
      });
  },
});

export const {
  updateFilter,
  updateBoundFilter,
  updateInventoryStatuses,
  removeInventory,
} = inventorySlice.actions;

export {
  fetchInventory,
  createInventory,
  updateInventory,
  deleteInventory,
  fetchInventoryFilters,
};

export default inventorySlice.reducer;
