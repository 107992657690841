import { createSlice } from "@reduxjs/toolkit";

import {
  bulkStatusUpdateTargetSellerFolder,
  createTargetSellerFolder,
  deleteTargetSellerFolder,
  fetchTargetSellerListings,
  fetchTargetSellerListingsFilters,
  restoreTargetSellerFolder,
  updateTargetSellerFolder,
} from "./thunk";
import { updateBoundFilters } from "../updateBoundFilters";

const initialState = {
  targetSellerListings: [],
  links: {},
  filters: {
    type_filter: [],
    note_filter: [],
    dollar_filter: [],
    percent_filter: [],
    channel_filter: [],
    supply_channel_filter: [],
    brand_filter: [],
    assign_filter: [],
    bbowner_filter: [],
    category_filter: [],
    bbprice_filter: [],
    lwprice_filter: [],
    sales_channel_id_filter: [],
    created_filter: null,
    updated_filter: null,
    rating_count_filter: [],
    rating_filter: [],
    best_supply_offer_inventory: [],
    offers_count: [],
    bb_avg_price: [],
    sales_rank: [],
    top_percentage: [],
    bb_oos_90: [],
    fba_margin: [],
    fbm_margin: [],
    fba_profit: [],
    fbm_profit: [],
    category_degree: [],
  },
  meta: {},
  filtersMeta: {},
  boundFilters: {},
  loading: "idle",
  error: null,
  isLogin: false,
  filterLoading: "idle",
};

export const targetSellerListingsSlice = createSlice({
  name: "targetSellerListings",
  initialState,
  reducers: {
    updateBoundFilter: updateBoundFilters,
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    },
    fetchLoading: (state, action) => {
      state.loading = "pending";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTargetSellerListings.fulfilled, (state, action) => {
        state.loading = "idle";
        state.targetSellerListings = action.payload?.append
          ? [...state.targetSellerListings, ...action.payload["data"]]
          : action.payload["data"];
        state.links = action.payload["links"];
        state.meta = action.payload["meta"];
      })
      .addCase(fetchTargetSellerListings.rejected, (state, action) => {
        if (state.loading === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      })
      .addCase(fetchTargetSellerListingsFilters.fulfilled, (state, action) => {
        state.filtersMeta = action.payload?.data;
        state.filterLoading = "idle";
      })
      .addCase(fetchTargetSellerListingsFilters.pending, (state, action) => {
        state.filterLoading = "pending";
      });
  },
});

export const { updateFilter, updateBoundFilter, fetchLoading } =
  targetSellerListingsSlice.actions;

export {
  fetchTargetSellerListings,
  fetchTargetSellerListingsFilters,
  createTargetSellerFolder,
  updateTargetSellerFolder,
  deleteTargetSellerFolder,
  restoreTargetSellerFolder,
  bulkStatusUpdateTargetSellerFolder,
};

export default targetSellerListingsSlice.reducer;
